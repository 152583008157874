import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { currencyIcon } from 'utils/currencyIcon'
import SymbolComponents from './SymbolComponents'
import AmountComponent from './AmountComponent'
import { LockClockRounded } from '@mui/icons-material/index'

  const MenuItemComponent = React.memo(({ option, additionalInfo }) => {
  return (
    <Box className='currencies-list'>
      <Grid className='currencies-list-content'>
        <Box className='coin-value-box'>
          <SymbolComponents option={option} />
          <AmountComponent option={option} additionalInfo={additionalInfo} isNonCash={false} />
        </Box>
        {/* Temp show non csh only for IDR */}
        {option?.code === 'USDT' && // USDT is bonus currency , all bonus amout will be added it in.
          <Box className='coin-sub-value'>
            <LockClockRounded />
            <AmountComponent option={option} isNonCash />
          </Box>}
      </Grid>
      <Typography className='coin-type'>
        {currencyIcon[option?.code]} &nbsp;{option?.code}
      </Typography>
    </Box>
  )
})

export default MenuItemComponent
