import { SuccessMessage } from 'network/messages/successMessages'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/login', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.login
  })
}

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/logout', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
    // successMessage: SuccessMessage.logout
  })
}

export const signUpService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/sign-up', data, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.signup
  })
}

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/user/change-password', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.changePassword
  })
}

export const forgetPasswordService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/forget-password', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.tokenVerify
  })
}
export const forgetVerifyPasswordService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/verify-forget-password', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.resetPassword
  })
}

export const verifyEmailService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/forget-password', payload, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const checkUserAddress = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/check-user-address', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getMetaUser = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/login-with-metaMask', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.login
  })
}
export const loginWithOtpService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/login', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const placeBetCrashGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/place-bet-crash-game', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.BetPlacedSuccessfully
  })
}
export const escapeCrashBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/player-escape-crashGame', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.PlayerEscapedSuccessfully
  })
}

export const cancelCrashBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/cancel-bet-crash-game', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getAllRoundHistoryService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-history', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllBetDataService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/get-total-bets', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getTotalWinningAmountService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/system/get-total-winning-amount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getCrashGameRoundDetails = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-history', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const getCrashGameRoundDetailService = (roundId) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-round-detail', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: { roundId }
  })
}

export const getAllbetOfOneRoundService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/all-placed-bets', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const getMyBetsService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, '/crash-game/get-crash-game-status', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const verifyMetaSignature = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/verify-metaSignature', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

export const verifyProvableFairCrashService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/crash-game/check-provable-fair', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const updateEmailService = (data) => {
  return axiosInstanceService(METHOD_TYPES.put, '/user/update-email', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.emailChangedSuccess
  })
}

export const checkIsEmailUnique = (email) => {
  return axiosInstanceService(METHOD_TYPES.get, `user/check-unique-constraints?email=${email}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const checkIsUsernameUnique = (username) => {
  return axiosInstanceService(METHOD_TYPES.get, `user/check-unique-constraints?username=${username}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const addBrowserId = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/insert-unique-key', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const resendEmailToken = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/refresh-email-token', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const loginWithSocialService = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'sociallogin/social-sign-up', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
