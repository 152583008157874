import { Box, styled } from '@mui/material'

export const Styledlayout = styled(Box)(({ theme }) => {
  return {
    position: 'relative',
    overflowX: 'hidden',
    maxWidth: '100%',
    width: 'calc(100% - 4.75rem)',
    minHeight: 'calc(100vh - 65px)',
    margin: '0 0 0 auto',
    padding: theme.spacing(4, 3.75, 0),
    backgroundColor: '#0b0116',
    height: '100%',
    transition:'all 500ms',
     [theme.breakpoints.down('xm')]: {
      marginBottom: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
    },
    '& .buble2': {
      width: '300px',
      height: '300px',
      borderRadius: '50%',
      backgroundColor: '#FA00FF',
      position: 'fixed',
      filter: 'blur(250px)',
      top: 0,
      right: '-10%',
      [theme.breakpoints.down('xxl')]: {
        width: '200px',
        height: '200px',
        filter: 'blur(150px)',
      },
    },
    '& .buble3': {
      width: '400px',
      height: '400px',
      borderRadius: '50%',
      backgroundColor: '#3F83F8',
      position: 'fixed',
      filter: 'blur(250px)',
      bottom: '-15%',
      right: '-10%',
      [theme.breakpoints.down('xxl')]: {
        width: '200px',
        height: '200px',
        filter: 'blur(150px)',
      },
    },
    '& .buble4': {
      width: '400px',
      height: '400px',
      borderRadius: '50%',
      backgroundColor: '#2519AD',
      position: 'fixed',
      filter: 'blur(250px)',
      bottom: 0,
      left: '-10%',
      [theme.breakpoints.down('xxl')]: {
        width: '200px',
        height: '200px',
        filter: 'blur(150px)',
      },
    },
    '& .buble5': {
      width: '400px',
      height: '400px',
      borderRadius: '50%',
      backgroundColor: '#2519AD',
      position: 'fixed',
      filter: 'blur(250px)',
      top: '-10%',
      left: '-10%',
      [theme.breakpoints.down('xxl')]: {
        width: '200px',
        height: '200px',
        filter: 'blur(150px)',
      },
    },
    '& .main-wrapper': {
      minHeight: '100vh',
      padding: theme.spacing(2),
      marginLeft: theme.spacing(9.5),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0)
      }
    },
    '& .MuiContainer-root': {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      }
    },

    '.home-banner': {
      display: 'flex',
      alignItems: 'center',
      justifyContents: 'center',
      '& img': {
        width: '100%',
        height: 'auto'
      }
    },

    '& .all-bets-tab-table-container': {
      position: 'relative',
      border: '1px solid #30538E',
      padding: '20px',
      borderRadius: '10px',
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        borderRadius: '0',
        border: '0',
      },
      '& .shadow': {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '40px',
        background: 'transparent',
        backdropFilter: 'blur(10px)', /* Efek blur di belakang elemen */
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', /* Shadow lembut */
        borderRadius: '20px',
        [theme.breakpoints.down('sm')]: {
          height: '10px',
        },
      },
      '& .MuiFormControl-root': {
        backgroundColor: 'transparent',
      },
      '& .MuiInputBase-root':{
        backgroundColor: '#0D1B32',
        border: 'none',
        height: '2.8rem',
        borderRadius: '30px',
        paddingLeft: '.9rem',
        input: {
          height: '0',
          webkitBoxShadow: '0 0 0 300px #0d1b32 inset !important',
          boxShadow: '0 0 0 300px #0d1b32 inset !important',
          border: 'none',
        }
      },
      '& .MuiTabs-root': {
        '& .MuiTabs-scroller':{
          [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center",
          },
        },
        '& .MuiTabs-flexContainer': {
          width: 'fit-content',
          backgroundColor: 'transparent',
          borderRadius: theme.spacing(1),
          padding: '10px 0',
          '& .MuiButtonBase-root': {
            minHeight: 'auto',
            borderRadius: theme.spacing(0.5),
            color: theme.palette.primary.contrastTextDark,
            backgroundColor: '#0D1B32',
            fontSize: theme.spacing(1.8),
            fontWeight: '400',
            textTransform: 'capitalize',
            borderRadius: '30px',
            marginRight: '10px',
            '&.Mui-selected': {
              background: theme.palette.button.mainGradient,
              color: theme.palette.primary.contrastText,
              borderRadius: '30px',
              fontWeight: '500'
            },

            [theme.breakpoints.down('sm')]: {
              minWidth: 'auto',
            },
          }
        },
        '& .MuiTabs-indicator': {
          display: 'none'
        }
      },
      '& .table-wrap': {
        width: '100%',
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.primary.transparent,
          borderRadius: theme.spacing(0),
          boxShadow: theme.shadows[0],
          '& .MuiTableContainer-root': {
            overflowY : 'hidden',
          },
          '& .MuiTable-root': {
            '& .MuiTableHead-root': {
              '& .MuiTableRow-root, & .MuiTableRow-head': {
                '& .MuiTableCell-root, & .MuiTableCell-head': {
                  borderBottom: `1px solid ${theme.palette.primary.contrastTextDark}`,
                  color: theme.palette.primary.contrastTextDark,
                  fontSize: theme.spacing(1.7),
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  padding: theme.spacing(2, 3),
                  [theme.breakpoints.down('xl')]: {
                    fontSize: theme.spacing(1.5)
                  },
                  '&:first-of-type': {
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  },
                  '&:last-of-type': {
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  },
                }
              }
            },
            '& .MuiTableBody-root': {
              '& .MuiTableRow-root': {
                '& .MuiTableCell-root, & .MuiTableCell-body': {
                  borderBottom: '0',
                  color: '#fff',
                  fontSize: theme.spacing(1.6),
                  textTransform: 'capitalize',
                  padding: theme.spacing(1, 3),
                  [theme.breakpoints.down('xl')]: {
                    fontSize: theme.spacing(1.4),
                  },
                },
                '&:nth-child(even)': {
                  backgroundColor: '#2A2633',
                },
                '& td': {
                  '&:last-of-type': {
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  },
                },
                '& th': {
                  '&:first-of-type': {
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  },
                },
              },
            },
          }
        },
        '& .icon-text-box': {
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          '& .icon-box': {
            width: theme.spacing(2),
            height: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '& .user-profile-img': {
            width: '100%',
            maxWidth: theme.spacing(4),
            height: 'auto'
          },
          '& .currency-img': {
            width: '100%',
            maxWidth: theme.spacing(2),
            height: 'auto'
          },
          '& .text': {
            color: theme.palette.primary.contrastText,
            fontSize: theme.spacing(1.6),
            [theme.breakpoints.down('xl')]: {
              fontSize: theme.spacing(1.4)
            }
          }
        }
      },
      "& .pagination-container": {
        display: "flex",
        justifyContent: "flex-end",
      },

      "& .pagination-btn": {
        border: "none",
        background: "#ccc",
        padding: "0 .5rem",
        borderRadius: "5px",
        margin: "0 5px",
        color: "#fff",
        background: theme.palette.primary.light,
        cursor: "pointer",
        "&.active": {
          background: theme.palette.button.activeBtn,
        },
      },
    },

    [theme.breakpoints.down(1024)]: {
      width: '100%',
    },

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4.375, 2),
    }
  }
})
