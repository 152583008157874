import { Box, styled } from '@mui/material'

export const ChatBoxWrapper = styled(Box)(({ theme }) => {
  return {
    '.notification-card': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0),
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',  // Changed from space-between to flex-start
      transition: 'background-color 0.3s ease-in-out',
      overflowY: 'auto',  // Enable vertical scrolling
      maxHeight: 'calc(100vh - 100px)',  // Set a max height with some margin from the bottom

      '& .notification-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '& .icon-container': {
          width: theme.spacing(3),
          height: theme.spacing(3),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
          '& img': {
            width: '70%',
            height: '70%',
          },
        },
        '& .notification-title': {
          flexGrow: 1,
          marginLeft: theme.spacing(1.5),
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.text.primary,
        },
        '& .notification-time': {
          fontSize: theme.typography.caption.fontSize,
          color: theme.palette.text.secondary,
        },
      },
      '& .notification-content': {
        marginTop: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '1.5',
        color: theme.palette.text.secondary,
        '& .highlight': {
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.primary.main,
        },
      },
    },
    '.chat-sidebar': {
      position: "fixed",
      right: "0",
      background: '#0B0116',
      zIndex: 999,
      width: "370px",
      height: "100vh",
      padding: theme.spacing(1, 2, 2, 2),
      paddingBottom: '5rem',
      overflowY: 'auto',  // Enable scrolling for the sidebar
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: "100vh",
        paddingBottom: '10rem',
      },
      [theme.breakpoints.down('customPoint_lg')]: {
        height: "100vh",
        paddingBottom: '10rem',
      },
      '& .chat-close-icon': {
        textAlign: 'end',
        '& button': {
          justifyContent: 'end',
          paddingRight: '0',
          minWidth: 'auto',
        }
      },
      '& .time-section': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(1, 0, 1, 0),
        '& .MuiTypography-root': {
          color: theme.palette.primary.contrastTextDark,
          fontSize: theme.spacing(1.25),
        }
      },
      '& .chat-box': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        '& .chat-item': {
          fontSize: theme.spacing(1.5),
          lineHeight: '1.2',
          display: 'inline-block',
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.contrastTextDark,
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1),
          '& .chat-by-user': {
            paddingRight: theme.spacing(0.5),
            color: '#b4aaaa',
          },
          '& .chat-tag-user': {
            background: "#221125",
            padding: "2px 6px",
            lineHeight: "normal",
            borderRadius: "4px",
            display: 'inline-block',
          }
        }
      }
    }
  }
})
