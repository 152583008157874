import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { setShowNotif } from 'redux-thunk/redux/slices/settings.slice'
// import { v4 as uuidv4 } from 'uuid'
import { ChatBoxWrapper } from './style'
import { Box, Button, Typography } from '@mui/material'
import { CrossIcon } from 'assets/icons/index'
import { fetchUserNotification } from 'redux-thunk/thunk/user.thunk'
import { getEmailCookie } from 'helpers/cookie.helpers'
import NotificationCard from './notificationCard';

const Notif = () => {
  const dispatch = useDispatch()
  const notificationRequestData = useSelector((state) => state.user.notificationRequestData);

  const handleCloseChat = () => {
    dispatch(setShowNotif(false))
    // dispatch(setChatCollapse(true))
  }


  useEffect(() => {
    if (getEmailCookie()) {

      const myParams = {
        email: getEmailCookie(),
      };
      dispatch(fetchUserNotification({ params: myParams }));
    }
  }, [])


  return (
    <ChatBoxWrapper>
      <Box className='chat-sidebar'>
        <Box className='chat-close-icon'>
          <Button onClick={handleCloseChat}>
            <CrossIcon />
          </Button>
        </Box>

        <Box>
          {notificationRequestData.length > 0 ? (
            notificationRequestData.map((notification) => (
              <NotificationCard key={notification.id} notification={notification} />
            ))
          ) : (
            <Typography variant='h6' sx={{ textAlign: 'center', color: '#fff', marginTop: '10rem' }}>No notifications available.</Typography>
          )}
        </Box>
      </Box>
    </ChatBoxWrapper>
  )
}

export default React.memo(Notif)
