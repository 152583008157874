import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { NavLink } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
// import { getItem } from 'helpers/localstorage.helpers'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
// import { BrandLogoIcon } from 'components/ui-kit/Icons/iconComponents/BrandLogoIcon'
// import { SHOW_SIDE_NAV } from 'constants/index'
import { Box, ButtonBase, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography, useTheme } from '@mui/material'
import { FooterWrapper } from './style'
import { Link } from '../../../../node_modules/@mui/material/index'
import { getAllCmsSiteData } from 'redux-thunk/thunk/gameSetting'
import {MainLogo} from 'assets/icons/index'
import currencyNetwork from 'assets/images/tron-token.webp'
import currencyNetwork2 from 'assets/images/btc.webp'
import currencyNetwork3 from 'assets/images/Dogecoin.webp'
import currencyNetwork4 from 'assets/images/Ethereum.webp'
import currencyNetwork5 from 'assets/images/Litecoin.webp'
import currencyNetwork6 from 'assets/images/Tether.webp'

const Footer = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const { cmsData } = useSelector((state) => state?.gameSetting)
  const cmsDetails = cmsData?.cmsDetails || []

  useEffect(() => {
    if (window.anj_ffd9dfa2_0287_41b9_b700_df661274a6f9) {
      window.anj_ffd9dfa2_0287_41b9_b700_df661274a6f9.init();
    } else {
      console.error('Anj seal script not loaded');
    }
  }, []);

  useEffect(() => {
    dispatch(getAllSiteinfo({}))
  }, [])

  const currencyNetworks = [
    { img: currencyNetwork },
    { img: currencyNetwork2 },
    { img: currencyNetwork3 },
    { img: currencyNetwork4 },
    { img: currencyNetwork5 },
    { img: currencyNetwork6 },
  ]

  return (
    <FooterWrapper theme={theme}>
      <Box className='footer-container'>
        <Grid container columnSpacing={1}>
          <Grid item lg={4} sm={12} xs={12}>
            <Box className='logo-wrap'>
              <MainLogo/>
              <Typography variant='body1'>Deuces is owned and operated by Cupertino Entertainment Ltd.. registration number: 27073 BC 2024, registered address: Suite 305, Griffith Corporate Centre, Kingstown, St Vincent and the Grenadines. Contact us support@deuces.com. Deuces is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. Act 007 of 2005. Deuces has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.</Typography>
            </Box>
          </Grid>
          <Grid item lg={4} sm={12} xs={12}>
            <Box className='footer-link-box'>
              <Grid container columnSpacing={1}>
                <Grid item lg={6} sm={12} xs={12} className='footer-link-in'>
                  <Typography variant='h6'>Platform</Typography>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => history.push('/casino')}>
                        <ListItemText primary='Casino' />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <Typography variant='h6'>Category</Typography>
                  <List>
                    {
                      cmsData?.cmsDetails
                        ? (
                            cmsData?.cmsDetails.map((data) => (
                              <ListItem disablePadding key={data.cmsPageId}>
                                <ListItemButton to={`/cms/${data.slug}`}>
                                  <ListItemText primary={data.title.EN} />
                                </ListItemButton>
                              </ListItem>
                            ))
                          )
                        : null
                    }
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} sm={12}>
            <Box className='footer-link-box'>
              <Grid container columnSpacing={1}>
                <Grid item lg={6} sm={12} xs={12} className='footer-link-in'>
                  <Typography variant='h6'>Social Media</Typography>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary='Facebook' />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary='Twitter' />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary='Instagram' />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary='Discord' />
                      </ListItemButton>
                    </ListItem>

                  </List>
                </Grid>
                <Grid item lg={6} sm={12}>
                  <Typography variant='h6'>Contact</Typography>
                  <Box className='contact-card-wrap'>
                    <Grid className='contact-card'>
                      <Typography>Support</Typography>
                      <Link href='#'>support@deuces.com</Link>
                    </Grid>
                    <Grid className='contact-card'>
                      <Typography>Partners</Typography>
                      <Link href='#'>partners@deuces.com</Link>
                    </Grid>
                    <Grid className='contact-card'>
                      <Typography>Legal</Typography>
                      <Link href='#'>legal@deuces.com</Link>
                    </Grid>

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

        </Grid>
      </Box>
      <div className="line"></div>
      <div className="image-crypto-network">
        {currencyNetworks.map((item, i) => (
        <div key={i}>
          <img src={item.img} className='' alt='' />
        </div>
        ))}
      </div>
      <div className="line"></div>
      <Box className='copyright-container'>
        <Typography variant='body2'>
          Copyright © 2024-2025 DEUCES. All rights reserved.
        </Typography>
      </Box>
      <div
          id="anj-ffd9dfa2-0287-41b9-b700-df661274a6f9"
          data-anj-seal-id="ffd9dfa2-0287-41b9-b700-df661274a6f9"
          data-anj-image-size="30"
          className='img-license'
        ></div>
    </FooterWrapper>
  )
}
export default Footer
