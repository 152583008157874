import { Box, styled } from '@mui/material';

export const ToasterWrapper = styled(Box)(({ theme }) => {
  return {
    '& .toasterSection': {
      backgroundColor: '#FFF',
      color: '#000', // '#FFF',
      padding: '0.8rem 1.2rem',
      borderRadius: '8px',
      position: 'fixed', // Fixed position relative to the viewport
      top: '80px', // Position from the top
      right: '20px', // Position from the left
      maxWidth: '25vw',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      zIndex: '999999',
      boxShadow: '0 0.8rem 3rem rgba(0,0,0,0.9)',

      '&.info': {
        backgroundColor: '#162C54',
        color: '#FFF',
        boxShadow: '0 0.8rem 3rem rgba(0,0,0,0.9)',
      },

      '&.error': {
        backgroundColor: '#B71C1C', color: '#FFF',
        boxShadow: '0 0.8rem 3rem rgba(0,0,0,0.9)',
      },

      '&.success': {
        backgroundColor: '#004D40',
        color: '#FFF',
        boxShadow: '0 0.8rem 3rem rgba(0,0,0,0.9)',
      },

      '& .title': {
        fontWeight: 'bold',
        fontSize: '15px',
      },

      '& p': {
        fontSize: '16px',
      },
      '& svg': {
        cursor: 'pointer',
      },

      '& .close': {
        cursor: 'pointer',
        position: 'absolute',
        top: '8px',
        right: '8px',

        '&:hover svg': {
          cursor: 'pointer',
          color: '#FF0000',
        },
      }
    },

    // Media Queries for Responsiveness
    [theme.breakpoints.down('md')]: {
      '& .toasterSection': {
        maxWidth: '90vw', // Reduce max width on smaller screens
        top: '100px', // Adjust position on smaller screens
        left: '10px', // Adjust position on smaller screens
        padding: '1rem', // Adjust padding for smaller screens
        fontSize: '12px', // Smaller font size for better fit
      },
    },

    [theme.breakpoints.down('sm')]: {
      '& .toasterSection': {
        maxWidth: '95vw', // Further reduce max width on very small screens
        padding: '0.8rem', // Adjust padding for very small screens
        fontSize: '10px', // Smaller font size for better fit
      },
    },
  };
});
