import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSideNav: true,
  showChat: false,
  showNotif: false,
  isChatCollapse: true,
  isNotifCollapse: true,
  showSearchPopup: false,
  toaster: { isVisible: false, message: '', type: 'info' }
}

const {
  actions: {
    setShowSideNav,
    setChatCollapse,
    setShowChat,
    setShowNotif,
    setNotifCollapse,
    setShowSearchPopup,
    setToaster
  },
  reducer
} = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setShowSideNav: (state, { payload }) => ({
      ...state,
      showSideNav: payload
    }),
    setChatCollapse: (state, { payload }) => ({
      ...state,
      isChatCollapse: payload
    }),
    setShowChat: (state, { payload }) => ({
      ...state,
      showChat: payload
    }),
    setNotifCollapse: (state, { payload }) => ({
      ...state,
      isNotifCollapse: payload
    }),
    setShowNotif: (state, { payload }) => ({
      ...state,
      showNotif: payload
    }),
    setShowSearchPopup: (state, { payload }) => ({
      ...state,
      showSearchPopup: payload
    }),
    setToaster: (state, { payload }) => ({
      ...state,
      toaster: { isVisible: payload?.isVisible, message: payload?.isVisible ? payload?.message : '', type: payload?.type || 'info' }
    })
  }
})

export default reducer
export {
  setShowSideNav,
  setChatCollapse,
  setShowChat,
  setShowNotif,
  setNotifCollapse,
  setShowSearchPopup,
  setToaster
}
