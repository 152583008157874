import React from 'react'

import {
  Route,
  Switch
} from 'react-router-dom'
import SportBookLobby from '../SportsPages/SportBookLobby/index'

// import SportBookLobby from "./SportsPages/SportBookLobby";
// import MoreMarkets from "../SportsBook/MoreMarkets";
// import SportBookLobby from "../SportsBook/Lobby";
// import Mybets from "../SportsBook/Mybets";
// import MatchResults from "../SportsBook/MatchResults";
// import Favorite from "../SportsBook/Favorites";

export const SportsRoutes = () => {
  return (
    <Route
      key='/sportsbook/lobby'
      path='/lobby'
			// render={() => <SportBookLobby />}
      component={() => <SportBookLobby />}
    />
  )
}
