import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, InputLabel, Typography } from '../../../node_modules/@mui/material/index'

import { ErrorMessage, Form, Formik } from 'formik'
import { CustomTextField } from 'components/layout/Common/StyledForm/style'
import { CustomMainButton } from 'components/layout/Common/StyledButton/CustomButton'
import { LOADER_HANDLER_TYPES } from 'constants/index'

import { NavLink } from 'react-router-dom'
import Loader from 'components/ui-kit/Loader/index'
import { verifyEmailSchema } from './schema'
import { setForgotPassword } from 'redux-thunk/redux/slices/auth.slice'
import { verifyEmail } from 'redux-thunk/thunk/auth.thunk'
import config from 'config/app.config'
import { ForgetPasswordModalStyle } from './style'
import AlertBox from 'components/Popup/AlertBox'
function VerifyEmail () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [alertBoxopen, setAlertBoxOpen] = useState(false)
  const [error, setError] = useState({
    name: '',
    message: ''
  })

  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const onVerifyEmailError = (error) => {
    setAlertBoxOpen(true)
    if (error?.[0]?.name === 'EmailNotExists') {
      setError({ name: error?.[0]?.name, message: t('emailNotExists') })
    } else {
      setError({ name: error?.[0]?.name, message: t(error?.[0]?.description) })
    }
  }
  const handleAlertBoxClose = () => {
    setAlertBoxOpen(false)
  }
  const handleVerifySubmit = async (value) => {
    const reqBody = {
      email: value?.email,
      origin: config.BASE_URl || 'http://localhost:3000',
      onVerifyEmailError
    }
    dispatch(
      verifyEmail(reqBody)
    )
  }
  return (
    <ForgetPasswordModalStyle>
      <Box>
        <Typography id='modal-modal-title' variant='h6' component='h2' className='modal-heading'>
          {t('forgetPassword')}
        </Typography>
      </Box>
      <Box>
        <Formik
          enableReinitialize
          initialValues={{ email: '' }}
          validationSchema={verifyEmailSchema(t)}
          onSubmit={({ email }) =>
            handleVerifySubmit({ email })}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur
          }) => {
            return (
              <Form autoComplete='off'>
                <Grid container spacing={2} className='login-form-group'>
                  <Grid item xs={12}>
                    <InputLabel className='input-label' htmlFor='Enter Email' sx={{ color: '#FFF', marginBottom: '10px' }}>
                      {t('email')}
                    </InputLabel>
                    <CustomTextField
                      placeholder={t('enterYourEmail')} name='email'
                      value={values.email}
                      onChange={(e) => {
                        alertBoxopen && setAlertBoxOpen(false)
                        e.target.value = e.target.value.trim()
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      maxLength={50}
                      haslabel='yes'
                    />
                    <ErrorMessage
                      component='div'
                      name='email'
                      className='color-red text-danger error-msg login-input ms-2 d-flex'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AlertBox
                      open={alertBoxopen}
                      handleClose={handleAlertBoxClose}
                      messageType='error'
                      message={error?.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomMainButton
                      type='submit'
                      onSubmit={handleSubmit}
                      fullWidth
                    >{
                        loading
                          ? <Loader variant={LOADER_HANDLER_TYPES.submit} />
                          : <span>{t('verifyEmail')}</span>
                      }
                    </CustomMainButton>
                  </Grid>

                </Grid>

                <Box className='or-divider'>
                  <span className='custom-divider'>{t('or')}</span>
                </Box>

                <Box className='login-text' sx={{ width: '100%', textAlign: 'center' }}>
                  <NavLink onClick={() => dispatch(setForgotPassword(false))} to='#' style={{ color: '#fff' }}>{t('login')} ?</NavLink>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </ForgetPasswordModalStyle>
  )
}

export default memo(VerifyEmail)
