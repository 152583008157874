import React from 'react'
import { FeaturedMatchesContainer } from './style'

const FeaturedMatches = () => {
	return (
		<FeaturedMatchesContainer>
			Country top Bar
		</FeaturedMatchesContainer>
	)
}

export default FeaturedMatches