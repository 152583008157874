import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage as ErrorMessageField } from 'formik'

const ErrorMessage = (props) => {
  const {
    className
  } = props

  return (
    <ErrorMessageField
      {...props}
      className={className}
      style={{ color: 'red', fontSize: '0.8rem' }}
    />
  )
}

ErrorMessage.defaultProps = {
  className: 'color-red',
  id: '',
  style: {},
  message: '',
  children: null
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  message: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any)
}

export default memo(ErrorMessage)
