import { Box, styled } from '@mui/material'
import loadingScreen from 'assets/images/0530.gif'

const StyledContainer = styled(Box)({
  textAlign: 'center',
  background: '#000',
  width: '100%',
  top: '0',
  left: '0',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: '450px'
  },
  '&  .MuiTypography-h2': {
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '1rem'
  },
  '& .loader-content-wrap': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&  .MuiTypography-body2': {
      color: '#fff',
      fontSize: '2rem',
      marginTop: '1rem'
    }
  }
})

const NotFound = () => {
  return (
    <StyledContainer>
      <Box className='loader-content-wrap'>
        <img src={loadingScreen} alt="" />
      </Box>
    </StyledContainer>
  )
}

export default NotFound
