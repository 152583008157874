import Select from "react-select";

export const ReactSelect = (props) => {
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparen",
      border: "1.5px solid rgba(255,255,255,0.2)",
      height: "52px",
      marginTop: "2px",
      borderRadius: "8px",
      outline: "none",

      "&:focus-within": {
        borderColor: "#fff",
      },
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: "#121C27",
      color: "white",
      borderColor: "#121C27",
    }),
    singleValue: (styles) => ({ ...styles, color: "white" }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#0096b0",
      };
    },
  };

  return <Select {...props} styles={colourStyles} />;
};
