import Loader from "components/ui-kit/Loader/index";
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from "constants/index.js";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { setShowLoginPopup } from "redux-thunk/redux/slices/gameSetting.slice";
import { userLogin } from "redux-thunk/thunk/auth.thunk";
import { userLoginSchema } from "./schema";

import { TabContext, TabList, TabPanel } from "@mui/lab/index";
import Tab from "@mui/material/Tab";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material/index";
import { CustomTextField } from "components/layout/Common/StyledForm/style";
import { LoginModalStyle } from "./style";

import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WestIcon from "@mui/icons-material/West";
import AlertBox from "components/Popup/AlertBox";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import { LANGUAGES } from "constants/index";
import VerifyEmail from "pages/ForgetPassword/VerifyEmail";
import Signup from "pages/Signup/index";
import { IoKeyOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { PiLineVerticalLight } from "react-icons/pi";
import {
  setAuthTabPanelValue,
  setForgotPassword,
} from "redux-thunk/redux/slices/auth.slice";
import { getLoginBannerList } from "redux-thunk/thunk/casino.thunk";
import { getDeviceType } from "utils/getDeviceType";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [alertBoxopen, setAlertBoxOpen] = useState(false);
  const [error, setError] = useState({
    name: "",
    message: "",
  });

  const loginHandleClose = () => {
    dispatch(setForgotPassword(false));
    dispatch(setShowLoginPopup(false));
  };

  const { showLoginPopup } = useSelector((state) => state.gameSetting);
  const { forgotPassword } = useSelector((state) => state.auth);
  const { authTabPanelValue } = useSelector((state) => state?.auth);
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  );

  const [showPassword, setShowPassword] = useState(false);
  const { language } = useSelector((state) => state.auth);
  const { loginBanners } = useSelector((state) => state.casino);

  const selectedLang = LANGUAGES.find((item) => item.languageCode === language);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (event, newValue) => {
    dispatch(setAuthTabPanelValue(newValue));
  };

  const handleAlertBoxClose = () => {
    setAlertBoxOpen(false);
  };

  const onLoginSuccess = () => {
    if (location.pathname.match("/casino/play-game")) {
      history.replace(ROUTE_PATHS.CASINO);
    } else if (location.pathname?.match("/verify")) {
      history.replace(ROUTE_PATHS.HOME);
    } else {
      history.replace(location.pathname);
    }
    dispatch(setShowLoginPopup(false));
  };

  const OnLoginError = (error) => {
    setAlertBoxOpen(true);
    if (error?.[0]?.name === "INCORRECT_CREDENTIAL") {
      setError({ name: error?.[0]?.name, message: t("invalidCredentials") });
    } else {
      setError({ name: error?.[0]?.name, message: t(error?.[0]?.description) });
    }
  };

  const handleLoginSubmit = async ({ email, password }) => {
    dispatch(
      userLogin({
        userNameOrEmail: email,
        password,
        deviceType: getDeviceType(),
        onLoginSuccess,
        OnLoginError,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getLoginBannerList({
        key: "loginBanner",
      })
    );
  }, []);

  useEffect(() => {
    const body = document.body;
    body?.classList.add("overflowHidden");
    return () => {
      body?.classList.remove("overflowHidden");
    };
  }, []);

  const loginSlider = {
    dots: true,
    infinite: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Modal
        open={showLoginPopup}
        onClose={loginHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginModalStyle
          className={`login-signup-modal ${forgotPassword && "forget-modal"}`}
        >
          {forgotPassword && (
            <Box className="forgot-back-btn">
              <IconButton onClick={() => dispatch(setForgotPassword(false))}>
                <WestIcon sx={{ color: "#433F4C" }} />
              </IconButton>
            </Box>
          )}
          <Box className="forgot-close-btn">
            <IconButton onClick={loginHandleClose}>
              <CloseIcon sx={{ color: "#433F4C" }} />
            </IconButton>
          </Box>
          {forgotPassword ? (
            <VerifyEmail />
          ) : (
            <Grid container spacing={0.5}>
              {/* <Grid className='login-signup-img' item xs={12} lg={6}>
                  <Grid className='login-slider-wrap'>
                    <Slider {...loginSlider}>
                      {loginBanners
                        ? loginBanners?.rows?.map(({ title, imageUrl, description, redirectUrl, documentUrl, fileName }) => {
                            const aElem = document.createElement('a')
                            aElem.target = '_blank'
                            aElem.href = redirectUrl
                            return (
                              <Box key={uuidv4()} className='slider-img-box'>
                                <img
                                  src={`${process.env.REACT_APP_S3_URL}${imageUrl}`}
                                  src={imageBanner}
                                  alt='Slider Img'
                                />
                                <Box className='hero-heading-section'>
                                  <Box className='hero-heading-box '>
                                    <Typography className={`banner-heading ${redirectUrl ? 'banner-link' : ''}`}>
                                      <span onClick={() => {
                                        if (redirectUrl) {
                                          aElem.click()
                                        }
                                      }}
                                      >{title?.[selectedLang?.languageCode?.toUpperCase()] || title?.EN || ''}
                                      </span>

                                        {t('signUpAndGet')}
                                    </Typography>
                                    <Typography className='rake-disc-banner'>
                                        {t('rakeDisc')}
                                    </Typography>
                                    <Typography className='box-banner-footer'>
                                        <div className="box-image-banner-footer">
                                          <img src={imageBannerLogo} alt="" />
                                        </div>
                                        {t('bannerFooter')}
                                    </Typography>
                                    <Box className='inner-banner-heading'>
                                      {description?.[selectedLang?.languageCode?.toUpperCase()] || description?.EN || ''}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            )
                          })
                        : null}
                    </Slider>
                  </Grid>
                </Grid> */}
              <Grid item xs={12}>
                <Box className="login-modal">
                  {/* <Box>
                      <Typography id='modal-modal-title' variant='h6' component='h2' className='modal-heading'>
                        {authTabPanelValue === '1' && t('logIn')}
                        {authTabPanelValue === '2' && t('signUp')}
                      </Typography>
                    </Box> */}
                  <Box sx={{ width: "100%" }} className="login-tab">
                    <TabContext value={authTabPanelValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          className="login-inner-tab"
                        >
                          <Tab
                            label={t("logIn")}
                            value="1"
                            className="item-one"
                          />
                          <Tab
                            label={t("signUp")}
                            value="2"
                            className="item-two"
                          />
                        </TabList>
                      </Box>
                      <TabPanel value="1" className="tab-panel">
                        <Box>
                          <Formik
                            enableReinitialize
                            initialValues={{ email: "", password: "" }}
                            validationSchema={userLoginSchema(t)}
                            onSubmit={({ email, password }) =>
                              handleLoginSubmit({ email, password })
                            }
                          >
                            {({
                              values,
                              handleChange,
                              handleSubmit,
                              handleBlur,
                            }) => {
                              return (
                                <Form autoComplete="off">
                                  <Grid
                                    container
                                    // spacing={2}
                                    className="login-form-group"
                                  >
                                    <Grid item xs={12}>
                                      <InputLabel
                                        className="input-label"
                                        htmlFor="Enter Email"
                                      >
                                        {t("signinUsername")}{" "}
                                        <Typography className="required-indicator">
                                          *
                                        </Typography>
                                      </InputLabel>
                                      <CustomTextField
                                        placeholder={t("emailUserName")}
                                        name="email"
                                        value={values.email}
                                        onChange={(e) => {
                                          alertBoxopen &&
                                            setAlertBoxOpen(false);
                                          e.target.value =
                                            e.target.value.trim();
                                          handleChange(e);
                                        }}
                                        // onBlur={handleBlur}
                                        maxLength={50}
                                        haslabel="yes"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <MdOutlineAlternateEmail
                                                style={{ color: "#667FA4" }}
                                                edge="start"
                                              />
                                              <PiLineVerticalLight
                                                style={{ color: "#667FA4" }}
                                                edge="start"
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="email"
                                        className="color-red text-danger error-msg login-input ms-2 d-flex"
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <InputLabel
                                        className="input-label"
                                        htmlFor="Password"
                                      >
                                        {t("profilePassword")}
                                        <Typography className="required-indicator">
                                          *
                                        </Typography>
                                      </InputLabel>
                                      <CustomTextField
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        placeholder={t("signinPassword")}
                                        className="form-control shadow-none"
                                        name="password"
                                        value={values.password}
                                        onChange={(e) => {
                                          alertBoxopen &&
                                            setAlertBoxOpen(false);
                                          e.target.value =
                                            e.target.value.trim();
                                          handleChange(e);
                                        }}
                                        // onBlur={handleBlur}
                                        maxLength={50}
                                        minLength={8}
                                        haslabel="yes"
                                        // required
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IoKeyOutline
                                                style={{ color: "#667FA4" }}
                                                edge="start"
                                              />
                                              <PiLineVerticalLight
                                                style={{ color: "#667FA4" }}
                                                edge="start"
                                              />
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                onClick={handleTogglePassword}
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <VisibilityIcon />
                                                ) : (
                                                  <VisibilityOffIcon />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />

                                      <ErrorMessage
                                        component="div"
                                        name="password"
                                        className="color-red text-danger error-msg login-input ms-2 d-flex"
                                      />
                                    </Grid>

                                    <Box className="forgot-text">
                                      <NavLink
                                        onClick={() => {
                                          dispatch(setForgotPassword(true));
                                        }}
                                        to="/"
                                        className="reset-password"
                                      >
                                        {t("signinForgetPassword")}
                                      </NavLink>
                                    </Box>
                                    {/* {add alert} */}

                                    <Grid item xs={12}>
                                      <AlertBox
                                        open={alertBoxopen}
                                        handleClose={handleAlertBoxClose}
                                        messageType="error"
                                        message={error?.message}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <CustomMainButton
                                        className="signupLoginBtn"
                                        type="submit"
                                        onSubmit={handleSubmit}
                                        fullWidth
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <Loader
                                            variant={
                                              LOADER_HANDLER_TYPES.submit
                                            }
                                          />
                                        ) : (
                                          <span>{t("signupLoginBtn")}</span>
                                        )}
                                      </CustomMainButton>
                                    </Grid>
                                  </Grid>

                                  {/* <Box className='or-divider'>
                                      <span className='custom-divider'>
                                        {t('or')}
                                      </span>
                                    </Box>

                                    <SocialMediaLogin /> */}

                                  {/* <Box className='forgot-text'>
                                      <Typography className='primary-dark-text'>
                                        {t('signinDontHaveAccount')}
                                      </Typography>{' '}
                                      <Link
                                        href='#'
                                        onClick={() =>
                                          dispatch(setAuthTabPanelValue('2'))}
                                      >
                                        {t('registerNow')}
                                      </Link>
                                    </Box> */}

                                  <Box className="captcha">
                                    <Typography className="text-captcha">
                                      {t("captcha")}
                                    </Typography>
                                  </Box>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Box>
                      </TabPanel>

                      <TabPanel value="2" className="tab-panel">
                        <Signup />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </LoginModalStyle>
      </Modal>
    </>
  );
};

export default Login;
