import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, Box, Typography, styled } from '@mui/material'
import NotFoundImage from '../../../assets/images/PageNotFound404.webp'

const StyledContainer = styled(Box)({
  textAlign: 'center',
  background: '#1B1821',
  width: '100vw',
  top: '0',
  left: '0',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '&  .MuiTypography-h2': {
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '1rem'
  }
})
const StyledImage = styled('img')({
  width: '100%',
  maxHeight: '300px',
  objectFit: 'cover'

})

const StyledButton = styled(Button)({
  marginTop: '20px'
})

const NotFound = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [redirectTime, setRedirectTime] = useState(5)

  useEffect(() => {
    const redirectTimer = setInterval(() => {
      setRedirectTime((prevTime) => prevTime - 1)
    }, 1000)

    const redirectTimeout = setTimeout(() => {
      history.push('/')
    }, redirectTime * 1000)

    return () => {
      clearInterval(redirectTimer)
      clearTimeout(redirectTimeout)
    }
  }, [history, redirectTime])

  const handleRedirect = () => {
    history.push('/')
  }

  return (
    <StyledContainer>
      <Typography variant='h2' color='primary' gutterBottom>
        {t('ooops')}
      </Typography>
      <StyledImage src={NotFoundImage} alt='404 Image' />
      <Typography variant='h5' color='#fff' paragraph sx={{ mt: 3 }}>
        {t('pageNotFound')}
      </Typography>
      <StyledButton variant='contained' color='primary' onClick={handleRedirect} sx={{ background: '#4caf50' }}>
        {t('verifyEmailGoToHome')}
      </StyledButton>
      <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress
          variant='determinate'
          value={(redirectTime / 5) * 100}
          size={50}
          thickness={5}
          sx={{ color: '#4caf50' }}
        />
        <Typography variant='body2' color='#fff' style={{ marginLeft: '10px', display: 'inline' }}>
          {t('redirectingMessage', { redirectTime: redirectTime })}
        </Typography>
      </div>
    </StyledContainer>
  )
}

export default NotFound
