import { Box } from '@mui/material'
import { styled } from '@mui/styles'

export const BuyCryptoStyle = styled(Box)(({ theme }) => {
  return {
    color: '#fff',
    '& .label': {
      color: '#fff',
      display: 'flex',
      alignItems: 'start',
      '& p': {
        fontSize: '15px',
        marginTop: '.7rem',
        marginBottom: '.3rem',
      },
      '& .required-indicator': {
        color: 'red',
      },
    },
    '& .moonpay': {
      display: 'flex',
      alignItems: 'center',
      gap: '.3rem',
      '& .icon': {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: '#37434B',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .bullet': {
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#D9D9D9',
        },
      },
      '& .type p': {
        fontSize: '14px',
      },
    },
    '& .box-estimated': {
      display: 'flex',
      justifyContent: 'space-between',
      border: '2.5px solid green',
      borderRadius: '8px',
      padding: '10px',
      backgroundColor: '#262c3e',
      '& p': {
        margin: '0 !important',
      },
      '& .user-get': {
        '& .desc p': {
          textAlign: 'right',
          color: '#C4C4C4',
          fontSize: '12px',
        },
        '& .amount p': {
          fontWeight: 'bolder',
        },
      },
    },
    '& .disclaimer': {
      '& p': {
        fontSize: '14px',
        color: '#A5A5A5',
      }
    },
    '& .header-wallet-section': {
      height: '2rem',
      background: '#0d1b32',
    },
    '& .select-crypto': {
      background: '#0d1b32',
      maxHeight: theme.spacing(6.25),
      borderRadius: theme.spacing(1.25),
      marginBottom: '5px',
      color: '#fff',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.contrastText
      },
    },
  }
})
