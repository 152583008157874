export default {
  404: '404',
  mobileLeftNavRegister: 'Registrieren',
  mobileLeftNavLogin: 'Anmeldung',
  mobileLeftNavGrand: 'Großartig',
  mobileLeftNavMajor: 'Wesentlich',
  mobileLeftNavMinor: 'Unerheblich',
  mobileLeftNavMini: 'Mini',
  crashGameTitle: 'Absturz',
  inputFieldBetAmount: 'Einsatzbetrag',
  inputFieldProfit: 'Profitieren',
  inputFieldPayout: 'Zielauszahlung',
  inputFieldChance: 'Gewinnchance',
  inputFieldBetBtn: 'Wette',
  inputFieldAutoCashout: 'Automatische Auszahlung',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  inputFieldBtnMax: 'max',
  fieldsProfit: 'Profitieren',
  fieldsWagered: 'Wetten',
  fieldsLost: 'Verloren',
  fieldsWon: 'Gewonnen',
  fieldsStreak: 'Strähne',
  fieldsHighestWin: 'Höchster Gewinn',
  fieldsHighestLost: 'Höchster Verlust',
  fieldsHighestBet: 'Höchste Wette',
  fieldsMaxStreak: 'Max Streak',
  fieldsMaxProfit: 'Maximaler Gewinn: ',
  fieldsBal: 'Bal: ',
  messagesNoBetHistory: 'Ihr Wettverlauf ist leer',
  messagesCopyNotSupported: 'Ihr Browser unterstützt kein automatisches Kopieren in die Zwischenablage.\n',
  messagesCopied: 'Adresse kopiert.',
  toolTipDeposit: 'Kaution',
  toolTipStatReset: 'Statistiken zurücksetzen',
  toolTipMaxProfit: 'Maximaler Gewinn',
  rollOver: 'Sich umdrehen',
  buttonTextPlaceBet: 'Wetteinsatz festlegen',
  buttonTextCancelBet: 'Wette stornieren',
  buttonTextEscapeBet: 'Auszahlen',
  buttonTextStartAutoBet: 'Autobet starten',
  buttonTextCancelAutoBet: 'Kündigen Sie Autobet',
  buttonTextLoading: 'Wird geladen',
  tabsMyBets: 'Meine Wetten',
  tabsDeposit: 'Kaution',
  tabsCashout: 'Auszahlungen',
  tabsAllbets: 'Alle Wetten',
  tabsTopbets: 'TOP-Wetten',
  tabsBonus: 'Bonus',
  tabsJackpot: 'Jackpot',
  tabsBetHistory: 'Wettverlauf',
  timerTextCurrentPayout: 'Aktuelle Auszahlung',
  timerTextStarting: 'Ab in',
  timerTextSec: 'S',
  timerTextFlewAway: 'Flog weg',
  timerTextWait: 'Warten Sie mal..',
  timerTextPreparingRound: 'Runde vorbereiten',
  timerTextLoadingRound: 'Laderunde',
  comingSoon: 'Demnächst',
  labelsProfitOnWin: 'Profitieren Sie vom Sieg',
  inputsNoOfBets: 'Anzahl der Wetten',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Start',
  inputsCancel: 'Stornieren',
  buttonsMin: 'Mindest',
  buttonsMax: 'max',
  prediction: 'Vorhersage',
  luckyNumber: 'Glückszahl',
  multiplier: 'Multiplikator',
  chanceToWin: 'Gewinnchance',
  betBtn: 'Wette',
  bets: 'Wetten',
  depositBtn: 'Kaution',
  rollUnder: 'Unterrollen',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Geschichte',
  navBarMyBets: 'Meine Wetten',
  navBarAllBets: 'Alle Wetten',
  navBarHighRollers: 'High Roller',
  navBarRoundHistory: 'Runde Geschichte',
  navBarTotal: 'Gesamtwetten',
  topHeaderBetId: 'Wett-ID',
  topHeaderUser: 'Benutzer',
  topHeaderPayout: 'Auszahlung',
  topHeaderAmount: 'Menge',
  topHeaderTime: 'Zeit',
  topHeaderProfit: 'Profitieren',
  topHeaderRoundCrashed: 'Abgestürzt',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Haschisch',
  topHeaderRoundBet: 'Wette',
  topHeaderRoundProfit: 'Profitieren',
  topHeaderRoundSignature: 'Unterschrift',
  roundHistoryTitle: 'SPIELINFORMATIONEN',
  roundHistoryDescGame: 'Spiel',
  roundHistoryDescCrashAt: 'Absturz bei:',
  roundHistoryDescDate: 'Datum :',
  roundHistoryDescBetHeader: 'Wette',
  roundHistoryTableTitlePlayer: 'SPIELER',
  roundHistoryTableTitleBet: 'WETTE',
  roundHistoryTableTitleCashedOut: 'AUSGEZAHLT',
  roundHistoryTableTitleProfit: 'PROFITIEREN',
  roundHistoryTableTitleBetId: 'BET-ID',
  roundHistoryMessagesNoBetHistory: 'Ihr Wettverlauf ist leer',
  placedBetsTabPlaced: 'Platzierte Wette',
  placedBetsTabPrev: 'Vorherige Wette',
  placedBetsTitleUser: 'Benutzer',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Wette',
  placedBetsTitleProfit: 'Profitieren',
  placedBetsTitleCashout: 'Auszahlen',
  placedBetsNoPlacedBet: 'Keine platzierten Wetten.',
  provablyFairContentCheckBtn: 'Prüfen Sie Provable Fair',
  provablyFairContentTitle: 'Nachweislich fair',
  provablyFairContentDesc: 'Das Ergebnis der vierseitigen Führung kann als fair bewiesen werden. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Absturzrate',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Absturzrate ohne x',
  provablyFairContentInputFieldsRoundHashTitle: 'Runder Hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'z.B. ',
  provablyFairContentInputFieldsSubmitBtn: 'Signatur generieren',
  provablyFairContentSignature: 'Runde Signatur',
  provablyFairContentErrorsCrashRate: 'Bitte geben Sie die Zahl in das Feld für die Absturzrate ein',
  resetPasswordTitle: 'Passwort zurücksetzen',
  resetPasswordNewPasswordTitle: 'Neues Kennwort',
  resetPasswordNewPasswordErrorsRequired: '*Passwort ist erforderlich.',
  resetPasswordNewPasswordErrorsMinLength: 'Mindestens 8 Zeichen erlaubt.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximal 16 Zeichen erlaubt.',
  resetPasswordNewPasswordErrorsPattern: '*Passwort sollte aus 8 bis 16 alphanumerischen Zeichen und Sonderzeichen bestehen. ',
  resetPasswordConfirmPasswordTitle: 'Bestätige das Passwort',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Passwörter stimmen nicht überein.',
  resetPasswordResetBtn: 'Zurücksetzen',
  resetPasswordResetSuccess: 'Passwort erfolgreich geändert.',
  resetPasswordWentWrong: 'Etwas ist schief gelaufen!',
  levelGrand: 'GROSSARTIG',
  levelMajor: 'WESENTLICH',
  levelMinor: 'UNERHEBLICH',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Gewinnen Sie täglich $1000 ',
  bannerContentContest: 'Gewinnspiele jetzt ',
  bannerContentJoin: 'Nehmen Sie an unserem täglichen Wettwettbewerb teil und werden Sie zum Helden!  ',
  bannerContentPlayNow: 'jetzt spielen',
  bannerContentTitle: 'Jackpot gewinnen',
  bannerContentContent: 'Spiel mehr',
  bannerContentGameName: 'CRASH-SPIEL',
  bannerContentGameDesc: 'Spiel mehr',
  partners: 'Partner',
  gamesGoldenDragon: 'goldener Drache',
  gamesCrashGame: 'Crash-Spiel',
  gamesDice: 'Würfel',
  gamesHilo: 'Hilo',
  allBetsTitle: 'Alle Wetten',
  allBetsBetId: 'Wett-ID',
  allBetsUser: 'Benutzer',
  allBetsPayout: 'Auszahlung',
  allBetsAmount: 'Menge',
  allBetsTime: 'Zeit',
  allBetsProfit: 'Profitieren',
  login: 'Anmeldung',
  register: 'Registrieren',
  LoginwithOtp: 'Melden Sie sich mit Otp an',
  EnterLoginOtp: 'Geben Sie Login-Otp ein',
  submit: 'Einreichen',
  footerAboutUs: 'Über uns',
  footerFaq: 'Häufig gestellte Fragen',
  footerSitePolicy: 'Website-Richtlinie',
  footerResponsibleGambling: 'Verantwortungsvolles Spielen',
  footerTerms: 'Geschäftsbedingungen',
  footerCopywrite: '2022 KRYPTO-LINIE. ',
  signinTitle: 'Anmeldung',
  signinUsername: 'Benutzername / E-Mail',
  signinPassword: 'Passwort',
  signinLoginBtn: 'Anmeldung',
  signinSignup: 'Anmeldung!',
  signinForgetPassword: 'Passwort vergessen?',
  signinDontHaveAccount: 'Sie haben kein Konto? ',
  signinCreateAccount: 'Benutzerkonto erstellen',
  signinLoginDescription: 'Clutch Gaming kann Ihnen gelegentlich kostenlose Angebote und Sonderaktionen per E-Mail oder SMS zusenden. ',
  signinErrorsUserNameRequired: '*Bitte geben sie einen Benutzernamen ein.',
  signinErrorsUserNameMinLength: 'Mindestens 4 Zeichen erlaubt.',
  signinErrorsUserNameMaxLength: 'Maximal 20 Zeichen erlaubt.',
  signinErrorsUserNamePattern: 'Bitte geben Sie einen gültigen Benutzernamen ein, es sind nur Buchstaben und Zahlen erlaubt.',
  signinErrorsPasswordRequired: '*Passwort ist erforderlich.',
  signinErrorsPasswordMinLength: 'Mindestens 8 Zeichen erlaubt.',
  signinErrorsPasswordMaxLength: 'Maximal 16 Zeichen erlaubt.',
  signinErrorsPasswordPattern: '*Passwort sollte aus 8 bis 16 alphanumerischen Zeichen und Sonderzeichen bestehen. ',
  signinSuccessfullyLoggedIn: 'Erfolgreich eingeloggt.',
  signinResetMailSent: 'E-Mail zum Zurücksetzen des Passworts gesendet.',
  signinTryAgain: 'Etwas ist schief gelaufen. Bitte versuche es erneut.',
  signinResetPasswordUsernameErr: 'Bitte geben Sie den Benutzernamen/die E-Mail-Adresse ein, um das Passwort zurückzusetzen.',
  signinResetMailSentSuccessContent: 'Wir haben Ihnen einen Link zum Zurücksetzen des Passworts per E-Mail gesendet. ',
  signupTitle: 'Registrieren',
  signupUsername: 'Nutzername',
  signupEmail: 'Email',
  signup_TermsConditions: 'Bedingungen',
  signup_WalletConnect: 'Melden Sie sich mit Wallet an ',
  signupPassword: 'Passwort',
  signupEighteenPlus: 'Ich bin über 18 Jahre alt und stimme den Nutzungsbedingungen zu',
  signupRegisterBtn: 'Anmeldung',
  signupUseSocialAccount: 'Oder verwenden Sie ein soziales Konto',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Ein Konto haben? ',
  signupLoginBtn: 'Anmeldung',
  signupErrorsUserNameRequired: '*Bitte geben sie einen Benutzernamen ein.',
  signupErrorsUserNameMinLength: 'Mindestens 4 Zeichen erlaubt.',
  signupErrorsUserNameMaxLength: 'Maximal 20 Zeichen erlaubt.',
  signupErrorsUserNamePattern: 'Bitte geben Sie einen gültigen Benutzernamen ein, es sind nur Buchstaben und Zahlen erlaubt.',
  signupErrorsEmailRequired: '*Bitte E-Mail eingeben.',
  signupErrorsEmailMinLength: 'Mindestens 4 Zeichen erlaubt.',
  signupErrorsEmailMaxLength: 'Maximal 20 Zeichen erlaubt.',
  signupErrorsEmailPattern: 'Bitte eine gültige E-Mail-Adresse eintragen.',
  signupErrorsPasswordRequired: '*Passwort ist erforderlich.',
  signupErrorsPasswordMinLength: 'Mindestens 8 Zeichen erlaubt.',
  signupErrorsPasswordMaxLength: 'Maximal 16 Zeichen erlaubt.',
  signupErrorsPasswordPattern: '*Passwort sollte aus 8 bis 16 alphanumerischen Zeichen und Sonderzeichen bestehen. ',
  signupPlaceholderUserName: 'Mindest. ',
  signupPlaceholderEmail: 'z.B. ',
  signupPlaceholderPassword: 'Mindest. ',
  signupSomethingWentWrong: 'Etwas ist schief gelaufen!',
  signupSignupGoogle: 'Melden Sie sich erfolgreich bei Google an.',
  signupRegistered: 'Erfolgreich registriert.',
  signupConfirmAge: 'Bitte bestätigen Sie, dass Sie 18 Jahre alt sind.',
  signupSignupFacebook: 'Melde dich erfolgreich bei Facebook an.',
  signupCookiesConsent: 'Bitte erlauben Sie Cookies von Drittanbietern in den Einstellungen Ihres Browsers, um Social Login zu verwenden.',
  signupRegisterEmailPopupContent: 'Wir haben Ihnen eine Bestätigungs-E-Mail gesendet. ',
  accountMenuDeposit: 'Kaution',
  accountMenuCashout: 'Auszahlen',
  accountMenuJackpotWinners: 'Jackpot-Gewinner',
  accountMenuTransactions: 'Transaktionen',
  accountMenuPromotions: 'Werbeaktionen',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Ausloggen',
  accountMenu_Sound: 'Klang',
  accountMenuMusic: 'Musik',
  accountMenuWithdraw: 'Zurückziehen',
  pageNotFound: 'Wir können die gesuchte Seite nicht finden!',
  error: 'Fehler',
  somethingWentWrong: 'Etwas ist schief gelaufen!',
  verifyEmailThankYou: 'Danke schön!',
  verifyEmailEmailIsVerified: 'Ihre E-Mail ist verifiziert.',
  verifyEmailGoToLogin: 'Gehen Sie zur Anmeldeseite',
  verifyEmailWentWrong: 'Etwas ist schief gelaufen!',
  verifyEmailGoToHome: 'Gehen Sie zur Startseite',
  verifyEmailSuccessMsg: 'E-Mail erfolgreich bestätigt.',
  gameCardTitle: 'Clutch Gaming nachweislich faire Krypto-Glücksspielseite',
  bonusSectionTitle: 'BITSPIEL',
  bonusSectionSpanText: 'präsentiert die großzügigsten und vielfältigsten',
  bonusSectionBonuses: 'Boni',
  bonusSectionSubtitleText: 'Spielen Sie mit Vergnügen und erhalten Sie während des Spiels zusätzliche Belohnungen. ',
  bonusSectionHeadingText: 'Boni von Clutch Gaming',
  bonusSectionDesc: 'Als bestes Bitcoin-Casino verfügt Clutch Gaming über ein ausgeklügeltes System großzügiger Boni. ',
  JoiningBonusTitle: 'Beitrittsbonus',
  JoiningBonusButton: 'Beanspruche jetzt',
  JoiningBonusDesc: 'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
  depositBonusTitle: 'Einzahlungsbonus',
  depositBonusButton: 'Kaution',
  depositBonusDesc: 'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
  refferBonusTitle: 'Empfehlungsbonus',
  refferBonusButton: 'Kaution',
  refferBonusDesc: 'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
  aboutSectionTitle: 'Was ist Clutch Gaming',
  aboutSectionSpanText1: ' Clutch Gaming ist eine innovative Bitcoin-Casinoseite, die mit Blick auf neue Konzepte des Online-Glücksspiels erstellt wurde. ',
  aboutSectionSpanText2: 'Das Online-Casino ermöglicht es Ihnen, Ihre Lieblingsspiele entweder kostenlos oder für Kryptowährung zu spielen. ',
  aboutSectionSpanText3: ' In dieser virtuellen Welt des Bitcoin-Glücksspiels können Sie viele Möglichkeiten genießen – Ihre Lieblings-Casinospiele spielen, Boni erhalten, an Wettbewerben und Werbeaktionen teilnehmen.',
  verifiedSectionTitle: 'Verifizierte und lizenzierte Software',
  verifiedSectionSpanText1: 'Wir kopieren oder übernehmen keine Software von anderen Websites. ',
  faqSectionTitle: 'FAQ von Spielern',
  faqSectionForgetPasswordTitle: 'Ich habe mein Passwort vergessen.',
  faqSectionForgetPasswordDesc: 'Sie haben keine Wiederherstellungs-E-Mail-Adresse für Ihr Konto angegeben. ',
  faqSection_MyDepositCreditedTitle: 'Meine Einzahlung wurde nicht gutgeschrieben.',
  faqSection_MyDepositCreditedDesc: ' Platzhalterinhalt für dieses Akkordeon, der die Klasse demonstrieren soll. ',
  SupportedCurrencyTitle: 'Unterstützte Währung',
  SupportedCurrencySpanText1: 'Wir kopieren oder übernehmen keine Software von anderen Websites. ',
  CarouselSectionTitle: 'HODL-ABSTURZ',
  CarouselSectionSubTitle: ' Spielen Sie mehr Spiele und erhalten Sie die Chance zu gewinnen. ',
  WalletSettingTitle: 'Wallet-Einstellung',
  WalletSettingHideZero: 'Nullsalden ausblenden',
  WalletSettingHideZeroInfo: 'Ihr Nullguthaben wird nicht in Ihrer Brieftasche angezeigt',
  WalletSettingDisplayFiat: 'Krypto in Fiat anzeigen',
  WalletSettingDisplayFiatInfo: 'Alle Wetten',
  WalletSettingNoteForApprox: 'Bitte beachten Sie, dass es sich hierbei um ungefähre Währungsangaben handelt.',
  WalletSettingSave: 'Speichern',
  WalletSettingNoWalletFound: 'Keine Brieftasche gefunden',
  jackpotWinnersNoWinnerAvailable: 'No winners Available',
  jackpotWinnersNew: 'New',
  jackpotWinnersHeader: 'Jackpot Winner',
  jackpotDetailsHeader: 'Jackpot detail',
  jackpotDetailsCampaignPeriod: 'Campaign Period',
  jackpotDetailsFrom: 'From',
  jackpotDetailsTo: 'To',
  jackpotDetailsBettingRule: 'Betting Rule',
  jackpotDetailsMinBetAmount: 'Min bet amount',
  jackpotDetailsMaxBetAmount: 'Max bet amount',
  jackpotDetailsBetPercentage: 'Bet percentage',
  jackpotDetailsWinningRuleHeader: 'Winning Rule',
  jackpotDetailsWinningRule: 'Placing bets with higher bet amount will increase chances of winning.',
  jackpotDetailsProfitRule: 'If bet amount is between minBetAmount and maxBetAmount then betPercentage% of bet amount will be added to jackpot amount.',
  profileTitle: 'Persönliche Daten',
  profileFName: 'Vorname',
  profileLname: 'Familienname, Nachname',
  profileUserName: 'Nutzername',
  profileDob: 'Geburtsdatum',
  profileEmail: 'E-Mail-Addresse',
  profilePhone: 'Telefonnummer',
  profileChange: 'Ändern',
  profileSave: 'Änderungen speichern',
  profilePassword: 'Passwort',
  profileSubmit: 'Einreichen',
  profile_UploadProfile: 'Profil hochladen',
  profileCurrentPassword: 'Aktuelles Passwort',
  profileNewPassword: 'Neues Kennwort',
  profileConfirmPassword: 'Bestätige das Passwort',
  profile_ScanQRCode: 'QR-Code scannen',
  profile_EnterSecretCode: 'Geben Sie den Geheimcode ein',
  profileErrorsFirstNameRequired: 'Vorname ist erforderlich.',
  profileErrorsFirstNamePattern: 'Bitte geben Sie einen gültigen Namen ein.',
  profileErrorsFirstNameMinLength: 'Mindestens 3 Zeichen erlaubt.',
  profileErrorsFirstNameMaxLength: 'Maximal 20 Zeichen erlaubt.',
  profileErrorsLastNameRequired: 'Nachname ist erforderlich.',
  profileErrorsLastNamePattern: 'Bitte geben Sie einen gültigen Namen ein.',
  profileErrorsLastNameMinLength: 'Mindestens 3 Zeichen erlaubt.',
  profileErrorsLastNameMaxLength: 'Maximal 20 Zeichen erlaubt.',
  profileErrorsChangePasswordOldRequired: 'Altes Passwort ist erforderlich.',
  profileErrorsChangePasswordOldPattern: 'Bitte gültiges Passwort eingeben.',
  profileErrorsChangePasswordOldMinLength: 'Mindestens 8 Zeichen erlaubt.',
  profileErrorsChangePasswordOldMaxLength: 'Maximal 16 Zeichen erlaubt.',
  profileErrorsChangePasswordNewRequired: 'Neues Passwort erforderlich.',
  profileErrorsChangePasswordNewPattern: 'Bitte gültiges Passwort eingeben.',
  profileErrorsChangePasswordNewMinLength: 'Mindestens 8 Zeichen erlaubt.',
  profileErrorsChangePasswordNewMaxLength: 'Maximal 16 Zeichen erlaubt.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Passwörter stimmen nicht überein.',
  profileErrorsChangePasswordNewPasswordMatched: 'Das neue Passwort darf nicht mit dem aktuellen Passwort identisch sein.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Passwort bestätigen ist erforderlich.',
  profileErrorsUserNameRequired: 'Benutzername wird benötigt.',
  profileErrorsUserNamePattern: 'Bitte geben Sie einen gültigen Namen ein.',
  profileErrorsUserNameMinLength: 'Mindestens 3 Zeichen erlaubt.',
  profileErrorsUserNameMaxLength: 'Maximal 20 Zeichen erlaubt.',
  profileErrorsDobRequired: 'Geburtsdatum ist erforderlich.',
  profileErrorsEmailRequired: 'E-Mail ist erforderlich.',
  profileErrorsEmailPattern: 'Bitte eine gültige E-Mail-Adresse eintragen.',
  profileErrorsEmailMinLength: 'Mindestens 3 Zeichen erlaubt.',
  profileErrorsEmailMaxLength: 'Maximal 20 Zeichen erlaubt.',
  profileMessagesProfileUpdate: 'Profil erfolgreich aktualisiert.',
  profileMessagesChangePassword: 'Passwort erfolgreich aktualisiert.',
  profileProfilePicProfilePicUpload: 'Profilbild hochladen',
  profileNoNewChanges: 'Profil konnte nicht aktualisiert werden! ',
  profileLoggedInWithSocial: 'Sie haben sich mit Social Login angemeldet.',
  profileSideNavigator_Sound: 'Klang',
  profileSideNavigatorMusic: 'Musik',
  profileSideNavigatorProfile: 'Mein Profil',
  profileSideNavigatorBetHistory: 'Wettverlauf',
  profileSideNavigatorTransactions: 'Transaktionen',
  profileSideNavigatorDeposit: 'Kaution',
  profileSideNavigator_Withdraw: 'Zurückziehen',
  profileSideNavigator_GameLimits: 'Spielgrenzen',
  profileSideNavigator_Referral: 'Verweisung',
  profileSideNavigatorChangePassword: 'Kennwort ändern',
  profileSideNavigator_TermsConditions: 'Bedingungen',
  profileSideNavigator_LogOut: 'Ausloggen',
  profileSideNavigator_ProvablyFair: 'Nachweislich fair',
  WalletInfoSectionNotAvailable: 'Nicht verfügbar',
  WalletInfoSectionRealBalance: 'Echtes Gleichgewicht',
  GameLimitsMinimumBet: 'Mindesteinsatz',
  GameLimitsMaximumBet: 'Maximaler Einsatz',
  GameLimitsMaxWinFor1Bet: 'Maximaler Gewinn für eine Wette',
  ProvablyFairSubTitle: 'Dieses Spiel verwendet Provably Fair-Technologie, um das Spielergebnis zu bestimmen. ',
  ProvablyFairMaximumBet: 'Maximaler Einsatz:',
  ProvablyFairMaxWinFor1Bet: 'Maximaler Gewinn für eine Wette',
  promotionsTitle: 'Werbeaktionen',
  promotionsNoLosingData: 'Tut mir leid, keine Verliererbonus-Aktionen verfügbar für ',
  promotionsNoDepositData: 'Entschuldigung, Bonusaktionen ohne Einzahlung sind verfügbar für ',
  promotionsCurrency: ' Währung.',
  promotionsViewBtn: 'Sicht',
  promotionsClaimSuccess: 'Sie haben erfolgreich Anspruch erhoben',
  promotionsAvailability: 'Verfügbarkeit: ',
  promotionsTabLosing: 'Bonus verlieren',
  promotionsTabDeposit: 'Einzahlungsbonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Mindest. ',
  promotionsDepositBonusTablePercentage: 'Prozentsatz',
  promotionsDepositBonusTableMaxBonus: 'Maximaler Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover-Multiplikator',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Maximaler Rollover pro Wette',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Zeit zum Erreichen des Rollover-Ziels',
  promotionsBonusCode: 'BONUS-CODE: ',
  promotionsLossesClaim: 'Verluste können geltend gemacht werden für (innerhalb des Aktionszeitraums): ',
  percentage: 'Prozentsatz',
  minLosingAmount: 'Mindest. ',
  promotionsBonusStatus: 'Bonusstatus: ',
  promotionsBonusAmount: 'Bonusbetrag: ',
  promotionsRolloverTarget: 'Rollover-Ziel: ',
  promotionsRolloverAchieved: 'Rollover erreicht: ',
  promotionsActivatebtn: 'AKTIVIEREN SIE',
  promotionsClaimBtn: 'BEANSPRUCHEN',
  promotionsCancelBtn: 'STORNIEREN',
  promotionsBackBtn: 'ZURÜCK',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Bedingungen',
  promotionsClaimDepositBonusTitle: 'Schritte zur Beanspruchung des Einzahlungsbonus',
  promotionsClaimDepositBonusFirst: 'Bonuscode aktivieren',
  promotionsClaimDepositBonusSecond: 'Betrag in die Brieftasche einzahlen',
  promotionsClaimDepositBonusThird: 'Erreichen Sie das Rollover-Ziel, um den Bonus zu beanspruchen',
  promotionsClaimLosingBonusTitle: 'Schritte zur Beanspruchung des Verlustbonus',
  promotionsClaimLosingBonusFirst: 'Bonuscode aktivieren',
  promotionsClaimLosingBonusSecond: 'Spielen Sie Ihre Lieblingsspiele',
  promotionsClaimLosingBonusThird: 'Klicken Sie auf „Verluste geltend machen“, um Verluste innerhalb des Kampagnenzeitraums geltend zu machen',
  promotionsPromotionsWentWrong: 'Etwas ist schief gelaufen!',
  transactionTitle: 'Transaktionen',
  transactionBetId: 'Wett-ID',
  transactionUser: 'Benutzer',
  transactionPayout: 'Auszahlung',
  transactionAmount: 'Menge',
  transactionTime: 'Datum',
  transactionProfit: 'Profitieren',
  transactionCashout: 'Auszahlen',
  transactionBet: 'Wette',
  transactionMultix: 'Multi.x',
  transactionWin: 'Gewinnen',
  transactionFairness: 'Gerechtigkeit',
  transactionReferFriend: 'Empfehlen Sie einen Freund',
  transactionTotal: 'Gesamtwetten',
  transactionWins: 'Gewinnt',
  transactionRefresh: 'Aktualisierung',
  transactionFilterTopMultipliers: 'Top-Multiplikatoren',
  transactionFilter_HugeWins: 'Riesige Gewinne',
  transactionFilterBiggestWins: 'Größte Gewinne',
  transactionFilter_Multipliers: 'Multiplikatoren',
  transactionRealBalance: 'Echtes Gleichgewicht',
  depositWithdrawTXDate: 'Datum',
  depositWithdrawTXAmount: 'Menge',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Art der Transaktion',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Keine Daten verfügbar.',
  depositTitle: 'Kaution',
  depositHistory: 'Geschichte',
  depositSubTitle: 'Der Benutzer kann keine Fiat-Währung einzahlen oder abheben. ',
  depositCopyNotSupported: 'Ihr Browser unterstützt kein automatisches Kopieren in die Zwischenablage.\n',
  depositCopied: 'Adresse kopiert.',
  depositAddressCreated: 'Adresse erfolgreich erstellt.',
  depositGenerateAddress: 'Adresse generieren',
  depositWarningText: 'Bitte zahlen Sie NICHT per Cross-Chain-Transfer ein',
  withdrawTitle: 'Zurückziehen',
  withdrawSubTitle: 'Der Benutzer kann keine Fiat-Währung einzahlen oder abheben. ',
  withdrawNoteDesc: 'Bitte stellen Sie sicher, dass Sie KEINE BEP2-, BEP20 (BSC)-Adresse als LTC-Abhebungsadressen-Geldbörsen eingeben',
  withdrawNote_Sublist: ' Bitte heben Sie NICHT als Cross-Chain-Transfer ab',
  withdrawHistory: 'Geschichte',
  withdrawWithdrawBtn: 'Anfrage zurückziehen',
  withdrawFees: 'Abhebegebühr {{fees}} {{token}}',
  withdrawWithdrawPending: 'Ihre Auszahlungsanforderung steht noch aus. ',
  withdrawEnterFields: 'Geben Sie den Auszahlungsbetrag und Ihre Brieftaschenadresse ein.',
  withdrawSelectWallet: 'Bitte wählen Sie die Brieftasche zum Abheben aus.',
  withdrawAmountFieldTitle: 'Betrag (Min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Dein {{wallet.currency.code}} Adresse',
  withdrawAddressFieldPlaceholder: 'Geben Sie Ihre Adresse ein',
  withdrawNoBalance: 'Nicht genug Gleichgewicht.',
  withdrawMinAmount: 'Bitte geben Sie einen Betrag ein, der größer oder gleich ist {{min}} .',
  fiatCurrencyInfo: 'Der Benutzer kann keine Fiat-Währung einzahlen oder abheben. ',
  paginationNextBtn: 'Nächste',
  paginationPrevBtn: 'Vorherige',
  wentWrong: 'Etwas ist schief gelaufen.',
  referralTitle: 'Verweisung',
  referralSubTitle: 'Verweisen Sie Ihre Freunde',
  referralEarn: ' Verdienen Sie jeweils ₹150',
  referralReferAndEarn: 'Empfehlen Sie und erhalten Sie einen Bonus für jede Empfehlung',
  referralReferPara: ' Wenn Sie diesen Code an einen beliebigen Benutzer weiterleiten',
  referral_ReferLink: 'Siehe Link',
  referralCopyReferralCode: 'Empfehlungscode kopieren',
  referral_TotalRewards: 'Gesamtbelohnung',
  TwoWayAuthentication: 'Zweiseitige Authentifizierung',
  LoginRequired: 'Login erforderlich!',
  BetPlacedSuccessfully: 'Wette erfolgreich platziert',
  BetAddedInQueueSuccessfully: 'Einsatz erfolgreich in die Warteschlange aufgenommen',
  BetRemovedFromQueueSuccessfully: 'Einsatz erfolgreich aus der Warteschlange entfernt',
  BetCancelledSuccessfully: 'Wette erfolgreich storniert',
  PlayerEscapedSuccessfully: 'Spieler erfolgreich entkommen',
  CopiedSuccessfull: 'Erfolgreich kopiert',
  NotEnoughBalance: 'Nicht genug Gleichgewicht',
  AutoBetStarted: 'Automatische Wette gestartet ',
  AutoBetFinished: 'Auto-Wette beendet ',
  IdCopied: 'Id kopiert',
  bannerTitle: 'Amazing Casino',
  bannerSubTitle: 'JUST A CLICK AWAY',
  captcha: 'Diese Website ist durch reCAPTCHA geschützt und es gelten die Datenschutzbestimmungen und Nutzungsbedingungen von Google',
  signUpAndGet : 'Melden Sie sich an und erhalten Sie',
  rakeDisc : '50% RAKE BACK',
  bannerFooter: 'Durch den Zugriff auf diese Website bestätige ich, dass ich mindestens 18 Jahre alt bin und die Nutzungsbedingungen gelesen habe und ihnen zustimme.',
  DeucesOriginal: 'Deuces Original',
  Promotion: 'Förderung',
  orLoginWith: 'oder loggen Sie sich mit ein',
  RegisterAnAccount: 'einen Account registrieren',
  MakeADepositAndPlay: 'Machen Sie eine Einzahlung und spielen Sie',
  ReceiveRakeBack: 'ERHALTEN SIE 50 % RAKEBACK',

  // Affiliate Translation

  affiliate: 'Affiliate',
  affiliateTitle: 'Partnerprogramm',
  affiliateDescription: 'Empfehlen Sie unsere Marke und verdienen Sie Geld durch die Teilnahme am Deuces-Partnerprogramm! ',
  affiliateButtonText: 'Werden Sie Partner',
  refferelLink: 'Empfehlungslink',
  affiliateLinkCopy: 'Link erfolgreich kopiert',
  startDate: 'Startdatum',
  endDate: 'Endtermin',
  affiliateEligibleDescription: 'Sie sind nicht berechtigt, Partner zu werden. Bitte absolvieren Sie KYC bis Level 2, um sich für Partner zu qualifizieren',
  moveOnKycPage: 'um auf der KYC-Seite zu wechseln.',
  docDownload: 'Klicken Sie hier, um das hochgeladene Dokument herunterzuladen',
  documentUploadedSuccess: 'Dokument erfolgreich hochgeladen',
  uploadFile: 'Datei hochladen',
  completeKycLevel: 'Bitte beenden Sie das Level  {{kycLevel}} Zuerst die KYC-Überprüfung',

  // New
  myBonuses: 'Meine Boni',
  bonus: 'Bonus',
  claim: 'Anspruch',
  claimedBonuses: 'beanspruchte Boni',
  bonusAmount: 'Bonusbetrag',
  wageredAmount: 'Eingesetzter Betrag',
  amountToWager: 'Zu wettender Betrag',
  claimedDate: 'Anspruchsdatum',
  expireAt: 'Verfällt am',
  currency: 'Währung',
  freeSpinsQty: 'Freispiele',
  validity: 'Gültigkeit',
  description: 'Beschreibung',
  termsAndConditions: 'Bedingungen',
  claimed: 'Beansprucht',
  currencies: 'Währungen',
  participationGames: 'Teilnehmende Spiele',
  userGroups: 'Benutzergruppen',
  maxBonusClaimed: 'Maximal beanspruchter Bonus',
  minimumDepositAmount: 'Mindesteinzahlungsbetrag',
  percentOrAmount: 'Prozent / Betrag',
  amountType: 'Art des Betrags',
  minimumBet: 'Mindesteinsatz',
  turnoverCoefficient: 'Umsatzkoeffizient',
  claimedCount: 'Anzahl beansprucht',
  note: 'Hinweis',
  cancelable: 'Benutzer kann diesen Bonus jederzeit stornieren',
  nonCancelable: 'Einmal beansprucht, kann der Benutzer diesen Bonus nicht stornieren',
  to: 'bis',
  wageringMultiplier: 'Wettmultiplikator',
  bonusType: 'Art der Bonusnutzung',

  newReleases: 'Neue Versionen',
  deucesOriginals: 'Deuces Originals',
  providers: 'Anbieter',
}
