import CryptoJS from 'crypto-js';

/* ==========================================================================
  Auth Token
========================================================================== */

export const encrypt = (text) => {
  try {
    const iv = CryptoJS.lib.WordArray.random(process.env.REACT_APP_IV_LENGTH); // Generate random IV
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Return IV and encrypted data as a single string
    return iv.toString(CryptoJS.enc.Hex) + ':' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};


// Function to convert hex string to ArrayBuffer
const hexToArrayBuffer = (hex) => {
  const bytes = new Uint8Array(hex.length / 2);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(hex.substr(i * 2, 2), 16);
  }
  return bytes.buffer;
};

// Function to decrypt data using Web Crypto API
export const decryptData = async (encryptedText) => {
  try {
    // Convert key and encrypted data from hex to ArrayBuffer
    const keyBuffer = hexToArrayBuffer(process.env.REACT_APP_ENCRYPTION_KEY);
    const [ivHex, encryptedHex] = encryptedText.split(':');
    const iv = hexToArrayBuffer(ivHex);
    const encryptedData = hexToArrayBuffer(encryptedHex);

    // Import the key
    const key = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-CBC' },
      false,
      ['decrypt']
    );

    // Decrypt the data
    const decryptedBuffer = await crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: new Uint8Array(iv)
      },
      key,
      new Uint8Array(encryptedData)
    );

    // Convert decrypted buffer to text
    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);
  } catch (error) {
    console.error('Decryption error:', error);
    throw error;
  }
};
