import React from 'react'
import TopSportsBar from './TopSportsBar/index'
import CountryViewBar from './CountryViewBar/index'
import FeaturedMatches from './FeaturedMatches/index'
import MatchesBox from './MatchesBox/index'
import MatchesList from './MatchesList/index'
import { SportsBookContainer } from '../style'

const SportBookLobby = () => {
  return (
    <SportsBookContainer>
      <TopSportsBar />
      <CountryViewBar />
      <FeaturedMatches />
      <MatchesBox />
      <MatchesList />
    </SportsBookContainer>
  )
}

export default SportBookLobby
