import { createAsyncThunk } from '@reduxjs/toolkit'
import { signIn } from 'helpers/cookie.helpers'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { getMetaUser, loginWithOtpService, verifyMetaSignature } from 'network/services/auth.service'
import {
  becomeAffiliateService,
  currencyCodeUpdateRequestService,
  deactivate2faAuthenticateTokenService,
  depositRequest,
  firstTime2faAuthenticateToken,
  generateQRCodeFor2fa,
  generateReferralCodeService,
  generateWalletAddressService,
  getActiveJoiningBonusService,
  getActiveReferralBonusService,
  getAffiliateDataService,
  getAffiliateManagementDataService,
  getDepositTransactionsService,
  getDocumentsInfoService,
  getDocumentsLabelService,
  getKycDocumentLabelService,
  getKycDocumentsService,
  getLeaderboardDetailsService,
  getLeaderboardWinnerDetailsService,
  getLoyaltyDetailsService,
  getNotificationUser,
  getPlacedBets,
  getReferredUsersDataService,
  getUserInfoService,
  getUsersCasinoTransactionsService,
  getUsersSportsbookTransactionsService,
  getUsersTotalBetsService,
  getUsersTransactionsService,
  getUserWalletCurrencyLimitService,
  getWithdrawTransactionsService,
  ghostModeUpdateRequestService,
  updateDocVerificationService,
  updatePhoneNumber,
  updateUserInfoService,
  uploadKycDocumentLabelService,
  uploadProfilePhotoService,
  withdrawAmountRequest
} from 'network/services/users.service'
import { setShowPopup } from 'redux-thunk/redux/slices/alert.slice'
import { setShowLoginPopup, setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setAffiliateData, setAffiliateManagementData, setKycDocumentLabel, setKycDocuments, setLoyaltyDetails, setProfileUploadingLoader, setQrcodeUrl, setTopBetTransactions, setUsersTransactions } from 'redux-thunk/redux/slices/user.slice'
import { setWalletCurrencyLimit } from 'redux-thunk/redux/slices/wallet.slice'
import { getKycLevelRequestService } from '../../network/services/users.service'
/**
 * Fetch User info who is logged in
 */


export const fetchUserNotification = createAsyncThunk(
  'user/notification-data',
  async ({ params }, thunkApi) => {
    try {
      const res = await getNotificationUser(params)
      return res.notificationData;
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)


export const fetchUserInformation = createAsyncThunk(
  'user/user-detail',
  async (_, thunkApi) => {
    try {
      const res = await getUserInfoService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getMetaUserRecord = createAsyncThunk(
  'user/get-MetaUserRecord', async ({ payload }, thunkApi) => {
    try {
      const res = await getMetaUser(payload)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const verifyMetaAccountSignature = createAsyncThunk(
  'user/verify-Signature',
  async (_, thunkApi) => {
    try {
      const res = await verifyMetaSignature()
      signIn({
        token: res.accessToken
      })

      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const loginWithOtp = createAsyncThunk(
  'user/verify-otp',
  async ({ param, showLoginOtpHandle }, thunkApi) => {
    try {
      const res = await loginWithOtpService(param)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(showLoginOtpHandle(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

/**
 * Update User info who is logged in
 */
export const updateUserInformation = createAsyncThunk(
  'user/update-user-details',
  async ({ data, onEditProfileError }, thunkApi) => {
    try {
      const res = await updateUserInfoService(data)
      thunkApi.dispatch(fetchUserInformation())
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      onEditProfileError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const updateUserPrivateData = createAsyncThunk(
  'user/update-user-details',
  async ({ params }, thunkApi) => {
    try {
      const res = await updateUserInfoService(params)
      thunkApi.dispatch(fetchUserInformation())

      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const updateProfilePic = createAsyncThunk(
  'user/update-profile-pic',
  async ({ data, onProfilePicError }, thunkApi) => {
    try {
      thunkApi.dispatch(setProfileUploadingLoader(true))
      const res = await uploadProfilePhotoService(data)
      // thunkApi.dispatch(fetchUserInformation())
      // onSuccess()
      return res
    } catch (error) {
      onProfilePicError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const updatePhone = createAsyncThunk(
  'user/update-phone',
  async (params, thunkApi) => {
    try {
      const res = await updatePhoneNumber(params)
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const firstTime2faAuthentication = createAsyncThunk(
  'user/generate-secret-code',
  async ({ token, handleClose }, thunkApi) => {
    try {
      const res = await firstTime2faAuthenticateToken(token)
      thunkApi.dispatch(fetchUserInformation())
      handleClose(false)
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const deactivateFirstTime2faAuthentication = createAsyncThunk(
  'user/2FA-Authentication-deactivated',
  async (thunkApi) => {
    try {
      const res = await deactivate2faAuthenticateTokenService()
      thunkApi.dispatch(fetchUserInformation())
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const generateQRCode = createAsyncThunk(
  'user/generate-secret-code',
  async (_, thunkApi) => {
    try {
      const res = await generateQRCodeFor2fa()
      thunkApi.dispatch(setQrcodeUrl(res?.dataURL))
      // onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getWithdrawRequest = createAsyncThunk(
  'user/update-profile',
  async ({ params, setWithdrawRequestData }, thunkApi) => {
    try {
      const res = await withdrawRequest(params)
      // onSuccess()
      // setWithdrawRequestData(res)
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const generateWalletAddress = createAsyncThunk(
  'user/generateWalletAddress',
  async ({ params }, thunkApi) => {
    try {
      const res = await generateWalletAddressService(params)
      thunkApi.dispatch(fetchUserInformation())
      // setWalletInfo(res)
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const generateReferralCode = createAsyncThunk(
  'user/generateReferralCode',
  async (thunkApi) => {
    try {
      const res = await generateReferralCodeService()
      thunkApi.dispatch(fetchUserInformation())
      // setWalletInfo(res)
      // onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const getActiveJoiningBonus = createAsyncThunk('bonus/get-active-joining-bonus', async (_, thunkApi) => {
  try {
    const res = await getActiveJoiningBonusService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
  }
})

export const getActiveReferralBonus = createAsyncThunk('bonus/get-active-referral-bonus', async (_, thunkApi) => {
  try {
    const res = await getActiveReferralBonusService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
  }
})
export const getLoyaltyDetails = createAsyncThunk('user/loyalty', async (_, thunkApi) => {
  try {
    const res = await getLoyaltyDetailsService()
    thunkApi.dispatch(setLoyaltyDetails(res))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
  }
})

export const getUserPlacedBets = createAsyncThunk(
  'user/withdraw',
  async (params, thunkApi) => {
    let baseUrl
    const { offset, transactionType, limit } = params
    if (transactionType === 'wallet') {
      baseUrl = 'user/wallet'
    } else if (transactionType === 'cashout') {
      baseUrl = 'user/cashout'
    } else if (transactionType === 'deposit') {
      baseUrl = 'user/deposit'
    } else if (transactionType === 'jackpot') {
      baseUrl = 'user/jackpot'
    } else if (transactionType === 'mybets') {
      params = {
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/my-bets'
    } else if (transactionType === 'topbets') {
      params = {
        type: params.type,
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/top-bets'
    } else if (transactionType === 'allbets') {
      params = {
        type: params.type,
        limit: limit,
        offset: offset
      }
      baseUrl = 'crash-game/all-bets'
    }
    try {
      const res = await getPlacedBets(params, baseUrl)
      if (transactionType === 'topbets') {
        thunkApi.dispatch(setTopBetTransactions(res))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const depositAmountRequest = createAsyncThunk(
  'wallet/deposit-amount',
  async ({ values, onDepositAddressError }, thunkApi) => {
    try {
      const res = await depositRequest(values)
      return res
    } catch (error) {
      onDepositAddressError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const currencyCodeUpdateRequest = createAsyncThunk(
  'wallet/currency-code',
  async ({ values }, thunkApi) => {
    try {
      const res = await currencyCodeUpdateRequestService(values)
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const withdrawRequest = createAsyncThunk(
  'wallet/deposit-amount',
  async ({ params, onWithDrawError, onWithDrawSuccess }, thunkApi) => {
    try {
      const res = await withdrawAmountRequest(params)
      onWithDrawSuccess(res)
      return res
    } catch (error) {
      onWithDrawError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getUserDepositTransactions = createAsyncThunk(
  'user/deposit',
  async (params, thunkApi) => {
    try {
      const res = await getDepositTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const getUserWithdrawDepositTransactions = createAsyncThunk(
  'user/withdraw',
  async (params, thunkApi) => {
    try {
      const res = await getWithdrawTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const getUserCasinoTransactions = createAsyncThunk(
  'user/casino-bet-history',
  async (params, thunkApi) => {
    try {
      const res = await getUsersCasinoTransactionsService(params)
      return res?.casinoTransaction
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getUserTransactions = createAsyncThunk(
  'user/casino-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersTransactionsService(params)
      thunkApi.dispatch(setUsersTransactions(res?.transactions))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getLeaderboardDetails = createAsyncThunk(
  'user/game-leaderboards',
  async (params, thunkApi) => {
    try {
      const res = await getLeaderboardDetailsService(params)
      if (res?.leaderBoardData?.count > 41) {
        res.leaderBoardData.count = 40 // Update count to 40 if it's greater than 41
      }
      return res?.leaderBoardData
    } catch (error) {
      return thunkApi.rejectWithValue(error?.[0]?.description)
    }
  }
)

export const getLeaderboardWinnerDetails = createAsyncThunk(
  'user/game-winner-leaderboards',
  async (params, thunkApi) => {
    try {
      const res = await getLeaderboardWinnerDetailsService(params)
      if (res?.leaderBoardData?.count > 41) {
        res.leaderBoardData.count = 40 // Update count to 40 if it's greater than 41
      }
      return res?.leaderBoardData
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const getUserSportsbookTransactions = createAsyncThunk(
  'user/sportsbook-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUsersSportsbookTransactionsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const getUsersTotalBets = createAsyncThunk(
  'user/total-bet-amounts',
  async (params, thunkApi) => {
    try {
      const res = await getUsersTotalBetsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  })

export const getDocumentsLabelRequest = createAsyncThunk('account/document-label', async (_, thunkApi) => {
  try {
    const res = await getDocumentsLabelService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getDocumentsInfoRequest = createAsyncThunk('account/document-info', async (_, thunkApi) => {
  try {
    const res = await getDocumentsInfoService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const updateDocVerificationRequest = createAsyncThunk(
  'user/update-user-details',
  async (params, thunkApi) => {
    try {
      const res = await updateDocVerificationService(params.data)
      // thunkApi.dispatch(fetchUserInformation())

      params.onSuccess && params.onSuccess()
      return res
    } catch (error) {
      // onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserWalletCurrencyLimit = createAsyncThunk(
  'user/casino-transactions',
  async (params, thunkApi) => {
    try {
      const res = await getUserWalletCurrencyLimitService(params)
      thunkApi.dispatch(setWalletCurrencyLimit(res?.currencyLimits))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getAffiliateData = createAsyncThunk(
  'user/affiliate-data',
  async ({ loader, setLoading }, thunkApi) => {
    try {
      const res = await getAffiliateDataService({ data: {}, loader })
      thunkApi.dispatch(setAffiliateData(res?.affiliateDetails))
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      return res
    } catch (error) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const becomeAffiliate = createAsyncThunk(
  'user/affiliate',
  async ({ loader }, thunkApi) => {
    try {
      const res = await becomeAffiliateService({ data: {}, loader })
      thunkApi.dispatch(getAffiliateData({ loader, setLoading: () => { } }))
      openSuccessToaster({ message: res?.message })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getAffiliateManagementData = createAsyncThunk(
  'user/affiliate/management',
  async (params, thunkApi) => {
    try {
      const res = await getAffiliateManagementDataService(params)
      thunkApi.dispatch(setAffiliateManagementData(res?.affiliateDetails))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getReferredUsersData = createAsyncThunk(
  'user/referral/management',
  async (params, thunkApi) => {
    try {
      const res = await getReferredUsersDataService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
export const getKycDocumentLabel = createAsyncThunk(
  'user/kyc/document-label',
  async (params, thunkApi) => {
    try {
      const res = await getKycDocumentLabelService(params)
      thunkApi.dispatch(setKycDocumentLabel(res?.documentLabel))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getKycDocuments = createAsyncThunk(
  'user/kyc/documents',
  async (params, thunkApi) => {
    try {
      const res = await getKycDocumentsService(params)
      thunkApi.dispatch(setKycDocuments(res?.userDocument))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const uploadKycDocuments = createAsyncThunk(
  'user/kyc/documents-upload',
  async ({ data, setButtonLoading, onKYCDocsUploadError, onKYCDocsUploadSuccess, currKycIndex }, thunkApi) => {
    try {
      const res = await uploadKycDocumentLabelService(data)
      setButtonLoading(false)
      thunkApi.dispatch(getKycDocuments())
      onKYCDocsUploadSuccess(res, currKycIndex)
      return res
    } catch (error) {
      setButtonLoading(false)
      onKYCDocsUploadError(error, currKycIndex)
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const ghostModeUpdateRequest = createAsyncThunk(
  'user/privacy/ghost-mode',
  async ({ values, leaderBoardDetails }, thunkApi) => {
    try {
      const res = await ghostModeUpdateRequestService(values)

      // thunkApi.dispatch(fetchUserInformation())
      // openSuccessToaster({ message: res?.message })
      thunkApi.dispatch(setShowPopup({ title: 'Success', content: res.message, type: 'success' }));

      // if(leaderBoardDetails !== undefined ) {
      //   const {actionType,winnersPage,DEFAULT_PAGINATION_LIMIT,searchVal} = leaderBoardDetails
      //   thunkApi.dispatch(getLeaderboardWinnerDetails({
      //     actionType: actionType,
      //     pageNo: winnersPage,
      //     limit: DEFAULT_PAGINATION_LIMIT,
      //     search: searchVal || ''
      //   }))
      // }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getKycLevel = createAsyncThunk(
  'user/kyc/level',
  async (_, thunkApi) => {
    try {
      const { result } = await getKycLevelRequestService()
      return result
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
