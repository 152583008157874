import { Box, styled } from '@mui/material'

import { makeStyles } from '@mui/styles'

export const RegisterModalStyle = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  }
})

export const DatePickerStyle = styled(Box)(({ theme }) => {
  return {
    '& .react-datepicker__year-dropdown': {
      background: '#000',

      '& .react-datepicker__year-option': {
        '&:hover': {
          background: '#57535E',
        }
      }
    },

    '& .react-datepicker': {
      background: theme.palette.primary.dark,
      border: 'none',
      width: '100%',

      '&  .react-datepicker__triangle::after,  .react-datepicker__triangle::before':
        {
          display: 'none'
        },
      '& .react-datepicker__day': {
        color: theme.palette.primary.contrastText,
        '&:hover': {
          background: theme.palette.button.activeBtn
        }
      },
      '& .react-datepicker__header ': {
        background: theme.palette.primary.dark,
        border: 'none',
        color: theme.palette.primary.contrastText
      },
      '& .react-datepicker__current-month, .react-datepicker__day-name': {
        color: theme.palette.primary.contrastText
      },
      '& .react-datepicker__day--selected': {
        background: theme.palette.button.activeBtn
      }
    },

    '& .custom-datepicker': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      '& .css-1h5utz0': {
        width: '100%'
      },
      '& .MuiTextField-root': {
        minWidth: '100%'
      },

      '.MuiFormLabel-root': {
        color: theme.palette.primary.contrastText
      },

      '.MuiInputBase-root': {
        background: '#0d1b32',
        height: theme.spacing(7),
        borderRadius: theme.spacing(1),
        border: 'none',
        padding: theme.spacing(0, 2),
        width: '100%',
        cursor: 'pointer',
        color: theme.palette.primary.contrastText,
        '&:focus-visible': {
          outline: 'none'
        },

        '.MuiIconButton-root': {
          color: theme.palette.primary.contrastText,
          padding: '25px'
        }
      },

      '.MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },

      '.react-datepicker-wrapper': {
        width: '100%',
        '& .react-datepicker__input-container': {
          width: '100%',

          [theme.breakpoints.down('xm')]: {
            width: '100%'
          },

          input: {
            background: theme.palette.primary.inputBg,
            height: theme.spacing(7),
            borderRadius: theme.spacing(1),
            border: 'none',
            padding: theme.spacing(0, 2),
            width: '100%',
            cursor: 'pointer',
            color: theme.palette.primary.contrastText,
            '&:focus-visible': {
              outline: 'none'
            }
          }
        }
      },

      '& svg': {
        position: 'absolute',
        right: '1rem',
      }
    }
  }
})

export const useStyles = makeStyles((theme) => ({

  inputLabel: {
    width: '100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1),
    display: 'flex',
    '& .required-indicator': {
      color: 'red'
    }
  },
  countryWrap: {
    '& .css-i30576-control': {
      background: '#0d1b32',
      border: 'none',
      '&:focus-visible': {
        background: '#0d1b32',
        outline: 'none',
        border: 'none'
      }
    },
    '& .css-1e7um3i-control': {
      background: '#0d1b32',
      border: 'none',
      boxShadow: 'none'
    }
  }
}))
