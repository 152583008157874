import React from 'react'

import { useDispatch } from 'react-redux'
import { setShowChat } from 'redux-thunk/redux/slices/settings.slice'
// import { v4 as uuidv4 } from 'uuid'
import { ChatBoxWrapper } from './style'
import { Box, Button, Typography } from '@mui/material'
import { CrossIcon } from 'assets/icons/index'
const Chat = () => {
  const dispatch = useDispatch()

  const handleCloseChat = () => {
    dispatch(setShowChat(false))
    // dispatch(setChatCollapse(true))
  }

  return (
    <ChatBoxWrapper>
      <Box className='chat-sidebar'>
        <Box className='chat-close-icon'>
          <Button onClick={handleCloseChat}>
            <CrossIcon />
          </Button>
        </Box>

        <Box>
          {/* <Box className='time-section'>
            <Typography variant='p' className='game-title-text'>
              Wednesday
            </Typography>
            <Typography variant='p' className='game-title-text'>
              6:39 PM
            </Typography>
          </Box> */}

          <Box className='chat-box'>
            {/* <Typography className='chat-item'>
              <Typography variant='span' className='chat-by-user'>Loremipsum :</Typography>
               Lorem ipsum dolor sit amet <Typography variant='span' className='chat-tag-user'>@Loremipsum</Typography>,
              consectetur adipiscing elit.
            </Typography> */}

            {/* <Typography className='chat-item'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>

            <Typography className='chat-item'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography> */}
          </Box>

        </Box>

        <Typography variant='h6' sx={{ textAlign: 'center', color: '#fff', marginTop: '10rem' }}>
          Stay tuned.
          <br />This is coming soon.
        </Typography>
      </Box>
    </ChatBoxWrapper>
  )
}

export default React.memo(Chat)
