import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { ChecronDown } from "assets/icons/index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setShowLoginPopup } from "redux-thunk/redux/slices/gameSetting.slice";
import UserImg from "../../../../../assets/images/user-default.webp";
import { CustomMainMenu } from "../../../CustomMenu/CustomMenu";
import { UserNavWrapper } from "../../style";

import { ChatIcon } from "assets/ui-kit-icons/svg/index";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import { ROUTE_PATHS } from "constants/index";
import { getAuthToken } from "helpers/cookie.helpers";
import { setAuthTabPanelValue } from "redux-thunk/redux/slices/auth.slice";
import { setUserBonus } from "redux-thunk/redux/slices/bonus.slice";
import { setShowChat } from "redux-thunk/redux/slices/settings.slice";
import { setShowWalletPopup } from "redux-thunk/redux/slices/wallet.slice";
import { userLogout } from "redux-thunk/thunk/auth.thunk";
import MyBonusesSVG from "../../../../../assets/userSpecific/bonuses.svg";
import LogoutSVG from "../../../../../assets/userSpecific/logout.svg";
import MyAccountSVG from "../../../../../assets/userSpecific/my-account.svg";
import MyWalletSVG from "../../../../../assets/userSpecific/my-wallet.svg";
import TrannsactionSVG from "../../../../../assets/userSpecific/transaction.svg";
import SearchGame from "../SearchGame/index";

export default function UserNav() {
  const theme = useTheme();
  const token = !!getAuthToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useSelector((state) => state.user);

  // const FB = window.FB
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLogOutClick, setIsLogOutClick] = useState(false);
  const { showChat } = useSelector((state) => state?.settings);
  const { showNotif } = useSelector((state) => state?.settings);
  const userData = useSelector((state) => state?.user?.user?.getUser);
  const { logoutLoader } = useSelector((state) => state?.user);

  const userSpecificDetails = {
    myAccount: "myAccount",
    myWallet: "myWallet",
    myBonuses: "myBonuses",
    // responsibleGambling: 'responsibleGambling',
    userLogout: "userLogout",
    transactions: "transactions",
  };

  const settings = [
    {
      name: userSpecificDetails?.myAccount,
      title: t("myAccount"),
      srcURL: MyAccountSVG,
    },
    {
      name: userSpecificDetails?.myWallet,
      title: t("wallet"),
      srcURL: MyWalletSVG,
    },
    {
      name: userSpecificDetails?.myBonuses,
      title: t("myBonuses"),
      srcURL: MyBonusesSVG,
    },
    // {
    //   name: userSpecificDetails?.responsibleGambling,
    //   title: 'Responsible Gambling',
    //   srcURL: RespsibleGamblingSVG
    // },
    {
      name: userSpecificDetails?.transactions,
      title: t("accountMenuTransactions"),
      srcURL: TrannsactionSVG,
    },
    {
      name: userSpecificDetails?.userLogout,
      title: t("accountMenuLogout"),
      srcURL: LogoutSVG,
      isDisabled: logoutLoader && isLogOutClick,
    },
  ];

  const handleOpenUserMenu = (event) => {
    if (userData?.username) {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (!token) {
      handleCloseUserMenu();
    }

    if (token) {
      localStorage.removeItem("affiliateCode");
      // history.push(ROUTE_PATHS?.CASINO)
    }
  }, [token]);

  const handleMenuClick = (name) => {
    if (name === userSpecificDetails?.myAccount) {
      handleCloseUserMenu();
      history.push(ROUTE_PATHS?.MY_ACCOUNT);
    } else if (name === userSpecificDetails?.myWallet) {
      dispatch(setShowWalletPopup(true));
    } else if (name === userSpecificDetails?.responsibleGambling) {
      handleCloseUserMenu();
      history.push(ROUTE_PATHS?.RESPONSIBLE_GAMBLING);
    } else if (name === userSpecificDetails?.userLogout) {
      setIsLogOutClick(true);
      dispatch(userLogout({ history }));
      localStorage.clear();
      dispatch(setUserBonus([]));
      // FB.logout(function (response) {
      //   console.log(response)
      // })
    } else if (name === userSpecificDetails?.transactions) {
      handleCloseUserMenu();
      history.push(ROUTE_PATHS.TRANSACTION);
    } else if (name === userSpecificDetails?.myBonuses) {
      handleCloseUserMenu();
      history.push(ROUTE_PATHS.MY_BONUSES);
    }
  };
  const loginHandleOpen = () => {
    dispatch(setShowLoginPopup(true));
    dispatch(setAuthTabPanelValue("1"));
  };
  const registerHandleOpen = () => {
    dispatch(setShowLoginPopup(true));
    dispatch(setAuthTabPanelValue("2"));
  };

  return (
    <UserNavWrapper theme={theme} className="header-user-nav">
      {/* <div className="show-mobile">
        <BellIcon className='mx-2' />
        <BellIcon className='mx-2' />
        <BellIcon className='mx-2'
          onClick={() => handleMenuClick('myWallet')}
        />
      </div> */}
      <Box className="user-nav-menu">
        <SearchGame />
        {/* <LanguageSwitcher /> */}
        {token ? (
          <>
            <Grid
              className="profile-btn chat-btn"
              onClick={() => dispatch(setShowChat(!showChat))}
            >
              <ChatIcon />
            </Grid>
            <div className="line"></div>
            <Box className="profile-btn-section " onClick={handleOpenUserMenu}>
              <img
                src={
                  user?.getUser?.profileImage
                    ? `${process.env.REACT_APP_IMG_URL}${user?.getUser?.profileImage}`
                    : UserImg
                }
                className="user-img"
                alt="userImg"
              />
              <Typography className="user-name-text">
                {t(userData?.username) || (
                  <CircularProgress color="success" size={18} sx={{ ml: 1 }} />
                )}{" "}
                <ChecronDown />
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <div className="line"></div>
            <CustomMainButton
              className="profile-btn login-btn"
              onClick={loginHandleOpen}
            >
              {t("login")}
            </CustomMainButton>{" "}
            &nbsp;
            <CustomMainButton
              className="profile-btn register-btn"
              onClick={registerHandleOpen}
            >
              {t("signinSignup")}
            </CustomMainButton>
          </>
        )}
      </Box>

      <CustomMainMenu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting?.name}
            onClick={() => handleMenuClick(setting?.name)}
            disabled={setting?.isDisabled}
          >
            <img src={setting?.srcURL} className="icon-img" alt="Icon" />
            <Typography textAlign="center">{setting?.title}</Typography> &nbsp;
            {/* {logoutLoader &&
              setting?.name === userSpecificDetails?.userLogout &&
              isLogOutClick && (
                <CircularProgress color='#3570D2' size={18} sx={{ ml: 1 }} />
              )} */}
          </MenuItem>
        ))}
      </CustomMainMenu>
    </UserNavWrapper>
  );
}
