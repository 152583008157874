import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  generalDataLoading: false,
  siteData: null,
  settingData: null,
}

const {
  actions: {
    getSiteDataSuccess,
    getSettingDataSuccess
  },
  reducer
} = createSlice({
  name: 'general',
  initialState,
  reducers: {
    getSiteDataSuccess: (state, { payload }) => {
      return ({
        ...state,
        siteData: payload,
      })
    },
    getSettingDataSuccess: (state, { payload }) => {
      return ({
        ...state,
        settingData: payload,
      })
    },
  },
})

export default reducer
export {
  getSiteDataSuccess,
  getSettingDataSuccess
}
