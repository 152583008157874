import React, { useState } from "react";
import { Box, TextField } from '@mui/material'
import { SearchNewIcon } from 'assets/icons/index'
import { useDebounce } from 'react-use'
import { setSearchGame } from 'redux-thunk/redux/slices/lobby.slice'
import { useDispatch} from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SearchGameWrapper } from "./style";

export default function SearchGame () {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState('')

  const handleSearchGame = (e) => {
    setSearchVal(e?.target?.value)
  }

  useDebounce(
    () => {
      dispatch(setSearchGame(searchVal))
    },
    1000,
    [searchVal]
  )

  return (
    <>
    <SearchGameWrapper>
    <Box className='search-wrap'>
        <Box className='input-holder'>
        <TextField
          onChange={handleSearchGame}
          placeholder={t('searchGames')}
          autoComplete='off'
          name='searchGame'
          type='search'
          InputProps={{
            style: {
              color: 'white', // Color of the text
            },
            inputProps: {
              style: {
                color: 'white', // Color of the placeholder text
                '::placeholder': {
                  color: 'white', // Fallback for older browsers
                  opacity: 1 // Ensuring the color is fully applied
                }
              }
            }
          }}
          />
        <Box className='icon-box'>
            <SearchNewIcon />
        </Box>
        </Box>
      </Box>
    </SearchGameWrapper>
    </>
  )
}
