import { Box, styled } from '@mui/material'
import checkIcon from '../../assets/icons/check.svg'

export const ResetPasswordModalStyle = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: theme.spacing(78.75),
    width: '100%',
    backgroundColor: '#0b0116',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4, 3),
    maxHeight: '100vh',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .login-modal': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .modal-heading': {
        textAlign: 'center',
        fontSize: theme.spacing(3.25),
        color: theme.palette.primary.contrastText
      },

      '& .login-tab': {
        marginTop: theme.spacing(3.5),
        '& .login-inner-tab': {
          'div[role=tablist]': {
            padding: theme.spacing(1),
            background: theme.palette.primary.inputBg,
            borderRadius: theme.spacing(0.625),
            '& button': {
              width: '50%',
              color: theme.palette.primary.contrastTextDark,
              textTransform: 'capitalize',
              fontSize: theme.spacing(2.25)
            },
            '& .item-one': {
              width: '50%'
            },
            '& .item-two': {
              width: '50%'
            },
            '& .Mui-selected': {
              color: theme.palette.primary.contrastText,
              background: theme.palette.button.mainGradient,
              borderRadius: theme.spacing(0.625)
            }
          }

        },

        '& .tab-panel': {
          padding: theme.spacing(0)
        },

        '& .input-label': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(2),
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(1)
        },

        '& .login-form-group': {

          color: theme.palette.primary.contrastTextDark,

          '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText
          }
        },

        '& .custom-checkbox-group': {
          paddingTop: theme.spacing(2.5),
          display: 'flex',
          alignItems: 'flex-start',
          '& .primary-dark-text': {
            color: theme.palette.primary.contrastTextDark,
            fontSize: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0)
          },

          '& .custom-checkbox': {

            '& .custom-input': {
              appearance: 'none',
              padding: theme.spacing(1.25),
              border: `1px solid ${theme.palette.primary.contrastTextDark}`,
              borderRadius: theme.spacing(0.5),
              cursor: 'pointer',

              ':checked': {
                backgroundImage: `url(${checkIcon})`
              }
            }

          }

        },

        // 'input' : {
        //   color: '#ffffff'
        //   '&::placeholder':{
        //     color: '#ffffff'
        //   }
        // },
        '& .social-media-box': {
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
          '& .social-media-icon': {
            background: theme.palette.primary.iconBg,
            textAlign: 'center',
            padding: theme.spacing(1.6, 6),
            borderRadius: theme.spacing(0.625)
          }
        },
        '& .forgot-text': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(3.5),

          a: {
            color: theme.palette.primary.contrastText,
            fontSize: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium
          }
        }

      },

      '.or-divider': {
        textAlign: 'center',
        padding: theme.spacing(3.5, 0, 2, 0),

        '& .custom-divider': {
          color: theme.palette.primary.contrastTextDark,
          padding: theme.spacing(0, 1),
          position: 'relative',
          '&::before': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            left: '100%'
          },

          '&::after': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            right: '100%'
          }
        }

      },

      '& .primary-dark-text': {
        color: theme.palette.primary.contrastTextDark,
        paddingRight: theme.spacing(1)
      }

    },

    '.text-danger': {
      color: theme.palette.danger.main,
      fontSize: theme.spacing(1.75),
      paddingTop: theme.spacing(1)
    }

  }
})

export const ForgetPasswordModalStyle = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .modal-heading': {
      textAlign: 'center',
      fontSize: theme.spacing(3.25),
      marginBottom: theme.spacing(1),
      color: '#FFF'
    },

    '& .login-tab': {
      marginTop: theme.spacing(3.5),
      '& .login-inner-tab': {
        'div[role=tablist]': {
          padding: theme.spacing(1),
          background: theme.palette.primary.inputBg,
          borderRadius: theme.spacing(0.625),
          '& button': {
            width: '50%',
            color: theme.palette.primary.contrastTextDark,
            textTransform: 'capitalize',
            fontSize: theme.spacing(2.25)
          },
          '& .item-one': {
            width: '50%'
          },
          '& .item-two': {
            width: '50%'
          },
          '& .Mui-selected': {
            color: theme.palette.primary.contrastText,
            background: theme.palette.button.mainGradient,
            borderRadius: theme.spacing(0.625)
          }
        }

      },

      '& .tab-panel': {
        padding: theme.spacing(0)
      },

      '& .input-label': {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(2),
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(1)
      },

      '& .login-form-group': {

        // color: theme.palette.primary.contrastTextDark,
        color: 'red',

        '& .MuiInputBase-input': {
          color: theme.palette.primary.contrastText
        }
      },

      '& .custom-checkbox-group': {
        paddingTop: theme.spacing(2.5),
        display: 'flex',
        alignItems: 'flex-start',
        '& .primary-dark-text': {
          color: theme.palette.primary.contrastTextDark,
          fontSize: theme.spacing(2),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(0)
        },

        '& .custom-checkbox': {

          '& .custom-input': {
            appearance: 'none',
            padding: theme.spacing(1.25),
            border: `1px solid ${theme.palette.primary.contrastTextDark}`,
            borderRadius: theme.spacing(0.5),
            cursor: 'pointer',

            ':checked': {
              backgroundImage: `url(${checkIcon})`
            }
          }

        }

      },

      // 'input' : {
      //   color: '#ffffff'
      //   '&::placeholder':{
      //     color: '#ffffff'
      //   }
      // },
      '& .social-media-box': {
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        '& .social-media-icon': {
          background: theme.palette.primary.iconBg,
          textAlign: 'center',
          padding: theme.spacing(1.6, 6),
          borderRadius: theme.spacing(0.625)
        }
      },
      '& .forgot-text': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3.5),

        a: {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(2),
          fontWeight: theme.typography.fontWeightMedium
        }
      }

    },

    '.or-divider': {
      textAlign: 'center',
      padding: theme.spacing(3.5, 0, 2, 0),

      '& .custom-divider': {
        color: theme.palette.primary.contrastTextDark,
        padding: theme.spacing(0, 1),
        position: 'relative',
        '&::before': {
          content: '""',
          width: theme.spacing(10.25),
          height: theme.spacing(0.125),
          background: theme.palette.primary.contrastTextDark,
          position: 'absolute',
          top: '50%',
          left: '100%'
        },

        '&::after': {
          content: '""',
          width: theme.spacing(10.25),
          height: theme.spacing(0.125),
          background: theme.palette.primary.contrastTextDark,
          position: 'absolute',
          top: '50%',
          right: '100%'
        }
      }

    },

    '& .primary-dark-text': {
      color: theme.palette.primary.contrastTextDark,
      paddingRight: theme.spacing(1)
    },

    '.text-danger': {
      color: theme.palette.danger.main,
      fontSize: theme.spacing(1.75),
      paddingTop: theme.spacing(1)
    }

  }
})
