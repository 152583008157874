/* eslint-disable react-hooks/exhaustive-deps */
import Login from "pages/Login";
import Wallet from "pages/Wallet";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { setLoginData } from "redux-thunk/redux/slices/auth.slice";
import {
  setShowLoginPopup,
  setShowSignupPopup,
} from "redux-thunk/redux/slices/gameSetting.slice";
import {
  setChat,
  setSelectedWallet,
} from "redux-thunk/redux/slices/user.slice";
import { getAllBonus } from "redux-thunk/thunk/bonus.thunk";
import { getAllCurrencyStart } from "redux-thunk/thunk/gameSetting";
import { fetchUserInformation } from "redux-thunk/thunk/user.thunk";

import { Box, useTheme } from "@mui/material";
import { CHAT, ROUTE_PATHS } from "constants/index";
import { getAuthToken } from "helpers/cookie.helpers";
import { getItem } from "helpers/localstorage.helpers";
import { openSuccessToaster } from "helpers/toaster.helpers";
import Signup from "pages/Signup/index";
import useWebSocket from "react-use-websocket";
import { setToaster } from "redux-thunk/redux/slices/settings.slice";
import {
  getCasinoProviderList,
  getGeneralData,
} from "redux-thunk/thunk/casino.thunk";
import {
  getSiteData,
  getSiteSettingData,
} from "redux-thunk/thunk/general.thunk";
import { getLanguages } from "redux-thunk/thunk/language.thunk";
import { getEmailCookie } from "../../../helpers/cookie.helpers";
import { encrypt } from "../../../helpers/obfus";
import Toaster from "../../Toaster/Toaster";
import LogoIcon from "./components/LogoIcon/index";
import UserCoin from "./components/UserCoin/UserCoin";
import UserNav from "./components/UserNav/UserNav";
import { StyledHeader } from "./style";

const Header = (props) => {
  const theme = useTheme();
  const token = getAuthToken();
  const isAuthenticated = !!token;

  const { search } = useLocation();
  const emailVarified = new URLSearchParams(search).get("emailVerified");
  const { showLoginPopup, showSignupPopup } = useSelector(
    (state) => state.gameSetting
  );

  const { showWalletPopup, walletSocket } = useSelector(
    (state) => state.wallet
  );

  const socket = useWebSocket(
    isAuthenticated
      ? `${process.env.REACT_APP_WS_URL}/notification?token=${token}`
      : null,
    {
      shouldReconnect: (closeEvent) => true,
      reconnectInterval: 1500,
      reconnectAttempts: 5,
      retryOnError: true,
    },
    true
  );

  const dispatch = useDispatch();
  const { toaster } = useSelector((state) => state.settings);
  const { user, selectedWallet } = useSelector((state) => state.user);

  const showToast = useCallback(
    (msgpopup) => {
      dispatch(setToaster({ isVisible: true, message: msgpopup }));
      setTimeout(() => {
        dispatch(setToaster({ isVisible: false }));
      }, 5000);
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getCasinoProviderList());
    if (token) {
      dispatch(fetchUserInformation());
      dispatch(setLoginData({ accessToken: getAuthToken() }));
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === ROUTE_PATHS.SIGNUP) {
      dispatch(setShowSignupPopup(true));
    } else if (
      window.location.pathname === ROUTE_PATHS.LOGIN &&
      !emailVarified
    ) {
      dispatch(setShowLoginPopup(true));
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!selectedWallet) {
        if (user?.userSetting?.currencyId) {
          dispatch(setSelectedWallet(user?.userSetting?.currencyId));
        } else {
          const currentWallet = user?.wallets?.find((ele) => ele.primary);
          dispatch(setSelectedWallet(currentWallet));
        }
      }
    }
  }, [user]);

  useEffect(() => {
    dispatch(getAllCurrencyStart());
    dispatch(getAllBonus());
    const chattingData = getItem(CHAT);
    if (chattingData?.length > 0) {
      dispatch(setChat({ chatData: chattingData }));
    }
    dispatch(getLanguages());
    dispatch(getSiteData());
    dispatch(getGeneralData());
    dispatch(getSiteSettingData());
  }, []);

  useEffect(() => {
    if (socket.lastMessage) {
      try {
        let message;
        const data = JSON.parse(socket.lastMessage.data);

        if (data.type === "deposit") {
          message = `Your deposit of ${data.effective_change} ${data.coin} has been confirmed!`;
        } else if (data.type === "withdrawal") {
          message = `Your withdrawal of ${data.effective_change} ${data.coin} has been confirmed!`;
        }

        walletSocket.send(
          encrypt(
            JSON.stringify({
              type: "wallet",
              email: getEmailCookie(),
            })
          )
        );

        openSuccessToaster({ message });
      } catch (error) {
        console.log("🚀 ~ getNotif ~ error:", error);
      }
    }
  }, [socket.lastMessage]);

  return (
    <StyledHeader theme={theme}>
      <Box className="nav-wrap brand-logo-wrap">
        <LogoIcon />
        {isAuthenticated && <UserCoin daken={token} />}
        <UserNav />
      </Box>

      {showLoginPopup && <Login />}
      {showWalletPopup && <Wallet daken={token} />}
      {showSignupPopup && <Signup />}
      {toaster.isVisible && (
        <Toaster type={toaster.type} message={toaster.message} />
      )}
    </StyledHeader>
  );
};

export default React.memo(withRouter(Header));
