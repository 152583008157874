import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Collapse,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  useTheme,
} from "@mui/material";
import { GameProviderIcon } from "assets/component-icons/index";
import {
  AffiliateIcon,
  BonusesIcon,
  DiceLeft,
  DiceRight,
  FavoritesIcon,
  LanguageIcon,
  LiveSupportIcon,
  PromotionsIcon,
  ReferralIcon,
  SlotMachine,
  SoccerLeft,
  SoccerRight,
  SportsIcon,
  TransactionIcon,
} from "assets/icons";
import clsx from "clsx";
import { ROUTE_PATHS } from "constants/index";
import { getAuthToken } from "helpers/cookie.helpers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillDice2Fill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setGameCategoryId } from "redux-thunk/redux/slices/games.slice";
import { setTab } from "redux-thunk/redux/slices/lobby.slice";
import { setShowSideNav } from "redux-thunk/redux/slices/settings.slice";
import { setChangeHamburgerIconClass } from "redux-thunk/redux/slices/user.slice";
import { getPromotions } from "redux-thunk/thunk/bonus.thunk";
import {
  getCasinoCategoryGame,
  getCasinoGamesCategories,
} from "redux-thunk/thunk/game.thunk";
import { useCustomScreenSize } from "../../../hooks/UseIsMobile";
import SubMenu from "./SideBarMenu";
import { SidebarWrapper } from "./style";

const languageNames = {
  en: "English",
  fr: "French",
  es: "Spanish",
  pt: "Portuguese",
  // id: "Indonesian",
};

const getFullLanguageName = (languageCode) => {
  return languageNames[languageCode] || languageCode;
};

function SideNavbar() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoggedIn = getAuthToken();
  const { tab } = useSelector((state) => state?.lobby);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [languageOpen, setLanguageOpen] = useState(false);
  const { casinoProviderList } = useSelector((state) => state.casino);
  const { isMobile } = useCustomScreenSize();
  const [intercomOpen, setIntercomOpen] = useState(false);

  const { casinoGameCategories, subCategoryId } = useSelector(
    (state) => state.games
  );
  const { selectedCategoryId } = useSelector((state) => state.casino);
  const [activeTab, setActiveTab] = useState(
    pathname === "/" || pathname === "/casino" ? t("allGames") : ""
  );
  // ----------------- handling the tab switch issue of sideNavBar
  useEffect(() => {
    if (isMobile) {
      dispatch(setShowSideNav(false));
    }

    if (
      (pathname !== "/sports" && tab === "sports") ||
      pathname.slice(0, 7) === "/casino"
    ) {
      dispatch(setTab("casino"));
    }

    const hasSponsor = pathname.includes("sponsorships");
    const hasPromotion = pathname.includes("promotion");
    const hasBonus = pathname.includes("bonus");

    const isSubModule = hasSponsor || hasBonus || hasPromotion;
    if (isSubModule || (pathname === "/" && activeTab === "sponsorships"))
      return;

    if (pathname !== activeTab) {
      setActiveTab("");
    }
  }, [pathname]);
  // --------------------------------

  useEffect(() => {
    dispatch(setShowSideNav(true));
    dispatch(getCasinoGamesCategories({ categoryId: selectedCategoryId }));
  }, []);

  useEffect(() => {
    if (subCategoryId !== null && (pathname === "/" || pathname === "/casino"))
      setActiveTab(subCategoryId);
    else if (
      subCategoryId === null &&
      (pathname === "/" || pathname === "/casino")
    )
      setActiveTab(t("allGames"));
  }, [subCategoryId]);
  const { hamburgerIconClass } = useSelector((state) => state?.user);

  const getCategoryGames = useCallback(
    (item) => {
      dispatch(setGameCategoryId({ id: item?.id, subCategory: item }));
    },
    [dispatch]
  );

  const getAllCategoryGames = () => {
    dispatch(setGameCategoryId({ id: null, subCategory: null }));
    dispatch(getCasinoCategoryGame({}));
    history.push("/casino");
  };

  const tabChangeHandler = (tabName) => {
    setActiveTab(tabName); // Update the active tab state here
    dispatch(setTab(tabName));
    history.push(`/${tabName}`);
  };

  const [isCollapsed, setCollapsed] = useState(false); // State to manage collapse/expand
  const handleCollapseToggle = () => {
    setCollapsed(!isCollapsed);
  };

  const { promotionsData } = useSelector((state) => state.bonus);
  const { language } = useSelector((state) => state.auth);

  const fetchPromotions = useCallback(() => {
    const reqBody = {
      category: 3,
      limit: 10,
      pageNo: 1,
    };

    dispatch(getPromotions(reqBody));
  }, [dispatch]);

  useEffect(() => {
    fetchPromotions();
  }, []);

  const sponsorshipsList = useCallback(() => {
    if (
      !promotionsData ||
      !promotionsData?.promotions ||
      !promotionsData?.promotions?.rows
    ) {
      return [];
    }
    return promotionsData?.promotions?.rows.map((promotionData) => {
      return {
        ...promotionData,
        title: promotionData?.title?.[language?.toUpperCase()]
          ? promotionData?.title?.[language?.toUpperCase()]
          : promotionData?.title?.["EN"?.toUpperCase()],
        content: promotionData?.content?.[language?.toUpperCase()]
          ? promotionData?.content?.[language?.toUpperCase()]
          : promotionData?.content?.["EN"?.toUpperCase()],
        description: promotionData?.description?.[language?.toUpperCase()]
          ? promotionData?.description?.[language?.toUpperCase()]
          : promotionData?.description?.["EN"?.toUpperCase()],
      };
    });
  }, [promotionsData, language]);

  const casinoGameCategoriesList = useCallback(() => {
    if (
      !casinoGameCategories ||
      !casinoGameCategories?.subCategoryList ||
      !casinoGameCategories?.subCategoryList?.rows
    ) {
      return [];
    }
    return casinoGameCategories?.subCategoryList?.rows.map(
      (casinoGameCategoriesData) => {
        return {
          ...casinoGameCategoriesData,
          name: casinoGameCategoriesData?.name?.[language?.toUpperCase()]
            ? casinoGameCategoriesData?.name?.[language?.toUpperCase()]
            : casinoGameCategoriesData?.name?.["EN"?.toUpperCase()],
        };
      }
    );
  }, [casinoGameCategories, language]);

  const sponsorshipsSubCategory = sponsorshipsList()?.map(
    (sponsorship) => sponsorship?.title
  );

  const casinoProviders = useMemo(() => {
    let items = casinoProviderList ?? [];

    items = items.map((item) => {
      return {
        id: item.id,
        title: item.name,
        link: `${ROUTE_PATHS.PROVIDERS}?providerId=${item.id}`,
        icon: <GameProviderIcon />,
      };
    });

    return items.sort((a, b) => a.id - b.id);
  }, [casinoProviderList]);

  useEffect(() => {
    if (
      activeTab !== "sponsorships" &&
      !sponsorshipsSubCategory?.includes(activeTab)
    ) {
      setCollapsed(false);
    }
  }, [activeTab]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setLanguageOpen(false);
  };

  const menuItems = useMemo(
    () => [
      {
        title: "Casino",
        link: "/casino",
        icon: <SlotMachine />,
        defaultExpanded: true,
        items: [
          // {
          //   title: t("newReleases"),
          //   link: ROUTE_PATHS.CASINO,
          //   icon: <SlotMachine />,
          // },
          // {
          //   title: t("deucesOriginals"),
          //   link: ROUTE_PATHS.CASINO,
          //   icon: <SlotMachine />,
          // },
          ...casinoGameCategoriesList()
            ?.filter((m) => m.isActive)
            .map((item) => {
              return {
                title: item?.name,
                link: `${ROUTE_PATHS.CASINO}?categoryId=${item?.id}`,
                icon: item?.imageUrl ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}${item?.imageUrl}`}
                    alt="casinoImg"
                    loading="lazy"
                  />
                ) : (
                  <SlotMachine />
                ),
                onClick: () => getCategoryGames(item),
              };
            }),
        ],
      },
      {
        title: t("providers"),
        link: ROUTE_PATHS.PROVIDERS,
        icon: <GameProviderIcon />,
        items: casinoProviders,
      },
      {
        title: t("favorites"),
        link: ROUTE_PATHS.FAVORITE,
        icon: <FavoritesIcon />,
        auth: true,
      },
      {
        title: t("promotionsTitle"),
        link: ROUTE_PATHS.PROMOTIONS,
        icon: <PromotionsIcon />,
      },
      // {
      //   title: t("referralTitle"),
      //   link: ROUTE_PATHS.REFERRAL,
      //   icon: <ReferralIcon />,
      //   auth: true,
      // },
      {
        title: t("affiliate"),
        link: ROUTE_PATHS.AFFILIATE,
        icon: <AffiliateIcon />,
      },
      {
        title: t("vipClub"),
        link: ROUTE_PATHS.VIPCLUB,
        icon: <TransactionIcon />,
        auth: true,
      },
      {
        title: t("Bonus"),
        link: ROUTE_PATHS.BONUS,
        icon: <BonusesIcon />,
      },
      {
        title: "Live Support",
        // link: ROUTE_PATHS.VIPCLUB,
        icon: <LiveSupportIcon />,
        onClick: () => {
          setIntercomOpen(!intercomOpen);
          window.Intercom(intercomOpen ? "hide" : "show");
        },
      },
    ],
    [
      casinoGameCategoriesList,
      casinoProviders,
      getCategoryGames,
      t,
      intercomOpen,
    ]
  );

  return (
    <SidebarWrapper
      theme={theme}
      className={`sidebar-section ${hamburgerIconClass}`}
    >
      <Paper className="sidebar-paper">
        {/* <div className="buble"></div>
        <div className="buble6"></div> */}

        <MenuList
          className="top-menulist"
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          }}
        >
          <li className="common-btn">
            <Tooltip>
              <Box
                className={` casino-btn ${tab === "casino" ? " active" : ""}`}
                onClick={() => {
                  tabChangeHandler("casino");
                  getAllCategoryGames();
                }}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon className="dice-left">
                  <DiceLeft />
                </ListItemIcon>
                <ListItemIcon className="dice-right">
                  <DiceRight />
                </ListItemIcon>
                <ListItemText>{t("casino")}</ListItemText>
              </Box>
            </Tooltip>

            <Tooltip>
              <Box
                className={` sport-btn ${tab === "sports" ? " active" : ""}`}
                onClick={() => tabChangeHandler("sports")}
                style={{ cursor: "pointer" }}
              >
                <ListItemIcon className="dice-left">
                  <SoccerLeft />
                </ListItemIcon>
                <ListItemIcon className="dice-right">
                  <SoccerRight />
                </ListItemIcon>
                <ListItemText>{t("sports")}</ListItemText>
              </Box>
            </Tooltip>
          </li>
        </MenuList>

        <MenuList className="bottom-menulist">
          <MenuItem
            className="nav-link-hidden"
            onClick={() => {
              tabChangeHandler("casino");
              getAllCategoryGames();
            }}
          >
            <Tooltip>
              <Box
                className={`casino-btn nav-link ${
                  tab === "casino" ? " active" : ""
                }`}
              >
                <ListItemIcon>
                  <BsFillDice2Fill />
                </ListItemIcon>
              </Box>
            </Tooltip>
          </MenuItem>

          <MenuItem
            className="nav-link-hidden"
            onClick={() => tabChangeHandler("sports")}
          >
            <Tooltip>
              <Box
                className={`casino-btn nav-link ${
                  tab === "sports" ? " active" : ""
                }`}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ListItemIcon>
                  <SportsIcon />
                </ListItemIcon>
              </Box>
            </Tooltip>
          </MenuItem>

          {menuItems.map((menuItem, index) => {
            if (!isLoggedIn && menuItem.auth) return null;

            if (menuItem.items && menuItem.items.length > 0) {
              return (
                <SubMenu
                  key={index}
                  isAuthenticated={isLoggedIn}
                  defaultExpanded={menuItem.defaultExpanded}
                  item={{
                    isActive:
                      pathname === menuItem.link ||
                      pathname.startsWith(menuItem.link),
                    icon: menuItem.icon,
                    title: menuItem.title,
                    subNav: menuItem.items.map((item) => {
                      return {
                        title: item.title,
                        path: item.link,
                        icon: item.icon,
                        isActive: false,
                        onClick: item.onClick,
                        auth: item.auth,
                      };
                    }),
                  }}
                />
              );
            }

            return (
              <MenuItem
                key={index}
                onClick={() => {
                  if (menuItem.onClick) {
                    menuItem.onClick();
                  } else {
                    history.push(menuItem.link);
                  }
                }}
                disableRipple
              >
                <Tooltip>
                  <Box
                    className={clsx("nav-link", {
                      active: pathname === menuItem.link,
                    })}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText>{menuItem.title}</ListItemText>
                  </Box>
                </Tooltip>
              </MenuItem>
            );
          })}

          <MenuItem
            onClick={() => {
              dispatch(setChangeHamburgerIconClass("sidebar-open"));
            }}
            disableRipple
          >
            <Box className="inner-menu-list">
              <Tooltip>
                <Box
                  className={` nav-link sidebar-dropdown ${
                    activeTab === "language" ? " active" : ""
                  }`}
                  onClick={() => setLanguageOpen(!languageOpen)}
                >
                  <ListItemIcon>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={getFullLanguageName(selectedLanguage)}
                  />

                  <IconButton
                    aria-label="arrow"
                    className="link-hidden"
                    sx={{ color: "#57535E", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ChevronRightIcon
                      className="chevron"
                      style={{
                        transform: languageOpen
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                        transition: "0.3s all",
                      }}
                    />
                  </IconButton>
                </Box>
              </Tooltip>
              <Collapse in={languageOpen} timeout="auto" unmountOnExit>
                <List className="inner-to-inner-list link-hidden">
                  {Object.entries(languageNames).map(([key, value]) => {
                    return (
                      <ListItem
                        key={key}
                        onClick={() => handleLanguageChange(key)}
                        className="nav-link"
                        style={{
                          padding: ".35rem 0",
                        }}
                      >
                        <ListItemText> {value}</ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Box>
          </MenuItem>
        </MenuList>
      </Paper>
    </SidebarWrapper>
  );
}

export default React.memo(SideNavbar);
