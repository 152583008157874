import { createSlice } from '@reduxjs/toolkit'
import { getUserCasinoTransactions, getLeaderboardDetails, getUserDepositTransactions, getUserSportsbookTransactions, getUserWithdrawDepositTransactions, getUsersTotalBets, getLeaderboardWinnerDetails } from 'redux-thunk/thunk/user.thunk'

const initialState = {
  withdrawTransactions: null,
  depositTransactions: null,
  usersCasinoTransactions: null,
  leaderboardDetails: null,
  leaderboardDetailsLoading: false,
  leaderBoardWinnerDetails: null,
  usersSportsbookTransactions: null,
  usersTotalBetAmounts: null,
  getUserCasinoTransactionsLoader: false
}

const {
  reducer
} = createSlice({
  name: 'transactions',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDepositTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          depositTransactions: action.payload
        }
      })
      .addCase(getUserWithdrawDepositTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawTransactions: action.payload
        }
      })
      .addCase(getUserCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCasinoTransactions: action.payload,
          getUserCasinoTransactionsLoader: false
        }
      })
      .addCase(getUserCasinoTransactions.pending, (state, action) => {
        return {
          ...state,
          getUserCasinoTransactionsLoader: true
        }
      })
      .addCase(getUserCasinoTransactions.rejected, (state, action) => {
        return {
          ...state,
          getUserCasinoTransactionsLoader: false
        }
      })
      .addCase(getLeaderboardDetails.pending, (state, action) => {
        return {
          ...state,
          leaderboardDetailsLoading: true
        }
      })
      .addCase(getLeaderboardDetails.fulfilled, (state, action) => {
        return {
          ...state,
          leaderboardDetailsLoading: false,
          leaderboardDetails: action.payload
        }
      })
      .addCase(getLeaderboardDetails.rejected, (state, action) => {
        return {
          ...state,
          leaderboardDetailsLoading: true
        }
      })
      .addCase(getLeaderboardWinnerDetails.pending, (state, action) => {
        return {
          ...state,
          leaderboardDetailsLoading: true
        }
      })
      .addCase(getLeaderboardWinnerDetails.fulfilled, (state, action) => {
        return {
          ...state,
          leaderboardDetailsLoading: false,
          leaderBoardWinnerDetails: action.payload
        }
      })
      .addCase(getLeaderboardWinnerDetails.rejected, (state, action) => {
        return {
          ...state,
          leaderboardDetailsLoading: false
        }
      })
      .addCase(getUserSportsbookTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersSportsbookTransactions: action.payload
        }
      })
      .addCase(getUsersTotalBets.fulfilled, (state, action) => {
        return {
          ...state,
          usersTotalBetAmounts: action.payload
        }
      })
  }
})

export default reducer
