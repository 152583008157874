// CryptoComponent.js
import { Box, InputLabel, Typography } from "@mui/material";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import { CustomTextField } from "components/layout/Common/StyledForm/style";
import ErrorMessage from "components/layout/ErrorMessage/index";
import CircularProgressBar from "components/layout/ProgressBar/CircularProgressBar";
import Loader from "components/ui-kit/Loader/index";
import { LOADER_HANDLER_TYPES } from "constants/index";
import { Form, Formik } from "formik";
import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPrice } from "utils/numberFormatter";
import { walletWithdrawCryptoSchema } from "../schema";
import {
  BoxWithdrawInfo,
  WithdrawInfoContainer,
  WithdrawWarningContainer,
} from "../style";

const CryptoComponent = ({
  MIN_MAX_WITHDRAW_AMOUNT,
  depositData,
  handleWithdrawRequest,
  handleMaxValue,
  alertBoxopen,
  setAlertBoxOpen,
  loading,
  withdrawalFeeLoader,
  error,
  walletCurrency,
  withdrawalFee,
  handleAlertBoxClose,
  checkEstimatedFee,
  isSubmitting = false,
}) => {
  const { t } = useTranslation();
  const {
    walletData: { convertedRate },
  } = useSelector((state) => state.wallet);

  const getAmount = useCallback(
    (amount) => {
      const fee = Number(withdrawalFee);
      const estimatedReceived = Number(amount) - fee;
      const converted = estimatedReceived / convertedRate[walletCurrency];

      return { fee, estimatedReceived, converted };
    },
    [convertedRate, walletCurrency, withdrawalFee]
  );

  return (
    <Fragment>
      {
        <Box sx={{ ml: 2 }}>
          <Formik
            enableReinitialize
            initialValues={{ address: "", amount: "", usdAmount: 0 }}
            validationSchema={walletWithdrawCryptoSchema(t, {
              MIN_MAX_WITHDRAW_AMOUNT,
              address: depositData?.address,
            })}
            validateOnMount
            validateOnBlur
            validateOnChange
            onSubmit={handleWithdrawRequest}
          >
            {({ values, handleChange, handleSubmit, handleBlur }) => {
              const { fee, estimatedReceived, converted } = getAmount(
                values.amount
              );

              return (
                <Form autoComplete="off">
                  <Box>
                    <Box>
                      <InputLabel className="input-label">
                        {t("Address")}
                        <Typography className="required-indicator">
                          *
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        type="text"
                        className="form-control shadow-none"
                        placeholder={t("pasteYourCurrencyAddressHere", {
                          currency: walletCurrency,
                        })}
                        name="address"
                        id="address"
                        required
                        value={values.address}
                        onChange={(e) => {
                          alertBoxopen && setAlertBoxOpen(false);
                          handleChange(e);
                        }}
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="color-red text-danger error-msg login-input ms-2 d-flex"
                      />
                    </Box>
                    <Box>
                      <InputLabel className="input-label">
                        {t(
                          `Amount ${
                            walletCurrency !== "default"
                              ? `(Min. ${MIN_MAX_WITHDRAW_AMOUNT} ${walletCurrency})`
                              : ""
                          }`
                        )}
                        <Typography className="required-indicator">
                          *
                        </Typography>
                      </InputLabel>
                      <div className="input-with-button">
                        <CustomTextField
                          type="number"
                          style={{ marginBottom: "0px" }}
                          className="form-control shadow-none"
                          placeholder="Enter Amount"
                          name="amount"
                          value={values.amount}
                          onChange={(e) => {
                            alertBoxopen && setAlertBoxOpen(false);
                            handleChange(e);
                            checkEstimatedFee(e.target.value);
                          }}
                          onBlur={handleBlur}
                        />
                        <button
                          disabled={walletCurrency === "default"}
                          onClick={(e) => {
                            e.preventDefault();
                            values.amount = handleMaxValue;
                            checkEstimatedFee(handleMaxValue);
                          }}
                        >
                          Max
                        </button>
                      </div>
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="color-red text-danger error-msg login-input ms-2 d-flex"
                      />
                    </Box>
                    <Box className="address-submit-box">
                      <CustomMainButton
                        type="submit"
                        fullWidth
                        disabled={loading || estimatedReceived <= 0}
                        sx={{ mb: 0 }}
                      >
                        {loading ? (
                          <Loader variant={LOADER_HANDLER_TYPES.submit} />
                        ) : (
                          <span>{t("withdrawWithdrawBtn")}</span>
                        )}
                      </CustomMainButton>

                      <WithdrawWarningContainer>
                        <p>
                          {t(
                            `Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a ${walletCurrency} withdraw address wallets`
                          )}
                        </p>
                        <p>
                          {"Please do NOT withdraw as cross-chain transfer!"}
                        </p>
                      </WithdrawWarningContainer>

                      {withdrawalFeeLoader ? (
                        <CircularProgressBar />
                      ) : (
                        Number(values.amount) > 0 &&
                        estimatedReceived > 0 &&
                        !isNaN(withdrawalFee) && (
                          <WithdrawInfoContainer>
                            <BoxWithdrawInfo>
                              <p>{t("Estimated Fee")}</p>
                              <p>
                                {`${formatPrice(fee, false)} ${walletCurrency}`}
                              </p>
                            </BoxWithdrawInfo>
                            <BoxWithdrawInfo>
                              <p>{t("Estimated Received")}</p>
                              <p>
                                {t(
                                  `${formatPrice(
                                    estimatedReceived,
                                    false
                                  )} ${walletCurrency}`
                                )}
                              </p>
                            </BoxWithdrawInfo>
                            <BoxWithdrawInfo>
                              <p>{t("Estimated Received (USD)")}</p>
                              <p>${formatPrice(converted, true)}</p>
                            </BoxWithdrawInfo>
                          </WithdrawInfoContainer>
                        )
                      )}
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      }
    </Fragment>
  );
};

export default CryptoComponent;
