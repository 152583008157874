import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllCurrencyService = (parms) => {
  // return axiosInstance(METHOD_TYPES.get, `get-currency?type=${parms}`, {}, {
  return axiosInstance(METHOD_TYPES.get, 'get-currency', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const getPaymentMethodsService = (parms) => {
  return axiosInstance(METHOD_TYPES.get, 'nutech/get-payment-methods/deposit', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const getAllBanksService = (parms) => {
  return axiosInstance(METHOD_TYPES.get, 'nutech/get-banks/withdraw', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const getLiminalApproxFeeService = (payload) => {
  return axiosInstance(METHOD_TYPES.post, 'liminal/get-approx-fees', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const getPaymentCodeService = (payload) => {
  return axiosInstance(METHOD_TYPES.post, 'nutech/get-payment-code', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getWithdrawAmountService = (payload) => {
  return axiosInstance(METHOD_TYPES.post, 'nutech/withdraw-amount', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getAllSiteinfoService = () => {
  return axiosInstance(METHOD_TYPES.get, 'get-cms-info', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
export const getAllCmsSiteinfoService = (data) => {
  return axiosInstance(METHOD_TYPES.get, `get-cms-page?cmsSlug=${data}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getGameLanguagesService = () => {
  return axiosInstance(METHOD_TYPES.get, 'get-languages', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getGameCountriesService = () => {
  return axiosInstance(METHOD_TYPES.get, 'get-all-country', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getSignupRegistrationFieldsService = () => {
  return axiosInstance(METHOD_TYPES.get, 'get-registration-fields', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
