import FileCopyIcon from "@mui/icons-material/FileCopy";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material/index";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import {
  CustomSelectBox,
  CustomTextField,
} from "components/layout/Common/StyledForm/style";
import ErrorMessage from "components/layout/ErrorMessage/index";
import QRCodeGenerator from "components/layout/QRCodeGenerator/index";
import AlertBox from "components/Popup/AlertBox";
import Loader from "components/ui-kit/Loader/index";
import { LOADER_HANDLER_TYPES } from "constants/index";
import { Form, Formik } from "formik";
import { openSuccessToaster } from "helpers/toaster.helpers";
import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepositCurrency,
  setDepositePaymentMethod,
  setPaymentCodeData,
} from "redux-thunk/redux/slices/wallet.slice";
import { getAllCurrencyStart } from "redux-thunk/thunk/gameSetting";
import { depositAmountRequest } from "redux-thunk/thunk/user.thunk";
import {
  getPaymentCode,
  getPaymentMethods,
} from "redux-thunk/thunk/wallet.thunk";
import { currencyIcon } from "utils/currencyIcon";
import { findPlainNumber, formatNumberWithCommas } from "utils/numberFormatter";
import { v4 as uuidv4 } from "uuid";
import { walletDepositeSchema } from "../schema";
import CryptoComponent from "./CryptoComponent";

const AmountLabelComponent = memo((props) => {
  const { minMaxAmountLimit, depositCurrency } = props;
  const { t } = useTranslation();
  return typeof minMaxAmountLimit === "object" ? (
    <InputLabel className="input-label">
      {t(
        `Amount (Min ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit?.min)
        )} - Max ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit?.max)
        )} ) ${depositCurrency}`
      )}
      <Typography className="required-indicator">*</Typography>
    </InputLabel>
  ) : (
    <InputLabel className="input-label">
      {" "}
      {t(
        `Amount (Min ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit)
        )} ${depositCurrency})`
      )}{" "}
      <Typography className="required-indicator">*</Typography>
    </InputLabel>
  );
});

export const Deposit = ({ currencyLimit }) => {
  const [isGetDepositAddApiCall, setIsGetDepositAddApiCall] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    depositData,
    depositCurrency,
    allPaymentMethods,
    getPaymentCodeData,
    depositePaymentMethod,
  } = useSelector((state) => state.wallet);
  const { allCurrencies } = useSelector((state) => state.gameSetting);
  const [currentCurrencyValue, setCurrentCurrencyValue] =
    useState(depositCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [alertBoxopen, setAlertBoxOpen] = useState(false);
  const [error, setError] = useState({
    name: "",
    message: "",
  });
  const { [LOADER_HANDLER_TYPES.content]: loading } = useSelector(
    (state) => state.loader
  );
  const { [LOADER_HANDLER_TYPES.submit]: faitDepositloading } = useSelector(
    (state) => state.loader
  );
  const userData = useSelector((state) => state?.user?.user?.getUser);
  const MIN_MAX_WITHDRAW_AMOUNT = currencyLimit?.value?.[depositCurrency];
  const handleSelect = (event) => {
    alertBoxopen && setAlertBoxOpen(false);
    const { value } = event?.target;
    dispatch(setDepositCurrency(value));
  };
  const depositFields = [
    {
      name: "currency",
      title: t("Currency"),
      handleChange: handleSelect,
      menuItems: allCurrencies,
      value: depositCurrency,
      defaultValue: t("selectCurrency"),
      widthXS: 12,
      isDisabled: false,
    },
  ];

  const handleCopyClick = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(depositData?.address);
      const message = t("CopiedSuccessfull");
      openSuccessToaster({ message });
    }
  };

  const handleAlertBoxClose = () => {
    setAlertBoxOpen(false);
  };

  const onDepositAddressError = (error) => {
    setAlertBoxOpen(true);
    if (error?.[0]?.name === "InternalServerError") {
      setError({ name: error?.[0]?.name, message: t("internalServerError") });
    } else {
      setError({ name: error?.[0]?.name, message: t(error?.[0]?.description) });
    }
  };

  const fetchDepositAddress = async (payload) => {
    const reqBody = { coin: payload === "USDT" ? "ETH" : payload };
    setIsGetDepositAddApiCall(true);
    dispatch(depositAmountRequest({ values: reqBody, onDepositAddressError }));
  };

  const handleDepositeSubmit = (values) => {
    const { paymentMethod, mobileNo, amount } = values;
    const reqBody = {
      amount: `${findPlainNumber(amount)}` || "",
      bankCode: paymentMethod || "",
      name: userData?.username || "LoggedIn-User",
      mobile: `${mobileNo}` || "",
    };
    dispatch(getPaymentCode(reqBody));
    dispatch(setDepositePaymentMethod(paymentMethod));
  };

  const handleKeyPress = (event) => {
    const isNumeric = /^[0-9]$/;
    if (!isNumeric.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const result = allCurrencies?.filter(
      (currency) => currency?.code === depositCurrency
    );
    setSelectedCurrency(...result);

    if (
      depositCurrency !== "default" &&
      currentCurrencyValue !== depositCurrency
    ) {
      if (result?.[0]?.type === 0) {
        fetchDepositAddress(depositCurrency);
      } else {
        dispatch(getPaymentMethods());
      }
      setCurrentCurrencyValue(depositCurrency);

      if (depositCurrency !== "IDR") {
        // when need remove it
        dispatch(setPaymentCodeData());
      }
    }
  }, [depositCurrency]);

  useEffect(() => {
    dispatch(getAllCurrencyStart());
  }, []);

  const tempAllPaymentMethods = useMemo(() => {
    const result = allPaymentMethods?.banks?.find(
      (paymentMethod) => paymentMethod?.code === "QR_CODE"
    );

    if (result) {
      return {
        ...result,
        name: "QRIS (QR Code)",
      };
    }
    return [];
  }, [allPaymentMethods]);

  const CopyFieldComponent = (params) => {
    const { value, title, handleCopyClick, name } = params;
    return (
      <Grid item xs={12}>
        <InputLabel className="input-label">{t(title)}</InputLabel>
        <CustomTextField
          type="text"
          className="form-control shadow-none"
          name={name}
          id={name}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopyClick} aria-label="Copy">
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography sx={{ color: "green", fontSize: 13 }}>
          {t(`Please copy "${title}" to make a payment`)}
        </Typography>
      </Grid>
    );
  };

  const FaitComponent = () => {
    const conditions = { MIN_MAX_WITHDRAW_AMOUNT };

    return (
      <Box sx={{ ml: 2 }}>
        <Formik
          enableReinitialize
          initialValues={{ paymentMethod: "default", mobileNo: "", amount: "" }}
          validationSchema={walletDepositeSchema(t, conditions)}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={handleDepositeSubmit}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => {
            return (
              <Form autoComplete="off">
                <Grid container spacing={2} className="login-form-group">
                  <Grid item xs={12}>
                    <AmountLabelComponent
                      minMaxAmountLimit={MIN_MAX_WITHDRAW_AMOUNT}
                      depositCurrency={depositCurrency}
                    />
                    <CustomTextField
                      type="text"
                      className="form-control shadow-none"
                      placeholder="Enter Amount"
                      name="amount"
                      value={values?.amount === "0" ? "" : values?.amount}
                      onChange={(e) => {
                        e.target.value = formatNumberWithCommas(
                          findPlainNumber(e.target.value)
                        );
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className="color-red text-danger error-msg login-input ms-2 d-flex"
                    />
                  </Grid>

                  <Grid item xs={12} key={uuidv4()}>
                    <Box>
                      <InputLabel
                        className="input-label"
                        htmlFor="paymentMethod"
                      >
                        {t("paymentMethod")}
                        <Typography className="required-indicator">
                          *
                        </Typography>
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.paymentMethod}
                        name="paymentMethod"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ width: "100%", color: "#FFF" }}
                        MenuListProps={{
                          className: "header-wallet-section",
                        }}
                      >
                        <MenuItem value="default" disabled>
                          {t("selectPaymentmethod")}
                        </MenuItem>
                        {/* {allPaymentMethods?.banks?.map((menuItem) => ( */}
                        {[tempAllPaymentMethods]?.map((menuItem) => (
                          <MenuItem value={menuItem?.code} key={uuidv4()}>
                            {currencyIcon[menuItem?.code]} &nbsp;{" "}
                            {t(menuItem?.name)}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="paymentMethod"
                        component="div"
                        className="color-red text-danger error-msg login-input ms-2 d-flex"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel className="input-label">
                      {t("mobileNo")}
                    </InputLabel>
                    <CustomTextField
                      type="text"
                      className="form-control shadow-none"
                      placeholder={t("enterMobileNo")}
                      name="mobileNo"
                      value={values.mobileNo}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="mobileNo"
                      component="div"
                      className="color-red text-danger error-msg login-input ms-2 d-flex"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomMainButton
                      type="submit"
                      fullWidth
                      disabled={loading}
                      sx={{ mb: 4 }}
                    >
                      {faitDepositloading ? (
                        <Loader variant={LOADER_HANDLER_TYPES.submit} />
                      ) : (
                        <span>{t("deposit")}</span>
                      )}
                    </CustomMainButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  };

  // const CryptoComponent = () => {
  //   return (
  //     <>{
  //       loading && isGetDepositAddApiCall && selectedCurrency?.type === 0
  //         ? (
  //           <Box className='loderBox'>
  //             <Stack sx={{ color: 'grey.500' }} spacing={2} direction='row'>
  //               <CircularProgress color='success' />
  //             </Stack>
  //           </Box>
  //           )
  //         : (
  //             depositData?.address && (
  //               <>
  //                 <CopyFieldComponent
  //                   value={depositData?.address}
  //                   title={t(`Your ${depositCurrency} deposit address`)}
  //                   handleCopyClick={handleCopyClick}
  //                   name='depositAddress'
  //                 />
  //                 <Grid item xs={12} textAlign='center'>
  //                   <Box sx={{ mb: 3 }} className='QRCodeGenerator'>
  //                     <QRCodeGenerator value={depositData?.address} />
  //                   </Box>
  //                   <Typography
  //                     component='p'
  //                     textAlign='center'
  //                     className='wallet-bottom-text'
  //                   >
  //                     {t('minDepositAmount', { min: `${currencyLimit?.value?.[depositCurrency]} ${depositCurrency}` })}
  //                   </Typography>
  //                   <Typography
  //                     component='p'
  //                     textAlign='center'
  //                     className='wallet-bottom-text'
  //                   >
  //                     {t(`Only send ${depositCurrency} to this address, 2 confirmations required`)}
  //                   </Typography>
  //                 </Grid>
  //               </>
  //             )
  //           )
  //     }
  //     </>
  //   )
  // }

  return (
    <>
      <Grid container spacing={2} className="wallet-form-group">
        {depositFields?.map((field, index) => {
          // Assuming field.id is unique and stable for each field
          const fieldId = field?.id || index;

          return (
            <Grid item xs={field?.widthXS} key={fieldId}>
              <Box>
                <InputLabel className="input-label" htmlFor={field?.name}>
                  {t(field?.title)}
                </InputLabel>
                <CustomSelectBox
                  labelId={`select-${fieldId}`} // Unique ID for each Select
                  id={field?.name}
                  value={field?.value}
                  name={field?.name}
                  onChange={field?.handleChange}
                  sx={{ width: "100%", color: "#FFF" }}
                  disabled={field?.isDisabled}
                  MenuListProps={{
                    className: "header-wallet-section",
                  }}
                  className='select-crypto'
                >
                  <MenuItem value="default" disabled>
                    {t(field?.defaultValue)}
                  </MenuItem>
                  {field?.menuItems?.map((menuItem, menuIndex) => {
                    const menuItemValue = menuItem?.code || menuIndex;

                    return (
                      <MenuItem
                        value={menuItem?.code}
                        key={menuItemValue}
                        disabled={menuItem?.code === "IDR"}
                      >
                        {currencyIcon[menuItem?.code]} &nbsp;{" "}
                        {t(menuItem?.code)}
                      </MenuItem>
                    );
                  })}
                </CustomSelectBox>
              </Box>

              {field?.value === "USDT" && (
                <Grid>
                  <InputLabel className="input-label" htmlFor="network">
                    Network
                  </InputLabel>
                  <CustomSelectBox
                    value={1}
                    defaultValue={1}
                    sx={{ width: "100%", color: "#FFF" }}
                    readonly
                  >
                    <MenuItem value={1}>Ethereum(ERC-20)</MenuItem>
                  </CustomSelectBox>
                </Grid>
              )}
            </Grid>
          );
        })}

        {alertBoxopen && (
          <Grid item xs={12} mt={2}>
            <Box className="custom-alert-box">
              <AlertBox
                open={alertBoxopen}
                handleClose={handleAlertBoxClose}
                messageType="error"
                message={error?.message}
              />
            </Box>
          </Grid>
        )}
        {selectedCurrency?.type === 0 && !alertBoxopen && (
          <CryptoComponent
            currencyLimit={currencyLimit}
            isGetDepositAddApiCall={isGetDepositAddApiCall}
            selectedCurrency={selectedCurrency}
          />
        )}
        {selectedCurrency?.type === 1 ? (
          !getPaymentCodeData?.paymentCode ? (
            faitDepositloading ? (
              <Box className="loderBox">
                <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                  <CircularProgress color="success" />
                </Stack>
              </Box>
            ) : (
              <FaitComponent />
            )
          ) : (
            <>
              {depositePaymentMethod === "QR_CODE" ? (
                <Grid item xs={12} textAlign="center">
                  <Box sx={{ mb: 3 }} className="QRCodeGenerator">
                    <QRCodeGenerator value={getPaymentCodeData?.paymentCode} />
                  </Box>
                  <Typography
                    component="p"
                    textAlign="center"
                    className="wallet-bottom-text"
                  >
                    {t("Scan this QR code to make a payment")}
                  </Typography>
                </Grid>
              ) : (
                <CopyFieldComponent
                  value={getPaymentCodeData?.paymentCode}
                  title={t(`Your ${depositCurrency} payment code`)}
                  handleCopyClick={handleCopyClick}
                  name="paymentCode"
                />
              )}
            </>
          )
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};
