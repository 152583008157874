import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLanguageService } from 'network/services/language.service'
import { getLanguageSupportKeysSuccess } from 'redux-thunk/redux/slices/language.slice';

export const getLanguages = createAsyncThunk(
  '/getlanguage',
  async (data, thunkApi) => {
    try {
      const res = await getLanguageService(data)
      thunkApi.dispatch(getLanguageSupportKeysSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
