import React from 'react'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

const AlertBox = (props) => {
  const { messageType, message, open, handleClose } = props
  return open && (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert
        severity={messageType} onClose={handleClose}
      >
        {message}
      </Alert>
    </Stack>
  )
}

export default AlertBox
