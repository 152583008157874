import React from 'react';
import { ChatBoxWrapper } from './style'
import { Box, Typography } from '@mui/material';
import { currencyIcon } from '../../../../../utils/currencyIcon'

const NotificationCard = ({ notification }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    // Calculate time differences in milliseconds
    const diffMs = now - date;

    // Time difference constants in milliseconds
    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;

    // Check if the date is today
    if (diffMs < msInDay && date.getDate() === now.getDate()) {
      if (diffMs < msInMinute) {
        return 'Just now';
      } else if (diffMs < msInHour) {
        const minutes = Math.floor(diffMs / msInMinute);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        const hours = Math.floor(diffMs / msInHour);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      }
    }

    // Format the date as "12-09-2024 4:13 PM" for dates on a different day
    const formattedDate = date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <ChatBoxWrapper>
      <Box className="notification-card">
        {/* Header Section */}
        <Box className="notification-header">
          <Box className="icon-container">
            {/* Replace the source with an actual image or icon */}
            <img src="wallet-icon.png" alt="icon" />
          </Box>
          <Typography className="notification-title">
            {notification.message || 'Notification'}
          </Typography>
          <Typography className="notification-time">
            {formatDate(notification.createdAt)}
          </Typography>
        </Box>

        {/* Content Section */}
        <Typography className="notification-content">
          {`Your ${notification.code === 'addMoney' ? 'Deposit' : 'Withdrawal'} using `}
          <span className="highlight">{notification.amount}</span>{' '}
          {currencyIcon[notification.coin]} has been successfully processed.
        </Typography>
      </Box>
    </ChatBoxWrapper>
  );
};

export default NotificationCard;
