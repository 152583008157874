import { Box, Grid, InputLabel, MenuItem, Typography } from "@mui/material";
import { CustomMainButton } from "components/layout/Common/StyledButton/CustomButton";
import {
  CustomSelectBox,
  CustomTextField,
} from "components/layout/Common/StyledForm/style";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { currencyIcon } from "utils/currencyIcon";
import { BuyCryptoStyle } from "./style";

export const BuyCrypto = () => {
  const { t } = useTranslation();
  const { allCurrencies } = useSelector((state) => state.gameSetting);
  const [currency, setCurrency] = useState("");

  const BuyCryptoFields = [
    {
      name: "currency",
      menuItems: allCurrencies,
      defaultValue: t("selectCurrency"),
      isDisabled: false,
    },
  ];

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <BuyCryptoStyle>
      <InputLabel className="label">
        <p>Your Transfer</p>
        <Typography className="required-indicator">*</Typography>
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <CustomTextField type="number" maxLength={50} />
        </Grid>
        <Grid item lg={4} xs={12}>
          <CustomSelectBox className="form-control shadow-none"></CustomSelectBox>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <InputLabel className="label">
          <p>In Exchange For</p>
        </InputLabel>
        {BuyCryptoFields?.map((field, index) => {
          const fieldId = field?.id || index;
          return (
            <Grid item xs={12} key={fieldId}>
              <Box>
                <CustomSelectBox
                  labelId={`select-${fieldId}`}
                  id={field?.name}
                  value={currency}
                  onChange={handleCurrencyChange}
                  name={field?.name}
                  sx={{ width: "100%", color: "#FFF" }}
                  disabled={field?.isDisabled}
                  MenuListProps={{
                    className: "header-wallet-section",
                  }}
                  className="select-box"
                >
                  <MenuItem value="" disabled>
                    {t(field?.defaultValue)}
                  </MenuItem>
                  {field?.menuItems?.map((menuItem, menuIndex) => {
                    const menuItemValue = menuItem?.code || menuIndex;

                    return (
                      <MenuItem
                        value={menuItem?.code}
                        key={menuItemValue}
                        disabled={menuItem?.code === "IDR"}
                      >
                        {currencyIcon[menuItem?.code]} &nbsp;{" "}
                        {t(menuItem?.code)}
                      </MenuItem>
                    );
                  })}
                </CustomSelectBox>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid item xs={12}>
        <div className="label">
          <p>Payment Provider</p>
        </div>
        <div className="box-estimated">
          <div className="moonpay">
            <div className="icon">
              <div className="bullet"></div>
            </div>
            <div className="type">
              <p>MoonPay.com</p>
            </div>
          </div>
          <div className="user-get">
            <div className="desc">
              <p>You Get</p>
            </div>
            <div className="amount">
              <p>27.43 USDT</p>
            </div>
          </div>
        </div>
        <div className="disclaimer">
          <p>
            Disclaimer: By choosing to purchase crypto with a third-party
            provider, you agree to their Terms of service and will be subject to
            their independent KYC process.
          </p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <CustomMainButton fullWidth>Buy</CustomMainButton>
      </Grid>
    </BuyCryptoStyle>
  );
};
