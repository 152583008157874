import { EMAIL, TOKEN } from 'constants/index'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

// 24 hour * 60 minutes of every hour
const COOKIE_EXPIRE_MIN = 24 * 60

export const getAuthToken = () => {
  return cookies.get(TOKEN)
}

export const setAuthToken = (authAccessToken) => {
  cookies.set(TOKEN, authAccessToken, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })
}

export const removeAuthToken = () => {
  cookies.remove(TOKEN, { path: '/' })
  localStorage.clear()
}

export const signIn = ({ token, email }) => {
  setAuthToken(token)
  setEmailCookie(email)
}

export const signOut = () => {
  removeAuthToken()
}

// Function to save email in cookies
export const setEmailCookie = (email) => {
  cookies.set(EMAIL, email, {
    path: '/', // Cookie available across the entire site
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000) // Expires in 24 hours
  });
};

// Function to get email from cookies
export const getEmailCookie = () => {
  return cookies.get(EMAIL);
};

// Function to remove email from cookies
export const removeEmailCookie = () => {
  cookies.remove(EMAIL, { path: '/' });
};

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}
