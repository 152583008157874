const errorCodeToMessage = new Map([
  [600, 'internalServerError'],
  [601, 'loginFailedError'],
  [602, 'invalidCredentialsError'],
  [603, 'invalidTokenError'],
  [604, 'invalidSessionError'],
  [605, 'invalidAccessError'],
  [606, 'nonOperationalError'],
  [2001, 'createUserError'],
  [2002, 'operatorNotExistsError'],
  [2003, 'operatorPlayerNotExists'],
  [2004, 'dataMismatchError'],
  [2005, 'userNotFoundError'],
  [2006, 'duplicateEmailError'],
  [2007, 'operatorUserIsBlocked']
])
export default errorCodeToMessage
