const config = {

  apiGateways: {
    BASE_URL_1: `${process.env.REACT_APP_USER_API_URL}`
  },
  SOCKET_URL: `${process.env.REACT_APP_USER_BACKEND_SOCKET_URL}`,
  META_MASK_SIGN_MESSAGE: `${process.env.REACT_APP_META_MASK_SIGN_MESSAGE}`,
  IMAGE_URL: `${process.env.REACT_APP_IMG_URL}`,
  BASE_URL: `${process.env.BASE_URl}`,
  socialLogin: {
    google: {
      googleAPI: 'https://www.googleapis.com/oauth2/v3/userinfo',
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
    },
    facebook: {
      // appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      appVersion: 'v19.0'
    }
  }
}

export default config
