import React, { memo, useEffect, useState } from 'react'
import { Box, Modal, Typography, FormControlLabel, Radio, FormControl, RadioGroup, IconButton } from '@mui/material/index'
import { useTranslation } from 'react-i18next'
import { BpCheckedIcon, BpIcon, IOSSwitch, WalletModalStyle } from './style'
import { CrossIcon, WalletSvgIcon } from 'assets/icons/index'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { currencyIcon } from 'utils/currencyIcon'
import { CustomMainButton } from 'components/layout/Common/StyledButton/CustomButton'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { fiatCurrency } from './constants'

const WalletSettings = memo((props) => {
  const { openWalletSettingsPopup, setOpenWalletSettingsPopup, changeCurrencySetting, setChangeCurrencySetting, handleSwitchItemClick } = props
  const { t } = useTranslation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const userData = useSelector((state) => state?.user?.user?.getUser)

  const [currentSelectedSwitch, setCurrentSelectedSwitch] = useState('')
  const [isWalletSettingChange, setIsWalletSettingChange] = useState(false)

  const changeCurrencySettingFunc = (event) => {
    const { name, checked } = event.target
    setChangeCurrencySetting((prev) => {
      return {
        ...prev,
        [name]: checked
      }
    })
  }

  const closeWalletPopup = () => {
    setOpenWalletSettingsPopup(false)
    setCurrentSelectedSwitch(userData?.other?.faitCurrencyCode)
  }

  const switchControllSettings = [
    {
      name: 'hideZeroBalance',
      title: t('hideZeroBalancesTitle'),
      subTitle: t('hideZeroBalancesSubTitle'),
      isDefaultChecked: changeCurrencySetting?.hideZeroBalance

    },
    {
      name: 'displayCryptoInFiat',
      title: t('displayCryptoInFiatTitle'),
      subTitle: t('displayCryptoInFiatSubTitle'),
      isDefaultChecked: changeCurrencySetting?.displayCryptoInFiat
    }
  ]

  function BpRadio (props) {
    return (
      <Radio
        disableRipple
        color='default'
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    )
  }

  const saveWalletSettingChanges = () => {
    handleSwitchItemClick(changeCurrencySetting)
    setIsWalletSettingChange(true)
    closeWalletPopup() // for temp
  }

  useEffect(() => {
    if (userData?.other?.faitCurrencyCode !== currentSelectedSwitch) {
      setCurrentSelectedSwitch(userData?.other?.faitCurrencyCode)
    }
  }, [userData?.other?.faitCurrencyCode])

  useEffect(() => {
    if (loading && isWalletSettingChange) {
      const message = 'wallet settings changed successfully'
      openSuccessToaster({ message })
      closeWalletPopup()
      setIsWalletSettingChange(false)
    }
  }, [loading])

  return (
    <Modal
      open={openWalletSettingsPopup}
      onClose={closeWalletPopup}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <WalletModalStyle>
        <Box className='wallet-modal'>
          <Box className='wallet-modal-heading'>
            <Typography id='modal-modal-title' variant='h6' component='h2' className='modal-heading'>
              <WalletSvgIcon /> {t('Wallet Settings')}
            </Typography>
            <IconButton onClick={closeWalletPopup}>
              <CrossIcon />
            </IconButton>
          </Box>
          <Box className='wallet-setting-headings'>
            {switchControllSettings?.map(setting => {
              return (
                <FormControlLabel
                  key={uuidv4()}
                  control={<IOSSwitch sx={{ m: 1 }} defaultChecked={setting?.isDefaultChecked} />}
                  name={setting?.name}
                  onChange={changeCurrencySettingFunc}
                  label={
                    <Box>
                      <Typography className='walle-subheading'>{setting?.title}</Typography>
                      <Typography className='wallet-setting-subheading'>{setting?.subTitle}</Typography>
                    </Box>
                  }
                />
              )
            })}

          </Box>
          <Box className='wallet-setting-switch'>
            <FormControl>
              <RadioGroup
                defaultValue='female'
                aria-labelledby='demo-customized-radios'
                name='customized-radios'
              >
                {fiatCurrency?.map((currency, index) => {
                  return (
                    <FormControlLabel
                      key={uuidv4()}
                      checked={currentSelectedSwitch === currency?.code}
                      disabled={!changeCurrencySetting?.displayCryptoInFiat}
                      onChange={() => {
                        setChangeCurrencySetting({ ...changeCurrencySetting, faitCurrencyCode: currency?.code })
                        setCurrentSelectedSwitch(currency?.code)
                      }}
                      control={<BpRadio />}
                      label={<> {currency?.code} &nbsp;{currencyIcon[currency?.code]} </>}
                      classes={{ label: !changeCurrencySetting?.displayCryptoInFiat ? 'disabled-label' : '' }}
                    />
                  )
                })}

              </RadioGroup>
            </FormControl>
          </Box>
          {/* <Box className='or-divider'>
            <span className='custom-divider' />
          </Box> */}

          <Box className='wallet-setting-footer'>
            <Typography>{t('Please note that these are currency approximations.')}</Typography>
            <CustomMainButton
              type='button'
              onClick={saveWalletSettingChanges}
              className='btn-head-wallet'
            >
              {t('save')}
            </CustomMainButton>
          </Box>
        </Box>
      </WalletModalStyle>
    </Modal>
  )
})

export default memo(WalletSettings)

WalletSettings.defaultProps = {
  openWalletSettingsPopup: false
}
