import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  alertMessage: {},
  showAlert: false,
  alertTitle: '',
  alertContent: '',
  alertType: 'info' // Default type is 'info'
}

const {
  actions: {
    setAlertMessage,
    setShowPopup,
    removeAlertMessage,
    hideAlert
  },
  reducer
}= createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      return {
        alertMessage: action?.payload
      }
    },
    setShowPopup(state, action) {
      state.showAlert = true;
      state.alertTitle = action.payload.title || '';
      state.alertContent = action.payload.content || '';
      state.alertType = action.payload.type || 'info';
    },
    hideAlert(state) {
      state.showAlert = false;
      state.alertTitle = '';
      state.alertContent = '';
      state.alertType = 'info';
    },
    removeAlertMessage: (state, action) => {
      return {
        alertMessage: {}
      }
    }
  }
})

export default reducer
export { setAlertMessage,
  setShowPopup,
  removeAlertMessage,
  hideAlert,
 }
