
import { Box, styled } from '@mui/material'

export const LinearProgressBarStyle = styled(Box)(({ theme, background }) => {
  return {
    '& .linear-progressbar-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .title': {
        transition: '0.5s ease-in-out',
        fontSize: theme.spacing(3),
        // [theme.breakpoints.down('lg')]: {
        //   fontSize: theme.spacing(2)
        // },

        '&:hover': {
          transform: 'translateX(20px)'
        }
      },
      '& .MuiTypography-body1': {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          fontSize: theme.spacing(1.4)
        }
      }
    },
    '& .linear-progressbar-box': {
      '& .linear-progressbar': {
        padding: 5,
        borderRadius: '1rem'
      },
      '& .MuiLinearProgress-root': {
        background: '#2f4553'
      },
      '& .MuiLinearProgress-bar': {
        background: background || 'green'
      }
    }
  }
})

export const CircularProgressBarStyle = styled(Box)(({ theme }) => {
  return {
    '& .circular-progressbar-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
})
