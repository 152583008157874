import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const onWindowResize = () => {
      if (typeof window !== "undefined") {
        const mobile = window.matchMedia("(max-width: 767px)")
        setIsMobile(mobile.matches)
      }
    }

    onWindowResize()

    window.addEventListener("resize", onWindowResize)

    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [isMobile])

  return isMobile
}

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    const onWindowResize = () => {
      if (typeof window !== "undefined") {
        const tablet = window.matchMedia("(max-width: 992px)")
        setIsTablet(tablet.matches)
      }
    }

    onWindowResize()

    window.addEventListener("resize", onWindowResize)

    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [isTablet])

  return isTablet
}

export const useIsPortrait = () => {
  const [isPortrait, setIsPortrait] = useState(false)

  useEffect(() => {
    const onWindowResize = () => {
      if (typeof window !== "undefined") {
        const portrait = window.matchMedia("(orientation: portrait)")
        const lg = window.matchMedia("(min-width: 1024px)")
        setIsPortrait(portrait.matches && lg.matches)
      }
    }

    onWindowResize()

    window.addEventListener("resize", onWindowResize)

    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [isPortrait])

  return isPortrait
}

export const useCustomScreenSize = () => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isPortrait = useIsPortrait()

  return { isMobile: isMobile || isTablet, isTablet, isPortrait }
}

export default useIsMobile;
