import { signOut } from 'helpers/cookie.helpers'
// import { openErrorToaster } from 'helpers/toaster.helpers'
import { getTranslation } from 'helpers/translations.helpers'
import { store } from 'App'
import errorMessages from 'network/messages/errorMessages'
import { stopLoader } from 'redux-thunk/redux/slices/loader.slice'
import { setShowPopup } from 'redux-thunk/redux/slices/alert.slice'
// import { setAlertMessage } from 'redux-thunk/redux/slices/alert.slice'

export const errorHandler = (error) => {
  if (error.response.status === 500) {
    // Snackbar Internal Server Error
    // openErrorToaster({
    //   message: getTranslation(errorMessages.internalServerError)
    // })
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 403) {
    // Snackbar UnAuthed
    store.dispatch(setShowPopup({ title: 'Error', content: (error.response.data &&
      error.response.data?.errors &&
      error.response.data?.errors[0]?.description) ||
    getTranslation(errorMessages.unAuthorized), type: 'error' }));

    // openErrorToaster({
    //   message:
    //     (error.response.data &&
    //       error.response.data?.errors &&
    //       error.response.data?.errors[0]?.description) ||
    //     getTranslation(errorMessages.unAuthorized)
    // })
    signOut()

    store.dispatch(stopLoader(error.response.config.loader))
    window.location.href = '/'
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 404) {
    // Snackbar UnAuthed
    // openErrorToaster({
    //   message: getTranslation(errorMessages.endPointNotFound)
    // })
    store.dispatch(stopLoader(error.response.config.loader))
    return
  } else if (error.response.status === 409) {
    window.location.href = '/'
    signOut()
    store.dispatch(stopLoader(error.response.config.loader))
    return
  } else if (error.response.config?.loader) {
    // Other errors
    store.dispatch(stopLoader(error.response.config.loader))
  }

  // Open Error Toaster
  // const errorMessage =
  //   (error.response.data &&
  //     error.response.data?.errors &&
  //     error.response.data?.errors[0]?.description) ||
  //   getTranslation(errorMessages.internalServerError)
  // openErrorToaster({ message: errorMessage })

  return Promise.reject(error.response.data.errors)
}
