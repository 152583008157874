import React from 'react'
import { CountryViewBarContainer } from './style'

const CountryViewBar = () => {
	return (
		<CountryViewBarContainer>
			Country top Bar
		</CountryViewBarContainer>
	)
}

export default CountryViewBar