export default {
  404: '404',
  register: 'Registro',
  login: 'Acceso',
  grand: 'grandioso',
  major: 'Importante',
  minor: 'Menor',
  mini: 'Mini',
  crashGameTitle: 'Chocar',
  betAmount: 'Monto de la apuesta',
  profit: 'Ganancia',
  payout: 'Pago objetivo',
  chance: 'Oportunidad de ganar',
  betBtn: 'Apuesta',
  autoCashout: 'Retiro automático',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'máx.',
  wagered: 'Apostado',
  lost: 'Perdido',
  won: 'Ganado',
  streak: 'Racha',
  highestWin: 'Mayor victoria',
  highestLost: 'Mayor perdida',
  highestBet: 'Apuesta más alta',
  maxStreak: 'Racha máxima',
  maxProfit: 'Beneficio máximo: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Tu historial de apuestas está vacío.',
  messagesCopyNotSupported: 'Su navegador no admite la copia automática al portapapeles.\n',
  messagesCopied: 'Dirección copiada.',
  deposit: 'Depósito',
  statReset: 'Restablecer estadísticas',
  rollOver: 'Dese la vuelta',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  inputFieldBtnMax: 'máximo',
  buttonTextPlaceBet: 'Realizar apuesta',
  buttonTextCancelBet: 'Cancelar apuesta',
  buttonTextEscapeBet: 'Retiro',
  buttonTextStartAutoBet: 'Iniciar apuesta automática',
  buttonTextCancelAutoBet: 'Cancelar apuesta automática',
  buttonTextLoading: 'Cargando',
  tabsMyBets: 'Mis apuestas',
  tabsDeposit: 'Depósito',
  tabsCashout: 'Retiros',
  tabsAllbets: 'Todas las apuestas',
  tabsTopbets: 'Apuestas principales',
  tabsBonus: 'Prima',
  tabsjackpot: 'Bote',
  tabsBetHistory: 'Historial de apuestas',
  timerTextCurrentPayout: 'Pago actual',
  timerTextStarting: 'Comenzando en',
  timerTextSec: 's',
  timerTextFlewAway: 'Se estrelló',
  timerTextWait: 'Espere por favor..',
  timerTextPreparingRound: 'Ronda de preparación',
  timerTextLoadingRound: 'Ronda de carga',
  comingSoon: 'Muy pronto !',
  labelsProfitOnWin: 'Ganancia al ganar',
  inputsNoOfBets: 'Número de apuestas',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Comenzar',
  inputsCancel: 'Cancelar',
  buttonsMin: 'mín.',
  buttonsMax: 'máx.',
  prediction: 'Predicción',
  luckyNumber: 'Número de la suerte',
  multiplier: 'Multiplicador',
  chanceToWin: 'Oportunidad de ganar',
  bets: 'Apuestas',
  depositBtn: 'Depósito',
  rollUnder: 'Rodar hacia abajo',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Historia',
  navBarMyBets: 'Mis apuestas',
  navBarAllBets: 'Todas las apuestas',
  navBarHighRollers: 'Apostadores altos',
  navBarRoundHistory: 'Historia de la ronda',
  navBarTotal: 'Apuestas totales',
  topHeaderBetId: 'ID de apuesta',
  topHeaderUser: 'Usuario',
  topHeaderPayout: 'Pagar',
  topHeaderAmount: 'Cantidad',
  topHeaderTime: 'Tiempo',
  topHeaderProfit: 'Ganancia',
  topHeaderRoundCrashed: 'Se estrelló',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Picadillo',
  topHeaderRoundBet: 'Apuesta',
  topHeaderRoundProfit: 'Ganancia',
  topHeaderRoundSignature: 'Firma',
  roundHistoryTitle: 'INFORMACIÓN DEL JUEGO',
  roundHistoryDescGame: 'Juego',
  roundHistoryDescCrashAt: 'Se estrelló en:',
  roundHistoryDescDate: 'Fecha :',
  roundHistoryDescBetHeader: 'Apuesta',
  roundHistoryTableTitlePlayer: 'JUGADOR',
  roundHistoryTableTitleBet: 'APUESTA',
  roundHistoryTableTitleCashedOut: 'SIN EFECTIVO',
  roundHistoryTableTitleProfit: 'GANANCIA',
  roundHistoryTableTitleBetId: 'ID DE APUESTA',
  roundHistoryMessagesNoBetHistory: 'Tu historial de apuestas está vacío.',
  placedBetsTabPlaced: 'Apuesta realizada',
  placedBetsTabPrev: 'Apuesta anterior',
  placedBetsTitleUser: 'Usuario',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Apuesta',
  placedBetsTitleProfit: 'Ganancia',
  placedBetsTitleCashout: 'Retiro',
  placedBetsNoPlacedBet: 'No se realizan apuestas.',
  provablyFairContentCheckBtn: 'Verificar feria demostrable',
  provablyFairContentTitle: 'Demostrablemente justo',
  provablyFairContentDesc: 'Se puede demostrar que el resultado de la ventaja de cuatro lados es justo. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Tasa de accidentes',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Tasa de accidentes sin x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hash redondo',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'p.ej. ',
  provablyFairContentInputFieldsSubmitBtn: 'Generar firma',
  provablyFairContentSignature: 'Firma redonda',
  provablyFairContentErrorsCrashRate: 'por favor ingrese el número en el campo de tasa de accidentes',
  resetPasswordTitle: 'Restablecer la contraseña',
  resetPasswordNewPasswordTitle: 'Nueva contraseña',
  resetPasswordNewPasswordErrorsRequired: '*se requiere contraseña.',
  resetPasswordNewPasswordErrorsMinLength: 'Se permiten un mínimo de 8 caracteres.',
  resetPasswordNewPasswordErrorsMaxLength: 'Máximo 16 caracteres permitidos.',
  resetPasswordNewPasswordErrorsPattern: '*La contraseña debe tener de 8 a 16 caracteres alfanuméricos y especiales. ',
  resetPasswordConfirmPasswordTitle: 'confirmar Contraseña',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Las contraseñas no coinciden.',
  resetPasswordResetBtn: 'Reiniciar',
  passwordResetSuccess: 'Contraseña cambiada correctamente.',
  resetPasswordWentWrong: '¡Algo salió mal!',
  levelGrand: 'GRANDIOSO',
  levelMajor: 'IMPORTANTE',
  levelMinor: 'MENOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Gana $1000 diarios ',
  bannerContentContest: 'Concursos ahora ',
  bannerContentjoin: '¡Únete a nuestro concurso de apuestas diario y conviértete en el héroe!  ',
  bannerContentPlayNow: 'Reproducir ahora',
  bannerContentTitle: 'Gana el premio mayor',
  bannerContentContent: 'Juega más',
  bannerContentGameName: 'JUEGO DE ACCIDENTE',
  bannerContentGameDesc: 'Juega más',
  partners: 'Socios',
  gamesGoldenDragon: 'Dragón dorado',
  gamesCrashGame: 'Juego de choque',
  gamesDice: 'Dado',
  gamesHilo: 'hilo',
  allBetsTitle: 'Todas las apuestas',
  allBetsBetId: 'ID de apuesta',
  allBetsUser: 'Usuario',
  allBetsPayout: 'Pagar',
  allBetsAmount: 'Cantidad',
  allBetsTime: 'Tiempo',
  allBetsProfit: 'Ganancia',
  LoginwithOtp: 'Iniciar sesión con Otp',
  EnterLoginOtp: 'Ingresar Iniciar Sesión Otp',
  submit: 'Entregar',
  footerAboutUs: 'Sobre nosotros',
  footerFaq: 'Preguntas frecuentes',
  footerSitePolicy: 'Política del sitio',
  footerResponsibleGambling: 'Juego responsable',
  footerTerms: 'Términos y condiciones',
  footerCopywrite: '2022 LÍNEA CRIPTO. ',
  signinTitle: 'Acceso',
  signinUsername: 'Nombre de usuario o correo electrónico',
  signinPassword: 'Contraseña',
  signinLoginBtn: 'Acceso',
  signinSignup: 'Inscribirse',
  signinForgetPassword: '¿Has olvidado tu contraseña?',
  signinDontHaveAccount: '¿No tienes una cuenta? ',
  signinCreateAccount: 'Crear una cuenta',
  signinLoginDescription: 'En ocasiones, Clutch Gaming puede enviarle ofertas gratuitas y promociones especiales por correo electrónico o SMS. ',
  signinErrorsUserNameRequired: '*Por favor ingrese nombre de usuario/correo electrónico.',
  signinErrorsUserNameMinLength: 'Se permiten un mínimo de 4 caracteres.',
  signinErrorsUserNameMaxLength: 'Máximo 20 caracteres permitidos.',
  signinErrorsUserNamePattern: 'Por favor ingrese un nombre de usuario válido, solo se permiten letras y números.',
  signinErrorsPasswordRequired: '*Se requiere contraseña.',
  signinErrorsPasswordMinLength: 'Se permiten un mínimo de 8 caracteres.',
  signinErrorsPasswordMaxLength: 'Máximo 16 caracteres permitidos.',
  signinErrorsPasswordPattern: '*La contraseña debe tener de 8 a 16 caracteres alfanuméricos y especiales. ',
  signinSuccessfullyLoggedIn: 'Inicie sesión exitosamente.',
  signinResetMailSent: 'Se envió el correo electrónico para restablecer la contraseña.',
  signinTryAgain: 'Algo salió mal. Por favor, vuelva a intentarlo.',
  signinResetPasswordUsernameErr: 'Ingrese el nombre de usuario/correo electrónico para restablecer la contraseña.',
  signinResetMailSentSuccessContent: 'Le enviamos un enlace para restablecer la contraseña a su correo electrónico. ',
  signupTitle: 'Registro',
  signupUsername: 'Nombre de usuario',
  signupEmail: 'Correo electrónico',
  signupTermsConditions: 'Términos',
  signupWalletConnect: 'Iniciar sesión con billetera ',
  signupPassword: 'Contraseña',
  signupEighteenPlus: 'Soy mayor de 18 años y acepto los Términos de servicio',
  signupRegisterBtn: 'Inscribirse',
  signupCreateNewAccount: 'Crear una nueva cuenta',
  signupFullName: 'Nombre completo',
  signupReferralCode: 'código de referencia',
  signupConfirmPassword: 'confirmar Contraseña',
  signupUseSocialAccount: 'O use una cuenta social',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: '¿Tener una cuenta? ',
  signupLoginBtn: 'Acceso',
  signupErrorsUserNameRequired: '*Por favor ingrese el nombre de usuario.',
  signupErrorsUserNameMinLength: 'Se permiten un mínimo de 4 caracteres.',
  signupErrorsUserNameMaxLength: 'Máximo 20 caracteres permitidos.',
  signupErrorsUserNamePattern: 'Por favor ingrese un nombre de usuario válido, solo se permiten letras y números.',
  signupErrorsFullNameRequired: '*Por favor ingrese el nombre completo.',
  signupErrorsFullNamePattern: 'Por favor ingrese un nombre completo válido, solo se permiten letras y números.',
  signupErrorsEmailRequired: '*Por favor ingrese el correo electrónico.',
  signupErrorsEmailMinLength: 'Se permiten un mínimo de 4 caracteres.',
  signupErrorsEmailMaxLength: 'Máximo 20 caracteres permitidos.',
  signupErrorsEmailPattern: 'Por favor introduzca un correo electrónico válido.',
  signupErrorsPasswordRequired: '*Se requiere contraseña.',
  signupErrorsPasswordMinLength: 'Se permiten un mínimo de 8 caracteres.',
  signupErrorsPasswordMaxLength: 'Depósito máximo permitido: 16 caracteres.',
  signupErrorsPasswordPattern: '*La contraseña debe tener entre 8 y 16 dígitos alfanuméricos (mayúsculas y minúsculas) y caracteres especiales. ',
  signupErrorsConfirmPasswordMatch: 'Contraseña debe coincidir con.',
  signupPlaceholderUserName: 'Mín. ',
  signupPlaceholderEmail: 'p.ej. ',
  signupPlaceholderPassword: 'Mín. ',
  signupSomethingWentWrong: '¡Algo salió mal!',
  signupSignupGoogle: 'Regístrese exitosamente en Google.',
  signupRegistered: 'Registrado exitosamente.',
  signupConfirmAge: 'Por favor confirma que tienes 18 años.',
  signupSignupFacebook: 'Regístrese exitosamente en Facebook.',
  signupCookiesConsent: 'Permita que las cookies de terceros desde la configuración de su navegador utilicen el inicio de sesión social.',
  signupRegisterEmailPopupContent: 'Le enviamos un correo electrónico de verificación. ',
  accountMenuDeposit: 'Depósito',
  accountMenuCashout: 'Retiro',
  accountMenujackpotWinners: 'Ganadores del premio mayor',
  accountMenuTransactions: 'Actas',
  accountMenuPromotions: 'Promociones',
  accountMenuProfile: 'Perfil',
  accountMenuLogout: 'Cerrar sesión',
  accountMenuSound: 'Sonido',
  accountMenuMusic: 'Música',
  accountMenuWithdraw: 'Retirar',
  pageNotFound: '¡No podemos encontrar la página que estás buscando!',
  error: 'Error',
  somethingWentWrong: '¡Algo salió mal!',
  verifyEmailThankYou: '¡Gracias!',
  verifyEmailEmailIsVerified: 'Su correo electrónico está verificado. ',
  verifyEmailGoToLogin: 'Ir a la página de inicio de sesión',
  verifyEmailWentWrong: '¡Algo salió mal!',
  verifyEmailTokenExpired: 'El correo electrónico ya está verificado o el enlace ha caducado. Vuelva a enviar el enlace de verificación del correo electrónico proporcionando su correo electrónico registrado a continuación. ',
  verifyEmailGoToHome: 'Ir a la página de inicio',
  verifyEmailSuccessMsg: 'Correo electrónico verificado exitosamente.',
  gameCardTitle: 'Clutch Gaming es un sitio de apuestas criptográficas demostrablemente justo',
  bonusSectionTitle: 'JUEGOS DE EMBRAGUE',
  bonusSectionSpanText: 'presenta los más generosos y variados',
  bonusSectionBonuses: 'Bonificaciones',
  bonusSectionSubtitleText: 'Juega con placer y obtén recompensas adicionales durante el juego. ',
  bonusSectionHeadingText: 'Bonos de Clutch Gaming',
  bonusSectionDesc: 'Como el mejor casino de Bitcoin, Clutch Gaming tiene un elaborado sistema de generosos bonos. ',
  JoiningBonusTitle: 'Bonificación por unirse',
  JoiningBonusButton: 'Reclama ahora',
  JoiningBonusDesc: 'Estos bonos animan a los jugadores de casinos en línea a apostar utilizando Bitcoin u otras criptomonedas. ',
  depositBonusTitle: 'Bono de depósito',
  depositBonusButton: 'Depósito',
  depositBonusDesc: 'Estos bonos animan a los jugadores de casinos en línea a apostar utilizando Bitcoin u otras criptomonedas. ',
  refferBonusTitle: 'Bonificación por recomendación',
  refferBonusButton: 'Depósito',
  refferBonusDesc: 'Estos bonos animan a los jugadores de casinos en línea a apostar utilizando Bitcoin u otras criptomonedas. ',
  aboutSectionTitle: '¿Qué son los juegos de embrague?',
  aboutSectionSpanText1: ' Clutch Gaming es un innovador sitio de casino Bitcoin, creado teniendo en mente nuevos conceptos de juegos de azar en línea. ',
  aboutSectionSpanText2: 'El casino en línea te permite jugar tus juegos favoritos de forma gratuita o con criptomonedas. ',
  aboutSectionSpanText3: ' En este mundo virtual de apuestas con Bitcoin, puedes disfrutar de muchas oportunidades: jugar tus juegos de casino favoritos, obtener bonos, participar en concursos y promociones.',
  verifiedSectionTitle: 'Software verificado y con licencia',
  verifiedSectionSpanText1: 'No copiamos ni adoptamos software de otros sitios web. ',
  faqSectionTitle: 'Preguntas frecuentes de los jugadores',
  faqSectionForgetPasswordTitle: 'Olvidé mi contraseña.',
  faqSectionForgetPasswordDesc: 'No proporcionó una dirección de correo electrónico de recuperación para su cuenta. ',
  faqSectionMyDepositCreditedTitle: 'Mi depósito no ha sido acreditado.',
  faqSectionMyDepositCreditedDesc: ' Contenido de marcador de posición para este acordeón, cuyo objetivo es demostrar la clase. ',
  SupportedCurrencyTitle: 'Moneda admitida',
  SupportedCurrencySpanText1: 'No copiamos ni adoptamos software de otros sitios web. ',
  CarouselSectionTitle: 'ACCIDENTE DE HODL',
  CarouselSectionSubTitle: ' Juega más juegos y ten la oportunidad de ganar. ',
  WalletSettingTitle: 'Configuración de billetera',
  WalletSettingHideZero: 'Ocultar saldos cero',
  WalletSettingHideZeroInfo: 'Tu saldo cero no aparecerá en tu billetera',
  WalletSettingDisplayFiat: 'Mostrar criptografía en fiat',
  WalletSettingDisplayFiatInfo: 'Todas las apuestas',
  WalletSettingNoteForApprox: 'Tenga en cuenta que estas son aproximaciones monetarias.',
  WalletSettingSave: 'Ahorrar',
  WalletSettingNoWalletFound: 'No se encontró ninguna billetera',
  jackpotWinnersNoWinnerAvailable: 'No hay ganadores disponibles',
  jackpotWinnersNew: 'Nuevo',
  jackpotWinnersHeader: 'Ganador del premio mayor',
  jackpotDetailsHeader: 'Detalle del premio mayor',
  jackpotDetailsCampaignPeriod: 'Período de campaña',
  jackpotDetailsFrom: 'De',
  jackpotDetailsTo: 'A',
  jackpotDetailsBettingRule: 'Regla de apuestas',
  jackpotDetailsMinBetAmount: 'Cantidad mínima de apuesta',
  jackpotDetailsMaxBetAmount: 'Cantidad máxima de apuesta',
  jackpotDetailsBetPercentage: 'Porcentaje de apuesta',
  jackpotDetailsWinningRuleHeader: 'Regla ganadora',
  jackpotDetailsWinningRule: 'Realizar apuestas con un monto de apuesta mayor aumentará las posibilidades de ganar.',
  jackpotDetailsProfitRule: 'Si el monto de la apuesta está entre minBetAmount y maxBetAmount, el %betPercentage del monto de la apuesta se agregará al monto del premio mayor.',
  profileTitle: 'Detalles personales',
  profileFName: 'Nombre de pila',
  profileLname: 'Apellido',
  profileUserName: 'Nombre de usuario',
  profileDob: 'Fecha de nacimiento',
  profileEmail: 'Dirección de correo electrónico',
  profilePhone: 'Número de teléfono',
  profileChange: 'Cambiar',
  profileSave: 'Guardar cambios',
  profilePassword: 'Contraseña',
  profileSubmit: 'Entregar',
  profileUploadProfile: 'Subir perfil',
  profileCurrentPassword: 'Contraseña actual',
  profileNewPassword: 'Nueva contraseña',
  profileConfirmPassword: 'confirmar Contraseña',
  profileScanQRCode: 'Escanear código QR',
  profileEnterSecretCode: 'Ingrese el código secreto',
  profileErrorsFirstNameRequired: 'Se requiere el primer nombre.',
  profileErrorsFirstNamePattern: 'Caracteres no válidos en su nombre. ',
  profileErrorsFirstNameMinLength: 'Se permiten un mínimo de 3 caracteres.',
  profileErrorsFirstNameMaxLength: 'Máximo 20 caracteres permitidos.',
  profileErrorsLastNameRequired: 'Se requiere apellido.',
  profileErrorsLastNamePattern: 'Caracteres no válidos en su apellido. ',
  profileErrorsLastNameMinLength: 'Se permiten un mínimo de 3 caracteres.',
  profileErrorsLastNameMaxLength: 'Máximo 20 caracteres permitidos.',
  profileErrorsChangePasswordOldRequired: 'Se requiere contraseña anterior.',
  profileErrorsChangePasswordOldPattern: 'Por favor ingrese una contraseña válida.',
  profileErrorsChangePasswordOldMinLength: 'Se permiten un mínimo de 8 caracteres.',
  profileErrorsChangePasswordOldMaxLength: 'Máximo 16 caracteres permitidos.',
  profileErrorsChangePasswordNewRequired: 'Se requiere una nueva contraseña.',
  profileErrorsChangePasswordNewPattern: 'Por favor ingrese una contraseña válida.',
  profileErrorsChangePasswordNewMinLength: 'Se permiten un mínimo de 8 caracteres.',
  profileErrorsChangePasswordNewMaxLength: 'Máximo 16 caracteres permitidos.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Las contraseñas no coinciden.',
  profileErrorsChangePasswordNewPasswordMatched: 'La nueva contraseña no puede ser la misma que la contraseña actual.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Se requiere confirmar la contraseña.',
  profileErrorsUserNameRequired: 'Se requiere nombre de usuario.',
  profileErrorsUserNamePattern: 'Por favor ingrese un nombre válido.',
  profileErrorsUserNameMinLength: 'Se permiten un mínimo de 3 caracteres.',
  profileErrorsUserNameMaxLength: 'Máximo 20 caracteres permitidos.',
  profileErrorsDobRequired: 'Se requiere fecha de nacimiento.',
  profileErrorsEmailRequired: 'Correo electronico es requerido.',
  profileErrorsPostcodeRequired: 'Se requiere código postal.',
  profileErrorsCityRequired: 'Se requiere ciudad.',
  profileErrorsCurrencyRequired: 'Se requiere moneda.',
  profileErrorsAddressRequired: 'La dirección es necesaria.',
  profileErrorsCountryRequired: 'Se requiere país.',
  profileErrorsEmailPattern: 'Por favor introduzca un correo electrónico válido.',
  profileErrorsEmailMinLength: 'Se permiten un mínimo de 3 caracteres.',
  profileErrorsEmailMaxLength: 'Máximo 20 caracteres permitidos.',
  profileMessagesProfileUpdate: 'Perfil actualizado con éxito.',
  profileMessagesChangePassword: 'Contraseña actualizada exitosamente.',
  profileProfilePicProfilePicUpload: 'Subir imagen de perfil',
  profileNoNewChanges: '¡No se pudo actualizar el perfil! ',
  profileLoggedInWithSocial: 'Ha iniciado sesión con inicio de sesión social.',
  profileSideNavigatorSound: 'Sonido',
  profileSideNavigatorMusic: 'Música',
  profileSideNavigatorProfile: 'Mi perfil',
  profileSideNavigatorBetHistory: 'Historial de apuestas',
  profileSideNavigatorTransactions: 'Actas',
  profileSideNavigatorDeposit: 'Depósito',
  profileSideNavigatorWithdraw: 'Retirar',
  profileSideNavigatorGameLimits: 'Límites del juego',
  profileSideNavigatorReferral: 'Remisión',
  profileSideNavigatorChangePassword: 'Cambiar la contraseña',
  profileSideNavigatorTermsConditions: 'Términos',
  profileSideNavigatorLogOut: 'Cerrar sesión',
  profileSideNavigatorProvablyFair: 'Demostrablemente justo',
  WalletInfoSectionNotAvailable: 'No disponible',
  WalletInfoSectionRealBalance: 'Saldo real',
  GameLimitsMinimumBet: 'Apuesta mínima',
  GameLimitsMaximumBet: 'Apuesta máxima',
  GameLimitsMaxWinFor1Bet: 'Ganancia máxima para una apuesta',
  ProvablyFairSubTitle: 'Este juego utiliza tecnología Provably Fair para determinar el resultado del juego. ',
  ProvablyFairMaximumBet: 'Apuesta máxima:',
  ProvablyFairMaxWinFor1Bet: 'Ganancia máxima para una apuesta',
  promotionsTitle: 'Promociones',
  promotionsNoLosingData: 'Lo sentimos, promociones de bonificación No Losing disponibles para ',
  promotionsNoDepositData: 'Lo sentimos, promociones de bonificación sin depósito disponibles para ',
  promotionsCurrency: ' divisa.',
  promotionsViewBtn: 'Vista',
  promotionsClaimSuccess: 'Has reclamado con éxito',
  promotionsAvailability: 'Disponibilidad: ',
  promotionsAvailabilityTabLosing: 'Bonificación perdedora',
  promotionsAvailabilityTabDeposit: 'Bono de depósito',
  promotionsDepositBonusTableCode: 'Código',
  promotionsDepositBonusTableMinDeposit: 'Mín. ',
  promotionsDepositBonusTablePercentage: 'Porcentaje',
  promotionsDepositBonusTableMaxBonus: 'Bono máximo',
  promotionsDepositBonusTableRolloverMultipler: 'Multiplicador de rollover',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Rollover máximo por apuesta',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Es hora de alcanzar el objetivo de renovación',
  promotionsBonusCode: 'CÓDIGO EXTRA: ',
  promotionsLossesClaim: 'Se pueden reclamar pérdidas por (dentro del período de la campaña): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Porcentaje',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Mín. ',
  promotionsBonusStatus: 'Estado de bonificación: ',
  promotionsBonusAmount: 'Monto del bono: ',
  promotionsRolloverTarget: 'Objetivo de rollover: ',
  promotionsRolloverAchieved: 'Rollover logrado: ',
  promotionsActivatebtn: 'ACTIVAR',
  promotionsClaimBtn: 'AFIRMAR',
  promotionsCancelBtn: 'CANCELAR',
  promotionsBackBtn: 'ATRÁS',
  promotionsBonus: 'Prima',
  promotionsTermAndConditions: ' Términos',
  promotionsClaimDepositBonusTitle: 'Pasos para reclamar el bono de depósito',
  promotionsClaimDepositBonusFirst: 'Activar código de bonificación',
  promotionsClaimDepositBonusSecond: 'Monto del depósito a la billetera',
  promotionsClaimDepositBonusThird: 'Lograr el objetivo de reinversión para reclamar el bono',
  promotionsClaimLosingBonusTitle: 'Pasos para reclamar el bono perdedor',
  promotionsClaimLosingBonusFirst: 'Activar código de bonificación',
  promotionsClaimLosingBonusSecond: 'Juega tus juegos favoritos',
  promotionsClaimLosingBonusThird: 'Haga clic en "Reclamar pérdidas" para reclamar pérdidas dentro del período de la campaña',
  promotionsWentWrong: '¡Algo salió mal!',
  transactionTitle: 'Actas',
  transactionBetId: 'ID de apuesta',
  transactionUser: 'Usuario',
  transactionPayout: 'Pagar',
  transactionAmount: 'Cantidad',
  transactionTime: 'Fecha',
  transactionProfit: 'Ganancia',
  transactionCashout: 'Retiro',
  transactionBet: 'Apuesta',
  transactionMultix: 'Multi.x',
  transactionWin: 'Ganar',
  transactionFairness: 'Justicia',
  transactionReferFriend: 'Recomendar un amigo',
  transactionTotal: 'Apuestas totales',
  transactionWins: 'gana',
  transactionRefresh: 'Actualizar',
  transactionFilterTopMultipliers: 'Principales multiplicadores',
  transactionFilterHugeWins: 'Grandes victorias',
  transactionFilterBiggestWins: 'Mayores victorias',
  transactionFilterMultipliers: 'Multiplicadores',
  transactionRealBalance: 'Saldo real',
  depositWithdrawTXDate: 'Fecha',
  depositWithdrawTXAmount: 'Cantidad',
  depositWithdrawTXTxId: 'ID de Tx',
  depositWithdrawTXTxType: 'Tipo de transacción',
  depositWithdrawTXStatus: 'Estado',
  depositWithdrawTXNoData: 'Datos no disponibles.',
  depositTitle: 'Depósito',
  depositHistory: 'Historia',
  depositSubTitle: 'El usuario no puede depositar ni retirar moneda fiduciaria. ',
  depositCopyNotSupported: 'Su navegador no admite la copia automática al portapapeles',
  depositCopied: 'Dirección copiada.',
  depositAddressCreated: 'Dirección creada exitosamente.',
  depositGenerateAddress: 'Generar dirección',
  depositWarningText: 'NO deposite mediante transferencia entre cadenas',
  withdrawTitle: 'Retirar',
  withdrawSubTitle: 'El usuario no puede depositar ni retirar moneda fiduciaria. ',
  withdrawNoteDesc: 'Asegúrese de NO ingresar la dirección BEP2, BEP20 (BSC) como billetera de dirección de retiro de LTC',
  withdrawNoteSublist: ' NO realice retiros como transferencia entre cadenas',
  withdrawHistory: 'Historia',
  withdrawWithdrawBtn: 'Petición de retiro',
  withdrawFees: 'Cargo por retiro {{fees}} {{token}}',
  withdrawWithdrawPending: 'Su solicitud de retiro está pendiente. ',
  withdrawEnterFields: 'Ingrese el monto del retiro y la dirección de su billetera.',
  withdrawSelectWallet: 'Seleccione la billetera para retirar.',
  withdrawAmountFieldTitle: 'Importe (Mín. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Su {{wallet.currency.code}} DIRECCIÓN',
  withdrawAddressFieldPlaceholder: 'Escribe tu dirección',
  withdrawNoBalance: 'No hay suficiente equilibrio.',
  withdrawMinAmount: 'Por favor ingrese una cantidad mayor o igual a {{min}} .',
  fiatCurrencyInfo: 'El usuario no puede depositar ni retirar moneda fiduciaria. ',
  paginationNextBtn: 'Próximo',
  paginationPrevBtn: 'Anterior',
  wentWrong: 'Algo salió mal.',
  referralTitle: 'Remisión',
  referralSubTitle: 'Recomiende a sus amigos',
  referralEarn: ' Gana 150 INR cada uno',
  referralReferAndEarn: 'Recomiende y gane bonos por cada recomendación',
  referralReferPara: ' Si refieres este código a cualquier usuario',
  referralReferLink: 'Enlace de referencia',
  referralCopyReferralCode: 'Copiar código de referencia',
  referralTotalRewards: 'Recompensa total',
  TwoWayAuthentication: 'Autenticación bidireccional',
  LoginRequired: '¡Necesario iniciar sesión!',
  BetPlacedSuccessfully: 'Apuesta realizada con éxito',
  BetAddedInQueueSuccessfully: 'Apuesta agregada en la cola con éxito',
  BetRemovedFromQueueSuccessfully: 'Apuesta eliminada de la cola con éxito',
  BetCancelledSuccessfully: 'Apuesta cancelada con éxito',
  PlayerEscapedSuccessfully: 'El jugador escapó con éxito',
  CopiedSuccessfull: 'Copiado exitosamente',
  NotEnoughBalance: 'No hay suficiente equilibrio',
  AutoBetStarted: 'Apuesta automática iniciada ',
  AutoBetFinished: 'Apuesta automática finalizada ',
  IdCopied: 'ID copiado',
  usersConsent: 'Al acceder a este sitio, confirmo que tengo 18 años y he revisado',
  termsOfService: 'Términos de servicio',
  email: 'Correo electrónico',
  playNow: 'Reproducir ahora',
  orContinueWith: 'O continuar con',
  metamaskExtensionNotAvailable: 'Instale la extensión metaMask y actualice la página.',
  cashier: 'CAJERO',
  account: 'CUENTA',
  hello: 'Hola',
  balance: 'Balance',
  referrals: 'Referencias',
  settings: 'Ajustes',
  withdrawals: 'Retiros',
  demoWallet: 'Cartera de demostración',
  myAccount: 'Mi cuenta',
  wallet: 'Billetera',
  user: 'Usuario',
  USD: 'Dólar estadounidense',
  amountIsRequired: 'Se requiere cantidad',
  minDepositAmount: 'El monto del depósito debe ser mayor que {{min}}.',
  minEqualDepositAmount: 'El monto del depósito debe ser mayor o igual a {{min}}.',
  minMaxDepositAmount: 'El monto del depósito debe ser mínimo. {{min}} y maximo {{max}}.',
  maxDepositAmount: 'El monto del depósito debe ser menor que {{max}}.',
  minWithdrawAmount: 'El monto del retiro debe ser mayor que {{min}}',
  minEqualWithdrawAmount: 'El monto del retiro debe ser mayor o igual a {{min}}',
  withdrawMustLessThanWallet: 'El monto del retiro debe ser menor que el monto de su billetera',
  accountTypeIsRequired: 'Se requiere el tipo de cuenta.',
  mobileNumberLength: 'El número de móvil debe ser de {{length}} dígitos',
  phoneIsRequired: 'Se requiere teléfono',
  cpfError: 'El CPF debe ser de {{length}} caracteres',
  cpfRequiredError: 'Se requiere CPF',
  cnpjError: 'El CNPJ debe ser de {{length}} caracteres',
  mainMenu: 'MENÚ PRINCIPAL',
  casino: 'Casino',
  sports: 'Deportes',
  crypto: 'Cripto',
  NFT: 'NFT',
  poker: 'Póker',
  earnRewards: 'GANA RECOMPENSAS',
  rankUp: 'Subir de rango',
  inviteFriends: 'Invitar a amigos',
  partnerProgram: 'Programa de socios',
  home: 'Hogar',
  sportsbook: 'Apuestas deportivas',
  menu: 'Menú',
  liveBets: 'APUESTAS EN VIVO',
  allBets: 'Todas las apuestas',
  highRollers: 'Apostadores altos',
  luckyBets: 'Apuestas afortunadas',
  trade: 'Comercio',
  game: 'JUEGO',
  player: 'JUGADOR',
  time: 'TIEMPO',
  wager: 'APOSTAR',
  multiplierBan: 'MULTIPLICADOR',
  payoutText: 'PAGAR',
  bannerHeadOne: 'Experimente la verdadera innovación con la más alta',
  bannerHeadTwo: 'programa de recompensas dentro de la industria.',
  registerNow: 'Regístrate ahora',
  liveWins: 'GANANCIAS EN VIVO',
  monthly: 'METRO',
  weekly: 'W.',
  daily: 'D',
  clutchEcosystem: 'ecosistema de embrague',
  allGames: 'Lobby',
  allSports: 'Todos los deportes',
  cryptoFutures: 'futuros criptográficos',
  tradeNow: 'Negocia ahora',
  $clutch: '$embrague',
  allTables: 'Todas las tablas',
  nft: 'NFT',
  viewAll: 'Ver todo',
  rewards: 'RECOMPENSAS',
  profile: 'Perfil',
  clutchProfile: 'PERFIL DEL EMBRAGUE',
  totalBets: 'Apuestas totales',
  totalWagered: 'Total apostado',
  joinDate: 'Fecha de Ingreso',
  displaySocialLinks: 'Mostrar cuentas sociales vinculadas',
  privateProfile: 'Perfil privado',
  rank: 'Rango',
  nextRank: 'Siguiente rango',
  changeUsername: 'Cambie el nombre de usuario',
  newPassword: 'Nueva contraseña',
  changeName: 'Cambiar nombre',
  linkSocialAccount: 'Vincular cuenta social',
  privateProfileTooltip: 'Después de hacer que su perfil sea privado, otros usuarios no verán su nombre de usuario junto con las apuestas, carreras, etc.',
  socialLinksTooltip: 'Contenido no disponible',
  rankTooltip: 'Contenido no disponible',
  balances: 'saldos',
  createReferralBalance: 'Crear saldo de referencia',
  total: 'Total',
  switchBalance: 'Cambiar equilibrio',
  mainAccount: 'Cuenta principal',
  tips: 'Consejos',
  receivedAmount: 'Cantidad recibida',
  dateOrTime: 'Fecha y hora',
  view: 'Vista',
  allChains: 'Todas las cadenas',
  creditAmount: 'Monto de crédito',
  setCustomReferralCode: 'Establecer código de referencia personalizado',
  changeEmail: 'Cambiar e-mail',
  newEmail: 'Nuevo Email',
  createPassword: 'Crear contraseña',
  newUsername: 'Nuevo nombre de usuario',
  username: 'Nombre de usuario',
  changePassword: 'Cambiar la contraseña',
  twoFactorAuthentication: 'Autenticación de dos factores',
  twoFactorAuthenticationTitle: 'Se recomienda encarecidamente utilizar la autenticación de dos factores porque protege su cuenta tanto con su contraseña como con su teléfono.',
  twoFactorAuthenticationSubTitle: 'Se debe configurar un correo electrónico y una contraseña para activar 2FA. ',
  enable2fa: 'HABILITAR 2FA',
  verifyIdentityByKyc: 'Verifica tu identidad (kyc)',
  oldPassword: 'Contraseña anterior',
  live: 'Vivir',
  soccer: 'Fútbol',
  tennis: 'Tenis',
  fifa: 'FIFA',
  basketball: 'Baloncesto',
  iceHockey: 'hockey sobre hielo',
  volleyball: 'Vóleibol',
  tableTennis: 'Tenis de mesa',
  baseball: 'Béisbol',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boxeo',
  myBets: 'Mis apuestas',
  favourites: 'Favoritos',
  americanFootball: 'fútbol americano',
  buyCrypto: 'Comprar criptomonedas',
  oldEmail: 'Correo electrónico viejo',
  loggedInSuccess: 'Inicie sesión exitosamente',
  loggedOutSuccess: 'Cerró sesión exitosamente',
  wagerLimit: 'Límite de apuesta establecido correctamente',
  lossLimit: 'Límite de pérdida establecido correctamente',
  accountDisable: 'Cuenta deshabilitada exitosamente',
  sessionTimeout: 'El tiempo de espera de la sesión se estableció correctamente',
  signup: 'Regístrese exitosamente',
  depositLimit: 'Límite de depósito establecido correctamente',
  tokenVerify: 'El token ha sido enviado a su correo electrónico, verifique',
  emailVerify: 'El correo electrónico ha sido verificado.',
  emailChangedSuccess: 'El correo electrónico se ha cambiado correctamente.',
  profileUpdatedSuccess: 'Perfil actualizado',
  otpVerified: 'OTP verificada',
  twoFADeactivated: 'Autenticación 2FA desactivada',
  unAuthorized: 'No autorizado',
  dataNotFound: 'Datos no encontrados',
  setReferralCode: 'Establecer código de referencia...',
  ultimate: 'ÚLTIMO',
  notFound: 'Extraviado',
  casinoTransactions: 'Transacciones de casino',
  clutchOriginals: 'Originales de embrague',
  sportsbookTransactions: 'Transacciones de apuestas deportivas',
  status: 'Estado',
  success: 'Éxito',
  failed: 'Fallido',
  pending: 'Pendiente',
  date: 'Fecha',
  to: 'A',
  gameName: 'Nombre del juego',
  transactionId: 'ID de transacción',
  roundId: 'ID redondo',
  bet: 'Apuesta',
  win: 'Ganar',
  transactionType: 'tipo de transacción',
  at: 'en',
  downloadAll: 'Descargar todo',
  gameText: 'Juego',
  betId: 'ID de apuesta',
  gameId: 'ID del juego',
  actionType: 'tipo de acción',
  searchGames: 'Buscar juegos',
  provider: 'Proveedor',
  search: 'Buscar',
  sortBy: 'Ordenar por',
  popular: 'Popular',
  challengePool: 'Grupo de desafíos',
  loadMore: 'Carga más',
  bitcoin: 'bitcóin',
  chat: 'Charlar',
  trades: 'Vientos alisios',
  battles: 'Batallas',
  loginToChat: 'Inicia sesión para chatear',
  rules: 'Normas',
  chatRule1: 'No acoses ni insultes a otros usuarios',
  chatRule2: 'No supliques, pide préstamos, trivias o propinas',
  chatRule3: 'No insinúes que Clutch tiene malas intenciones ("sitio fraudulento", etc.)',
  chatRule4: 'No envíe spam ni publique enlaces que no sean de Clutch',
  chatRule5: 'No anuncie ningún tipo de servicios comerciales, de compra o venta.',
  chatRule6: 'No comparta ni anuncie su código de referencia',
  chatRule7: 'No solicite convertirse en miembro del personal.',
  chatRule8: 'solo inglés',
  chatRule9: 'Respeta a los mods, administradores y otros usuarios.',
  send: 'Enviar',
  expand: 'Expandir',
  signIn: 'Iniciar sesión',
  cross: 'Cruz',
  collapse: 'Colapsar',
  emailPlaceholder: 'Introduce tu dirección de correo electrónico',
  removeFromFavourite: 'Quitar de favoritos',
  addToFavourite: 'Agregar a favoritos',
  footerAboutSite: 'Copyright © 2022-2023 Gammastack.com es propiedad y está operado por HNA Gaming N.V., una empresa registrada y establecida bajo las leyes de Curazao. ',
  footerRightsReserved: '2023 Gammapila. ',
  signupFirstName: 'Nombre de pila',
  signupAddress: 'DIRECCIÓN',
  signupCity: 'Ciudad',
  signupPostcode: 'Código postal ',
  signupCounty: 'País',
  signupCurrency: 'Divisa',
  signupGender: 'Género',
  signupMan: 'Hombre',
  signupWomen: 'Mujer',
  signupOther: 'Otro',
  signupLoginDetails: 'Iniciar sesión en Detalles',
  signupPersonalDetails: 'Detalles personales',
  signupConfirm: 'Confirmar',
  signupPrivacyPolicy: 'He leído y acepto la Política de Privacidad',
  signupTermAndConditions: 'Acepto el tratamiento de mis datos personales. ',
  signupNewsLetter: 'Me gustaría recibir bonos, promociones, noticias u otra información relevante periódicamente mediante News Letter.',
  signupSms: 'Me gustaría recibir periódicamente por SMS bonos, promociones, noticias u otra información relevante.',
  initialHeaderContent: 'Consigue un increíble 100%',
  secoundaryHeaderContent: 'Bono de bienvenida de hasta $100',
  playNowButton: 'Reproducir ahora',
  registerButtonContent: 'Registro',
  results: 'Resultados',
  refresh: 'Actualizar',
  cancel: 'Cancelar',
  select: 'Seleccionar',
  current: 'Actual',
  set: 'Colocar',
  remove: 'Eliminar',
  save: 'Ahorrar',
  not: 'no',
  before: 'Antes',
  after: 'Después',
  action: 'Acción',
  withdrawal: 'Retiro',
  requested: 'Solicitado',
  withdraw: 'Retirar',
  areYouSure: 'Está seguro',
  yes: 'Sí',
  no: 'No',
  cancelled: 'Cancelado',
  approved: 'Aprobado',
  refunded: 'Reintegrado',
  chargeback: 'Contracargo',
  processedOn: 'Procesado el',
  transaction: 'Transacción',
  dateTime: 'Fecha y hora',
  more: 'Más',
  fromDate: 'Partir de la fecha',
  toDate: 'Hasta la fecha',
  percentage: 'Porcentaje',
  info: 'Información',
  min: 'mín.',
  max: 'máx.',
  change: 'Cambiar',
  continueToSite: 'Continuar al sitio',
  choose: 'Elegir',
  enterWithdrawalAmount: 'Elija o ingrese el monto de su retiro',
  capDeposit: 'DEPÓSITO',
  capWithdrawal: 'RETIRO',
  capAmount: 'CANTIDAD',
  minimum: 'Mínimo',
  required: 'Requerido',
  of: 'de',
  isRequired: 'se requiere',
  isAllowed: 'esta permitido',
  method: 'Método',
  capWithdraw: 'RETIRAR',
  showLess: 'Muestra menos',
  clearFilter: 'Filtro claro',
  quantity: 'Cantidad',
  reset: 'Reiniciar',
  incorrect: 'Incorrecto',
  load: 'Carga',
  applied: 'Aplicado',
  ok: 'DE ACUERDO',
  list: 'Lista',
  all: 'Todo',
  favorite: 'Favorito',
  endPointNotFound: 'Algo anda mal con la conexión de red',
  category: 'Categoría',
  seeAll: 'Ver todo',
  emailUserNameRequired: 'Correo electrónico/nombre de usuario requerido',
  emailUserNamePlaceHolder: 'Ingrese su correo electrónico o nombre de usuario',
  passwordRequired: 'Se requiere contraseña',
  newPasswordRequired: 'Se requiere una nueva contraseña',
  accountFrozen: 'Su cuenta está congelada hasta',
  resendEmail: 'Reenviar email',
  resendLink: 'REENVIAR ENLACE',
  userLoggedOut: 'Cerró sesión exitosamente',
  emailUserName: 'Ingrese su correo electrónico/nombre de usuario',
  enterYourEmail: 'Ingrese su dirección de correo electrónico',
  emailAlreadyRegistered: 'Este correo electrónico ya está registrado',
  userNameAlreadyTaken: 'Este nombre de usuario ya está en uso',
  fillAllFields: 'Complete todos los campos antes de pasar al siguiente paso',
  pleaseAccept: 'Por favor acepta',
  acceptPrivacyPolicy: 'política de privacidad',
  acceptTerms: 'Términos y Condiciones',
  modeOfComm: ' y modo de comunicación',
  beforeNextStep: 'antes de pasar al siguiente paso',
  userNamePlaceholder: 'Introduzca su nombre de usuario',
  min8Characters: 'Mínimo 8 caracteres',
  min1Number: 'Número mínimo 1',
  min1LowerCase: 'Mínimo 1 carácter en minúscula',
  min1UpperCase: 'Mínimo 1 carácter en mayúscula',
  min1SpecialChar: 'Mínimo 1 carácter especial',
  passwordTip: 'La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial',
  newPasswordTip: 'La nueva contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.',
  commonWordsNotAllowed: 'La contraseña no debe ser una palabra común como ("contraseña@123", "123456", "nombre de usuario") ni una secuencia fácil de adivinar',
  passwordPlaceholder: 'Introducir la contraseña',
  confirmPasswordPlaceholder: 'Ingrese Confirmar Contraseña',
  acceptAll: 'Aceptar todo',
  firstNamePlaceholder: 'Introduzca el nombre',
  lastNamePlaceholder: 'Introduzca el apellido',
  addressPlaceholder: 'Ingresa la direccion',
  cityPlaceholder: 'Ingrese la ciudad',
  postCodePlaceholder: 'Ingrese el código postal',
  phonePlaceholder: 'Ingresa número telefónico',
  invalidEmail: 'Correo electrónico no válido. Introduzca una dirección de correo electrónico válida, como ejemplo@ejemplo.com.',
  emailRequired: 'Correo electronico (requerido',
  confirmPasswordRequired: 'Confirmar contraseña requerida',
  firstNameRequired: 'Primer nombre (requerido)',
  lastNameRequired: 'Apellido Necesario',
  phoneRequired: 'Teléfono requerido',
  dateOfBirthRequired: 'Fecha de nacimiento requerida',
  genderRequired: 'Género requerido',
  userNameRequired: 'Nombre de usuario (requerido',
  addressRequired: 'Dirección requerida',
  cityRequired: 'Ciudad requerida',
  stateRequired: 'Estado requerido',
  countryRequired: 'País requerido',
  postCodeRequired: 'Código postal requerido',
  currencyCodeRequired: 'Código de moneda requerido',
  matchPassword: 'Las contraseñas no coinciden. ',
  fName3Chars: 'Su nombre debe tener entre 3 y 50 caracteres.',
  onlyAlphabet: 'Sólo se permiten alfabetos',
  lName3Chars: 'El apellido debe tener al menos 3 caracteres.',
  mustBe18: 'Debes tener 18 años para registrarte.',
  validDOB: 'Por favor ingresa una fecha de nacimiento valida',
  max20Characters: 'Máximo 20 caracteres permitidos',
  min3Characters: 'Mínimo 3 caracteres requeridos',
  max100Characters: 'Máximo 100 caracteres permitidos',
  max50Characters: 'Máximo 50 caracteres permitidos',
  invalidNumber: 'Número invalido',
  preferredLanguageRequired: 'Idioma preferido requerido',
  invalidPass: 'Contraseña invalida',
  prevBtn: 'Anterior',
  newPasswordPlaceholder: 'Ingrese nueva clave',
  currentPasswordPlaceholder: 'Introducir la contraseña actual',
  uploadImage: 'Cargar imagen',
  removeImage: 'Quita la imagen',
  preferredLanguage: 'Idioma preferido',
  wantToChangePass: '¿Quieres cambiar la contraseña?',
  selectCountry: 'Seleccionar país',
  selectPreferredLanguage: 'Seleccionar idioma preferido',
  fName50Chars: 'El nombre debe tener como máximo 50 caracteres.',
  lName50Chars: 'El apellido debe tener como máximo 50 caracteres.',
  invalidDate: 'Fecha invalida',
  DOBEarlyThanToday: 'La fecha de nacimiento debe ser anterior a la actual.',
  enterValidPhone: 'Por favor ingresa un número de teléfono válido',
  accountStatus: 'estado de la cuenta',
  kycVerified: 'Su KYC se verificó exitosamente.',
  denied: 'Denegado',
  chooseFile: 'Elija el archivo',
  upload: 'Subir',
  countryCantChange: 'Está a punto de iniciar la verificación KYC. ',
  proceed: 'Proceder',
  update: ' ',
  history: 'Historia',
  type: 'Tipo',
  amount: 'Cantidad',
  forfeited: 'perdido',
  expired: 'Venció',
  completed: 'Terminado',
  zeroedOut: 'Poner a cero',
  active: 'Activo',
  lapsed: 'Transcurrido',
  yourActiveBonus: 'Tus bonos activos',
  currentActiveBonus: 'Bonos activos actuales',
  bonusStatus: 'Estado',
  bonusWager: 'Apostar',
  bonusActivate: 'Activar',
  bonusForfeit: 'Pérdida de bonificación',
  notUsableBonus: 'En caso afirmativo, no podrá volver a utilizar este bono.',
  bonusLapsed: 'No tuviste suficientes pérdidas para obtener un reembolso. ',
  inProcess: 'En proceso',
  claiming: 'Reclamando',
  loyaltyPopover: 'Estos puntos de fidelidad se pueden utilizar para obtener reembolsos. ',
  loyaltyPage: 'Página de fidelización',
  loyaltyPerCurrency: 'Lealtad por moneda',
  maxLevelReached: 'Nivel máximo alcanzado',
  pointsToReach: 'puntos para alcanzar ',
  loyaltyBannerHeading: 'Te mereces nuestra nueva lealtad',
  loyaltyBannerDesc: 'Bono de bienvenida del 100% hasta $200',
  loyaltyHeadingOne: 'Siempre serás recompensado en Gammastack',
  loyaltyHeadingOneDesc: '¡En Gammastack, llevamos la lealtad a un nivel completamente nuevo de emoción, colmándote de recompensas en cada giro y vuelta! ',
  loyaltySubHeadOne: 'Ganar puntos de fidelidad',
  loyaltySubHeadOneDesc: 'Por cada 10€ en apuestas en efectivo realizadas en nuestro casino, ganarás 1 punto de fidelidad. ',
  loyaltySubHeadTwo: 'Subiendo la escalera de la lealtad',
  loyaltySubHeadTwoDesc: 'Cuantos más puntos acumule, más alto ascenderá en los niveles de fidelidad y desbloqueará recompensas aún más fantásticas a lo largo del camino. ',
  loyaltySubHeadThree: 'Sube de nivel y obtén recompensas ',
  loyaltySubHeadThreeDesc: 'Mejore su experiencia de juego progresando a través de nuestros atractivos niveles de recompensa, donde cada nivel desbloquea una emocionante variedad de giros gratis para maximizar sus posibilidades de ganar. ',
  loyaltyTestimonialHeadOne: 'EN Gammastack CASINO NOS ASEGURAMOS DE QUE',
  loyaltyTestimonialHeadTwo: 'Cada giro cuenta',
  loyaltyTestimonialDesc: '¡Cada giro que das en las tragamonedas no es solo por diversión, sino que también te acerca a recompensas realmente dulces! ',
  loyaltyPoints: 'Puntos',
  headerLevel: 'nivel',
  loyaltyCashback: 'Devolución de dinero',
  loyaltyBannerBtn: 'Verificar progreso',
  loyaltyHeadingTwo: 'Desbloquee la experiencia de juego definitiva: programa VIP Gammastack',
  loyaltyHeadingTwoDesc: 'Una vez que alcance el nivel 6 en nuestro programa de fidelización, desbloqueará un mundo de privilegios exclusivos como miembro del prestigioso programa VIP Gammastack. ',
  loyaltySubHeadFour: 'Límites de depósito y retiro más altos',
  loyaltySubHeadFourDesc: 'Disfrute de la flexibilidad de mayores límites de depósito y retiro como miembro VIP.',
  loyaltySubHeadFive: 'Bonos y promociones exclusivos',
  loyaltySubHeadFiveDesc: 'Obtenga acceso a bonos y promociones especiales hechos a medida para nuestros jugadores VIP',
  loyaltySubHeadSix: 'Administrador de cuentas personales',
  loyaltySubHeadSixDesc: 'Reciba soporte dedicado y asistencia personalizada de su administrador de cuentas.',
  loyaltySubHeadSeven: 'Tiempos de retiro más rápidos',
  loyaltySubHeadSevenDesc: 'Como VIP, disfrutará de tiempos de retiro más rápidos, lo que le permitirá acceder a sus ganancias aún más rápido.',
  loyaltySubHeadEight: 'Recompensas de reembolso mensuales',
  loyaltySubHeadEightDesc: 'Aumente sus fondos con generosas recompensas de reembolso mensuales exclusivamente para nuestros miembros VIP.',
  loyaltySubHeadNine: 'Invitaciones a eventos y torneos exclusivos',
  loyaltySubHeadNineDesc: 'Obtenga codiciadas invitaciones a eventos y torneos exclusivos, donde podrá socializar con otros VIP y competir por premios increíbles.',
  loyaltySubHeadTen: 'Bonos de cumpleaños',
  loyaltySubHeadTenDesc: '¡Celebra tu día especial con estilo con un delicioso bono de cumpleaños solo para ti!',
  loyaltySubHeadEleven: 'Regalos de lujo',
  loyaltySubHeadElevenDesc: 'Disfrute de una exquisita selección de obsequios de lujo cuidadosamente seleccionados para recompensar a nuestros jugadores más valiosos.',
  loyaltyTableHeading: 'Descripción general de niveles y recompensas',
  loyaltyTableHeaderOne: 'Nivel de lealtad',
  loyaltyTableHeaderTwo: 'Puntos de lealtad',
  loyaltyTableHeaderThree: 'Reembolso diario',
  promReadMore: 'Leer más',
  currentPortalBlock: 'si desea bloquear su cuenta solo para este portal.',
  allPortalBlock: 'para bloquear su cuenta en todos los portales.',
  limit24Reset: 'Una vez que establezca los límites de apuesta, pérdida y depósito, podrá editarlos y eliminarlos después de 24 horas, pero los límites se pueden reducir inmediatamente.',
  enterAmount: 'Ingrese el monto',
  limit: 'Límite',
  loss: 'Pérdida',
  takeABreak: 'Tomar un descanso',
  session: 'Sesión',
  selfExclusion: 'Autoexclusión',
  used: 'Usado',
  edit: 'Editar',
  updatedAt: 'Actualizado en',
  cannotBeRemoved: 'No se puede eliminar antes.',
  timePeriod: 'Periodo de tiempo',
  custom: 'Costumbre',
  hours: 'Horas',
  days: 'Días',
  timePeriodPlaceholder: 'Número de días',
  months: 'Meses',
  permanent: 'Permanente',
  onlyNumbers: 'Sólo se permiten números',
  notNumbers: 'Números no permitidos',
  noSpaceAllow: 'Sapce no está permitido',
  userNameAllowed: 'Debe comenzar con el alfabeto. ',
  timePeriodRequired: 'Período de tiempo requerido',
  cannotBeIncreased: 'No se puede aumentar antes',
  amountGTZero: 'La cantidad debe ser mayor que 0',
  amountEqualOrLess: 'La cantidad debe ser igual o menor que la semanal y la mensual.',
  amountInBetween: 'La cantidad debe estar entre diaria y mensual (inclusive)',
  amountEqualOIrGreater: 'La cantidad debe ser igual o mayor que la diaria y la mensual.',
  limitRemoved: 'Se eliminará su siguiente límite.',
  limitSet24Hrs: 'Está a punto de establecer el siguiente límite en su cuenta. ',
  takeABreakInfo: 'Está a punto de bloquear el acceso a su cuenta por un período de tiempo preestablecido. ',
  sessionLimitInfo: 'Está a punto de establecer el siguiente límite en su cuenta. ',
  limitCantSetBefore: 'El límite de su sesión no se puede establecer antes',
  selfExclusionInfo: 'Está a punto de bloquear el acceso a su cuenta por un período de tiempo preestablecido. ',
  quickSearch: 'Búsqueda rápida',
  cash: 'Dinero',
  nonCash: 'No en efectivo',
  userDetail: 'Detalle del usuario',
  gameIdentifier: 'Identificador de juego',
  rollback: 'Retroceder',
  rollbackBeforeBetWin: 'Revertir antes de ganar la apuesta',
  freeSpins: 'Tiradas gratis',
  betInternal: 'Apuesta Interna',
  winInternal: 'Ganar interno',
  addMoney: 'Agregar dinero',
  removeMoney: 'Quitar dinero',
  addMoneyInternal: 'Agregar dinero interno',
  removeMoneyInternal: 'Quitar dinero interno',
  depositInternal: 'Depósito Interno',
  withdrawInternal: 'Retirar Interno',
  promotionTitle: 'Título de la promoción',
  cancelWithdrawRequest: 'Quieres cancelar esta solicitud',
  bankname: 'Nombre del banco',
  selectBank: 'Seleccione su banco',
  accountNumber: 'Número de cuenta',
  enterAccountNumber: 'Ingrese el número de cuenta',
  rowsPerPage: 'Filas por página',
  availedBonus: 'Ya tienes un bono activo. ',
  capForfeit: 'PERDER',
  itFirst: 'primero.',
  selectYourBonus: 'Seleccione su bono',
  skip: 'SALTAR',
  with: 'con',
  without: 'sin',
  selectPaymentMethod: 'Por favor elige tu método de pago',
  paymentMethod: 'Método de pago',
  selectPaymentmethod: 'Seleccione método de pago',
  mobileNo: 'Número de teléfono móvil',
  enterMobileNo: 'Introduce el número de móvil',
  volatility: 'Volatilidad',
  paylines: 'Líneas de pago',
  theme: 'Tema',
  tryForFree: 'Prueba gratis',
  resultsFound: 'Resultados encontrados',
  games: 'Juegos',
  in: 'en',
  low: 'Bajo',
  medium: 'Medio',
  high: 'Alto',
  'medium-high': 'Altura media',
  'very-high': 'Muy alto',
  'low-medium': 'Medio bajo',
  fantasy: 'Fantasía',
  ancient_civilizations: 'Civilizaciones antiguas',
  fruits: 'frutas',
  africa: 'África',
  military: 'Militar',
  joker: 'bufón',
  asia: 'Asia',
  luxurylife: 'Vida lujosa',
  underwater_world: 'Mundo submarino',
  book_off: 'Reserva',
  western: 'occidental',
  retro: 'Retro',
  egypt: 'Egipto',
  party: 'Fiesta',
  st_patrick_day: 'dia de San Patricio',
  space: 'Espacio',
  easter: 'Pascua de Resurrección',
  girls: 'Chicas',
  branded: 'De marca',
  x_mas_and_new_year: 'navidad y año nuevo',
  horrors: 'Horrores',
  other: 'Otro',
  loginFirst: 'Inicie sesión primero para agregar el juego a favorito',
  loginFirstPlay: 'Inicie sesión primero para jugar este juego',
  sport: 'Deporte',
  pirates: 'Piratas',
  sweets: 'dulces',
  luxury_life: 'Vida lujosa',
  st_valentines_day: 'Día de San Valentín',
  halloween: 'Víspera de Todos los Santos',
  food: 'Alimento',
  lobby: 'vestíbulo',
  favourite: 'Favorito',
  playNGO: 'Juega y ve',
  loyaltySystem: 'Sistema de fidelización',
  progressionSystem: 'Sistema de progresión',
  startPoint: 'Punto de partida',
  endPoint: 'Punto final',
  loyaltyBonus: 'Bono de lealtad',
  pageRemoved: 'Esta página no existe o fue eliminada',
  suggestToBackHome: 'Te sugerimos volver a casa.',
  backToHome: 'De vuelta a casa',
  goHome: 'Vete a casa',
  oopps: 'Ups',
  somethingNotWorking: 'Lo sentimos, ¡algo no funciona aquí!',
  tournament: 'Torneo',
  none: 'Ninguno',
  validOnDays: 'Válido en días',
  validTill: 'Válida hasta',
  yourRequest: 'Tu petición',
  withdrawalRequestPending: 'Tiene una solicitud de retiro en estado pendiente. ',
  realBalance: 'Saldo real',
  bonusInCasino: 'Bono en casino',
  bonusSelected: 'Bono seleccionado',
  payWith: 'Pagar con',
  reached: 'Alcanzó',
  receiptOfYour: 'recibo de su',
  capEmail: 'CORREO ELECTRÓNICO',
  capCreditCard: 'NÚMERO DE TARJETA DE CRÉDITO',
  capExpiry: 'EXPIRACIÓN',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY no tiene el formato correcto',
  min13Numbers: 'Mínimo 13 números requeridos',
  max19Numbers: 'Máximo 19 números permitidos',
  min3Numbers: 'Mínimo 3 números requeridos',
  max4Numbers: 'Máximo 4 números permitidos',
  Other: 'Otro',
  forfeit: 'Perder',
  withdrawRequestCreated: 'Solicitud de retiro creada exitosamente',
  noBetHistory: 'No se encontró ningún historial de apuestas',
  noPromotions: 'No se encontraron promociones',
  noBonuses: 'No se encontraron bonos',
  noHistory: 'No se encontró ningún historial',
  noWithdrawalHistory: 'No se encontró historial de retiros',
  noCMS: 'No se encontró ningún CMS',
  noProvidersFound: 'No se encontraron proveedores',
  noDataFound: 'Datos no encontrados',
  countryBlocked: 'PAÍS BLOQUEADO',
  forbidden: 'PROHIBIDO',
  notAcceptingVisitors: 'Lamentamos profundamente esto, pero shinywilds.com no está disponible en su país.',
  contactUsAt: 'Si cree que esto se le mostró por error, comuníquese con nuestro soporte por correo electrónico en',
  siteUnderMaintenance: 'Sitio en mantenimiento',
  weWillBeRightBack: '¡Ya volvemos!',
  serviceUnavailable: 'Nuestro servicio no está disponible temporalmente. ',
  tryAgainLater: 'Por favor, inténtelo de nuevo más tarde.',
  checkYourEmail: 'Consultar su correo electrónico',
  sentAVerification: 'Hemos enviado un enlace de verificación.',
  toYourEmail: 'a tu correo electrónico.',
  backToLogin: 'Atrás para iniciar sesión',
  goToLogin: 'Ir a iniciar sesión',
  accountVerified: 'Cuenta verificada',
  yourAccountVerified: 'Tu cuenta ha sido verificada',
  successfully: 'Exitosamente',
  verificationFailed: 'Fallo en la verificación',
  emailFailedContact: 'Error de verificación. Si esto sucede constantemente, comuníquese con ',
  setNewPass: 'Establecer una nueva contraseña',
  passResetLink: 'Hemos enviado un enlace para restablecer la contraseña a',
  toYourAccount: 'A tu cuenta',
  sendPasswordResetLink: 'Solicitud para restablecer la contraseña',
  gamePlayWagerComplete: 'Enhorabuena, ha completado con éxito las apuestas y el dinero del bono ganado es mayor que el monto máximo permitido. ',
  real: 'Real',
  demo: 'Manifestación',
  money: 'Dinero',
  formatRquired: 'Se requiere formato .png, .jpg, .jpeg',
  chooseImage: 'Por favor elige una imagen',
  imageSize3MB: 'Ingrese una imagen con un tamaño inferior a 3 MB',
  noProfileImage: 'No hay imagen de perfil disponible para eliminar',
  limitSetSuccess: 'límite establecido con éxito',
  accountDisabled: 'Cuenta deshabilitada exitosamente',
  loginSuccess: 'Inicio de sesión correcto',
  signupSuccess: 'Registrarse exitosamente',
  linkExpired: 'Enlace expirado',
  linkSent: 'Enlace enviado',
  accAlreadyVerified: 'Cuenta ya verificada. ',
  profileImage: 'Imagen de perfil',
  profileImageRemoved: 'Imagen de perfil eliminada correctamente',
  docs: 'Documentos',
  updatedSuccess: 'Actualizado con éxito',
  MATCH: 'FÓSFORO',
  BALANCE: 'BALANCE',
  FREESPINS: 'TIRADAS GRATIS',
  MATCH_1: 'FÓSFORO',
  DEPOSIT: 'DEPÓSITO',
  WAGERING: 'APUESTAS',
  PROMOTION: 'PROMOCIÓN',
  CREDITCARD: 'TARJETA DE CRÉDITO',
  WALLET: 'BILLETERA',
  CRYPTO: 'CRIPTO',
  OTHER: 'OTRO',
  INSTANTBANKING: 'BANCA INSTANTÁNEA',
  cookieTitle: '¡Galletas!',
  declineButton: 'Rechazar',
  acceptButton: 'Aceptar',
  cookieDeclinedMessage: 'Para obtener la mejor experiencia posible, se deben aceptar cookies para continuar usando este sitio web. ',
  cookieDescription: '¡Bienvenido a Shinywilds.com! ',
  forfeitMessageFirst: 'Actualmente tienes el siguiente bono activo:',
  forfeitMessageSecond: 'Debes perder este bono antes de reclamar uno nuevo.',
  forfeitButton: 'Perder',
  pleaseVerifyFirstText: 'Para poder solicitar un retiro, primero debemos verificar su dirección de correo electrónico. ',
  clickHere: 'Haga clic aquí',
  pleaseVerifyLastText: 'para que podamos reenviarle el correo electrónico de verificación y continuar.',
  pleseWait: 'Por favor espera',
  toResend: 'para reenviar el enlace',
  notVerified: 'Debes verificar tu dirección de correo electrónico para retirar dinero.',
  verification: 'Verificación',
  UPLOAD_IMG_MSG: 'Arrastre y suelte archivos aquí o haga clic para seleccionar archivos',
  addressIsRequired: 'La dirección es necesaria',
  withdrawRequestSent: 'Solicitud de retiro enviada al administrador. ¡Espere la aprobación!',
  withdrawAddressCannotBeSame: 'La dirección de retiro no puede ser la misma que la suya',
  pasteYourCurrencyAddressHere: 'Pega tu {{currency}} dirección de billetera aquí...',
  noGamesAvailable: 'No hay juegos disponibles',
  or: 'o',
  copyPasteNot: 'copiar/pegar no está permitido',
  selectDob: 'Selecciona fecha de nacimiento',
  iAgreeWith: 'Estoy de acuerdo con el acuerdo de usuario y confirmo que tengo al menos 18 años.',
  iAgreeto: 'estoy de acuerdo con ',
  alreadyHave: '¿Ya tienes una cuenta?',
  kyc: 'KYC',
  changePicture: 'Cambiar imagen',
  selectGender: 'Seleccione género',
  male: 'Masculino',
  female: 'Femenino',
  zipCode: 'Código postal',
  selectCurrency: 'Seleccione su moneda',
  bannerTitle: 'Casino increíble',
  bannerSubTitle: 'A SOLO UN CLIC',
  address: 'DIRECCIÓN',
  streetAddress: 'Dirección',
  signupAddressState: 'Estado',
  onlyNumberAllowed: '{{fieldName}} debe contener solo números',
  profileImageSizeFormat: 'Permitir un tamaño de imagen inferior a {{imageSize}} y formato {{imageFormat}}',
  fileSizeFormat: 'Permitir un tamaño de archivo inferior a {{imageSize}} y formatear {{imageFormat}}', ///
  comingNewUpdate: 'Estamos trabajando en algo emocionante. ',
  ooops: 'Ups...',
  redirectingMessage: 'Redireccionando en {{redirectTime}} segundos...',
  logIn: 'Acceso',
  signUp: 'Inscribirse',
  Username: 'Nombre de usuario',
  day: 'día',
  month: 'mes',
  year: 'año',
  forgetPassword: 'Contraseña olvidada',
  verifyEmail: 'Verificar correo electrónico',
  loggedInUser: 'Usuario registrado',
  favorites: 'Favoritos',
  liveGames: 'Juegos en vivo',
  videoSlots: 'Tragamonedas de vídeo',
  beforeAmount: 'Antes cantidad',
  startDateCanNotGreterThanEnd: 'la fecha de inicio no puede ser mayor que la fecha de finalización',
  Wallet: 'Billetera',
  Casino: 'Casino',
  affiliate: 'Afiliado',
  affiliateTitle: 'programa de afiliación',
  affiliateDescription: ' ¡Recomiende nuestra marca y gane dinero participando en el programa de afiliados de Deuces! ',
  affiliateButtonText: 'Conviértete en afiliado',
  refferelLink: 'Enlace de referencia',
  affiliateLinkCopy: 'Enlace copiado correctamente',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  referral: 'Remisión',
  referralCode: 'código de referencia',
  requiredReferralCode: 'Se requiere código de referencia para aplicar',
  referralProgram: 'Programa de referencia',
  overview: 'Descripción general',
  referralDetails: 'Detalles de referencia',
  referralIncentive: '¡Recomiende nuestra marca y gane dinero participando en el Programa de recomendación de Deuces! ',
  copyTitleMessage: 'por favor copie {{title}} Aplicar',
  referralCopiedMessage: 'Código de referencia copiado correctamente',
  isReferralCode: 'Código de referencia (opcional)',
  greaterThan18years: '¡Y confirme que tengo al menos 18 años!',
  userId: 'ID de usuario',
  totalCommissionsRewards: 'Recompensas de comisiones totales',
  registrationDate: 'Fecha de Registro',
  isReferralCheckbox: 'Deu al código de afiliado, no se puede aplicar el código de referencia ',
  notAvailable: 'N / A',
  serialNumber: 'SN',
  searchBy: 'Búsqueda por {{searchableKeyword}}',
  name: 'Nombre',
  noDataAvailable: 'Datos no disponibles',
  reloadPage: 'Recargar página',
  commission: 'Comisión',
  promotions: 'Promociones',
  sponsorships: 'Patrocinios',
  refreshYourPage: 'Actualiza tu página',
  latestPromotions: 'Últimas promociones',
  promotionsNotAvailable: 'En este momento los detalles de las promociones no están disponibles. ',
  luckyWinners: 'Ganadores afortunados',
  favoriteGames: 'Juegos favoritos',
  gameProviders: 'Proveedores de juegos',
  affiliateEligibleDescription: 'No es elegible para convertirse en afiliado; complete KYC hasta el nivel 2 para ser elegible como afiliado',
  moveOnKycPage: 'para pasar a la página KYC.',
  docDownload: 'Haga clic aquí para descargar el documento cargado',
  documentUploadedSuccess: 'Documento cargado exitosamente',
  uploadFile: 'Subir archivo',
  completeKycLevel: 'Por favor completa el nivel  {{kycLevel}} Verificación KYC primero',
  leadingCasinoTitle: 'Casino criptográfico en línea líder',
  leadingCasinoDescription: 'Descubra emociones infinitas con la amplia colección de juegos de casino de Deuces. ',
  casinoButtonTitle: 'ir al casino',
  leadingSportsTitle: 'Las mejores apuestas deportivas criptográficas en línea',
  leadingSportsDescription: 'Experimente la emoción de las apuestas deportivas con Deuces. ',
  sportsButtonTitle: 'Ir a apuestas deportivas',
  welcomeBack: 'Bienvenido de nuevo, {{userName}}',
  playSmarter: 'Juega más inteligentemente',
  loginNow: 'Inicia sesión ahora',
  yourKYCProgress: 'Su {{value}} Progreso',
  copiedMessage: '{{title}} Copiado correctamente',
  depositAddress: 'Dirección de depósito',
  emailVerified: 'Correo Electrónico Verificado',
  verify: 'Verificado',
  notVerify: 'No verificar',
  winAmount: 'Cantidad ganada',
  referralAmountMessage: 'El monto de la referencia solo se transferirá si se verifica el correo electrónico del usuario registrado.',
  vipClub: 'club vip',
  bronze: 'Bronce',
  silver: 'Plata',
  gold: 'Oro',
  platinum: 'Platino',
  vipRankingMsg: 'Sistema de clasificación VIP Deuces',
  level1: 'nivel 1',
  level2: 'nivel 2',
  level3: 'nivel 3',
  level4: 'nivel 4',
  level5: 'nivel 5',
  casinoLevelMessage: 'Casino - Monto de la apuesta {{amount}}',
  sportsLevelMessage: 'Deportes - Monto de la apuesta {{amount}}',
  level5message: 'El jugador puede alcanzar este nivel solo proporcionado por el superadministrador.',
  totalReferredAmount: 'Monto total referido {{amount}}',
  invalidReferralCode: 'Código de referencia no válido',
  wantToLogin: '¿Estás seguro de que deseas iniciar sesión?',
  totalWager: 'Apuesta Total',
  totalCommissionRewards: 'Recompensas de comisión total',
  noGamesFound: 'No se han encontrado juegos',
  bonusReferral: 'Referencia adicional',
  affiliateCode: 'Código de afiliación',
  bonus: 'Prima',
  latestBonus: 'Último bono',
  bonusNotAvailable: 'En este momento los detalles del bono no están disponibles',
  lose: 'Perder',
  genderInvalid: 'El tipo de género debe ser solo masculino o femenino.',
  walletSettings: 'Configuración de billetera',
  yearShouldBeBetween: 'El año seleccionado debe estar entre {{from}} - {{to}}',
  accessingSiteWarning: 'Al acceder a este sitio, doy fe de que tengo al menos 18 años y he leído y acepto los Términos de servicio.',
  rake_Back: '{{percent}} RASTRILLO DE NUEVO',
  logoutSuccess: 'cerrar sesión exitosamente',
  registerSuccess: 'Registro exitoso',
  forgetPasswordEmailVerifyMsg: 'Correo de verificación enviado exitosamente a su correo electrónico registrado, el correo electrónico de restablecimiento de contraseña es válido para {{time}} solo',
  mailSentSuccess: 'El correo enviado con éxito',
  passwordChanged: 'Contraseña cambiada',
  passwordResetLogin: 'Su contraseña cambió exitosamente, ¿Quiere iniciar sesión?',
  passwordChangedSuccess: 'Su contraseña cambió exitosamente',
  profileUpdate: 'perfil actualizado con éxito',
  invalidCredentials: 'Credenciales no válidas',
  emailAlreadyExists: 'el Email ya existe',
  usernameAlreadyExists: 'nombre de usuario ya existe',
  emailNotExists: 'El correo electrónico no existe',
  resetPassInvalidToken: 'El enlace para restablecer su contraseña ha caducado, verifique el correo electrónico nuevamente',
  IncorrectCurrentPassword: 'La contraseña actual es incorrecta',
  formatIsRequired: '{{formatTypes}} se requiere formato',
  anErrorOccurs: 'Se produce un error, por favor inténtelo de nuevo.',
  imageUploaded: 'Imagen cargada exitosamente',
  insufficientBalance: 'Saldo insuficiente',
  withdrawApprovalReqMsg: 'La solicitud de retiro se envió correctamente!',
  hideZeroBalancesTitle: 'Ocultar saldos cero',
  hideZeroBalancesSubTitle: 'Tus saldos cero no aparecerán en tu billetera',
  displayCryptoInFiatTitle: 'Mostrar criptografía en fiat',
  displayCryptoInFiatSubTitle: 'Todas las apuestas',
  documentsAlreadyUploaded: 'Documento ya subido',
  documentsUploadedSuccessfully: 'Documento cargado exitosamente',
  gameAddedtoFavourite: 'Juego agregado a favoritos exitosamente',
  gameRemovedFromFavourite: 'Juego eliminado de favoritos con éxito',
  favoriteGameExists: 'El juego favorito ya existe',
  favoriteGameNotFound: 'Juego favorito no encontrado',
  internalServerError: 'Error interno del servidor, por favor contacte con el administrador',
  realPlay: 'Juego real',
  funPlay: 'juego divertido',
  gameNotActive: 'El juego no está activo.',
  captcha: 'Este sitio está protegido por reCAPTCHA y se aplican la Política de privacidad y los Términos de servicio de Google.',
  signUpAndGet: 'Regístrese y obtenga',
  rakeDisc : '50% DE RAKE BACK',
  bannerFooter: 'Al acceder a este sitio, doy fe de que tengo al menos 18 años y he leído y acepto los Términos de servicio.',
  DeucesOriginal: 'Deuces Originales',
  Promotion: 'Promoción',
  orLoginWith: 'o inicia sesión con',
  RegisterAnAccount: 'registrar una cuenta',
  MakeADepositAndPlay: 'Haz un depósito y juega',
  ReceiveRakeBack: 'RECIBE 50% DE RAKEBACK',

  // Privacy
  privacy: 'Privacidad',
  ghostMode: 'Modo fantasma',
  ghostModeOn: 'Modo fantasma activado',
  ghostModeOff: 'Modo fantasma desactivado',
  enableGhostMode: 'Habilitar el modo fantasma',
  yourBetsWontAppear: 'Tus apuestas no aparecerán en el feed público de apuestas ni en la vista previa de apuestas',

  // New
  myBonuses: 'Mis Bonos',
  bonus: 'Bono',
  claim: 'Reclamación',
  claimedBonuses: 'Bonos reclamados',
  bonusAmount: 'Monto del bono',
  wageredAmount: 'Monto apostado',
  amountToWager: 'Monto a apostar',
  claimedDate: 'Fecha de reclamo',
  expireAt: 'Caduca en',
  currency: 'Moneda',
  freeSpinsQty: 'Giros gratis',
  validity: 'Validez',
  description: 'Descripción',
  termsAndConditions: 'Términos y condiciones',
  claimed: 'Reclamado',
  currencies: 'Monedas',
  participationGames: 'Juegos participantes',
  userGroups: 'Grupos de usuarios',
  maxBonusClaimed: 'Máximo bono reclamado',
  minimumDepositAmount: 'Monto mínimo de depósito',
  percentOrAmount: 'Porcentaje / Monto',
  amountType: 'Tipo de monto',
  minimumBet: 'Apuesta mínima',
  turnoverCoefficient: 'Coeficiente de rotación',
  claimedCount: 'Cantidad reclamada',
  note: 'Nota',
  cancelable: 'El usuario puede cancelar este bono en cualquier momento',
  nonCancelable: 'Una vez reclamado, el usuario no puede cancelar este bono',
  to: 'a',
  wageringMultiplier: 'Multiplicador de apuesta',
  bonusType: 'Tipo de uso del bono',

  newReleases: 'Nuevas Versiones',
  deucesOriginals: 'Deuces Originales',
  providers: 'Proveedores',
}
