import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  casinoProviderList: null,
  casinoProviderObj: null,
  casinoBanners: null,
  loginBanners: null,
  generalData: null,
  selectedCategoryId: null,
}

const {
  actions: {
    // setLiveData,
    setCasinoBanners,
    setloginBanners,
    setCasinoProviderListData,
    getGeneralDataSuccess
  },
  reducer
} = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    setCasinoProviderListData: (state, action) => {
      let providerObj = {};
      action?.payload?.forEach((item) => providerObj[item?.id] = item?.name );
      return {
      ...state,
      casinoProviderList: action.payload,
      casinoProviderObj: providerObj,
    }},
    setCasinoBanners: (state, { payload }) => ({
      ...state,
      casinoBanners: payload
    }),
    setloginBanners: (state, { payload }) => ({
      ...state,
      loginBanners: payload
    }),
    getGeneralDataSuccess: (state, { payload }) => {
      return ({
        ...state,
        generalDataLoading: false,
        generalData: payload,
        selectedCategoryId: payload?.casinoCategories?.rows[0]?.id  ,
      })
    },

  },
  extraReducers: (builder) => {

  }
})

export {
  // setLiveData,
  setCasinoBanners,
  setCasinoProviderListData,
  setloginBanners,
  getGeneralDataSuccess
}
export default reducer
