import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  systemGameData: null,
  allCurrencies: [],
  allCountries: [],
  gameSettings: [],
  banner: [],
  gameSound: false,
  gameMusic: false,
  showLoginPopup: false,
  showSignupPopup: false,
  affilateCode: null,
  homeBanner: [],
  gameThemeSetting: null,
  encourageSettings: [],
  isDemoGame: false,
  demoWallet: 3000,
  gameIframeLink: null,
  evolutionGameIframe: null,
  tenantDetails: null,
  cmsSiteData: [],
  cmsData: []
}

const {
  actions: {
    setAllCurrency,
    setSystemData,
    setGameSettings,
    setMusicSound,
    setGameSound,
    setShowLoginPopup,
    setShowSignupPopup,
    setAffilateCode,
    setNewSeed,
    setIsDemoGame,
    setDemoWallet,
    setLanguages,
    setAllCountries,
    setRegistrationFields,
    setCmsSiteData
  },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setSystemData: (state, action) => ({
      ...state,
      cmsData: action.payload
    }),
    setCmsSiteData: (state, action) => ({
      ...state,
      cmsSiteData: action.payload
    }),
    setGameSettings: (state, action) => ({
      ...state,
      gameSettings: action.payload
    }),
    setShowLoginPopup: (state, action) => ({
      ...state,
      showLoginPopup: action.payload
    }),
    setShowSignupPopup: (state, action) => ({
      ...state,
      showSignupPopup: action.payload
    }),
    setAffilateCode: (state, action) => ({
      ...state,
      affilateCode: action.payload
    }),
    setAllCurrency: (state, action) => ({
      ...state,
      allCurrencies: action.payload
    }),
    setRegistrationFields: (state, action) => ({
      ...state,
      regFields: action.payload
    }),
    setAllCountries: (state, action) => ({
      ...state,
      allCountries: action.payload
    }),
    setLanguages: (state, action) => ({
      ...state,
      languages: action.payload
    })
  },
  extraReducers: (builder) => {

  }
})

export default reducer
export {
  setAllCurrency,
  setSystemData,
  setGameSettings,
  setGameSound,
  setMusicSound,
  setShowLoginPopup,
  setShowSignupPopup,
  setAffilateCode,
  setNewSeed,
  setIsDemoGame,
  setDemoWallet,
  setLanguages,
  setAllCountries,
  setRegistrationFields,
  setCmsSiteData
}
