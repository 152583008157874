import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography, useTheme } from "@mui/material";
import { clsx } from "clsx";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setToaster } from "redux-thunk/redux/slices/settings.slice";
import { ToasterWrapper } from "./style";

const Toaster = ({ message, type }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const toastCloseHandler = () => {
    dispatch(setToaster({ isVisible: false }));
  };

  const title = useMemo(() => {
    switch (type) {
      case "error":
        return "Error";
      case "success":
        return "Success";
      case "info":
        return "Info";
      default:
        return "Info";
    }
  }, [type]);

  return (
    <ToasterWrapper theme={theme}>
      <div className={clsx("toasterSection", type ?? "info")}>
        <Grid>
          <Typography className="title">{title}</Typography>
          <div className="close">
            <CloseIcon onClick={toastCloseHandler} />
          </div>
        </Grid>
        <Typography>{message}</Typography>
      </div>
    </ToasterWrapper>
  );
};

export default Toaster;
