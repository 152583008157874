import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { StyledButton, StyledContainer } from './style'
import { Typography } from '../../../../node_modules/@mui/material/index'
class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (_error) {
    return { hasError: true }
  }

  handleReloadPage = () => {
    window.location.reload()
  };

  render () {
    const { errorBoundaryMessage } = this.props
    if (this.state.hasError) {
      return (
        <StyledContainer>
          <Typography variant='h2' color='primary' gutterBottom>
            {errorBoundaryMessage?.title}
          </Typography>
          <Typography variant='h5' color='#fff' paragraph sx={{ mt: 3 }}>
            {errorBoundaryMessage?.subtitle}
          </Typography>
          <StyledButton variant='contained' color='primary' sx={{ background: '#4caf50' }} onClick={this.handleReloadPage}>
            {errorBoundaryMessage?.buttonTitle}
          </StyledButton>

        </StyledContainer>
      )
    }

    return this.props.children
  }
}

export default withTranslation('home')(ErrorBoundary)
