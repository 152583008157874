import { createAsyncThunk } from '@reduxjs/toolkit'
import { activateBonusService, activateWageringService, availBonusService, cancelBonusService, forfeitBonusService, getAllBonusService, getBonusDetailsService, getBonusService, getLoyaltyLevelService, getPromotionsService, getUserBonusService } from 'network/services/bonus.service'
import { getLoyaltyLevelStart, setUserBonus } from 'redux-thunk/redux/slices/bonus.slice'
export const getAllBonus = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const activateWagering = createAsyncThunk(
  'activate-bonus/wagering',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await activateWageringService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const forfeitBonus = createAsyncThunk(
  'forfiet/bonus',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await forfeitBonusService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getUserBonus = createAsyncThunk(
  'fetch/user-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getUserBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getLoyaltyLevel = createAsyncThunk(
  'fetch/loyalty-level',
  async (_, thunkApi) => {
    try {
      const res = await getLoyaltyLevelService()
      thunkApi.dispatch(getLoyaltyLevelStart(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getBonusDetails = createAsyncThunk(
  'fetch/bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getBonusDetailsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const clearBonusDetails = createAsyncThunk(
  'clear/bonus-details',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setUserBonus(null))
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getPromotions = createAsyncThunk(
  'fetch/user-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getPromotionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getBonus = createAsyncThunk(
  'fetch/all-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const claimBonus = createAsyncThunk(
  'claim/bonus',
  async ({ onError, bonusId }, thunkApi) => {
    try {
      const res = await availBonusService({ bonusId })
      return res
    } catch (error) {
      onError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const cancelBonus = createAsyncThunk(
  'cancel/bonus',
  async ({ userBonusId }, thunkApi) => {
    try {
      const res = await cancelBonusService({ userBonusId })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const activateBonus = createAsyncThunk(
  'activate/bonus',
  async ({ onError, userBonusId }, thunkApi) => {
    try {
      const res = await activateBonusService({ userBonusId })
      return res
    } catch (error) {
      onError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
