export const formatNumberWithCommas = (value) => {
  return String(value)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const findPlainNumber = (value) => {
  const plainNumber = String(value)?.replace(/[^\d,]/g, '').replace(/,/g, '')
  return Number(plainNumber)
}

export const formatPrice = (value, fiat = false) => {
  let result = 0;

  if (!value || Number.isNaN(Number(value))) {
    result = 0;
  } else if (value.toString().includes('e')) {
    result = Number(value).toLocaleString('en-US', { maximumFractionDigits: 9 })
  } else {
    result = Number(value).toLocaleString('en-US', { maximumFractionDigits: fiat ? 2 : 9 })
  }

  const val = String(result)
  // check if has decimal
  if (val.includes('.')) {
    // check decimal length
    const values = val.split('.')
    if (!fiat) {
      return `${values[0]}.${values[1].slice(0, 8).padEnd(8, '0')}`
    }
  }

  return val;
}
