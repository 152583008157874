import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFavouriteGameSuccess,
  getGameDataSucces,
  setAllCasinoGameCategories,
  setCasinoSubCategories,
  setFilteredGames
} from '../redux/slices/games.slice'

import {
  addFavouriteGameService,
  getCasinoGamesCategoriesService,
  getCasinoSubCategories,
  getFavouriteGameService,
  getGameDataServices,
  getGamesListService,
  getSearchedGamesListService,
  removeFavouriteGameService
} from 'network/services/casino.service'
import { getUsersCasinoTransactionsService } from 'network/services/users.service'

export const getCasinoGamesList = createAsyncThunk(
  'fetch/casino-category-games',
  async ({ data }, thunkApi) => {
    try {
      const res = await getGamesListService({ ...data })
      const { categoryWiseGamesList } = thunkApi?.getState()?.games
      const updatedList = [
        ...categoryWiseGamesList,
        { type: data?.type, ...res }
      ]
      return updatedList
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getAllGames = createAsyncThunk(
  'fetch/games-list',
  async ({ data }, thunkApi) => {
    try {
      let res = await getGamesListService({ ...data })
      if (data?.offset > 0) {
        const { viewAllGames } = thunkApi?.getState()?.games
        res = {
          count: res?.count,
          rows: [...viewAllGames?.rows, ...res?.rows]
        }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getSearchedGames = createAsyncThunk(
  'fetch/searched-games-list',
  async ({ data, hasMore = false, popupGames = false }, thunkApi) => {
    try {
      const res = await getSearchedGamesListService({ ...data }, hasMore)
      const { filteredGames, searchPopupGames } = thunkApi?.getState()?.games
      const gameData = popupGames ? searchPopupGames : filteredGames
      if (hasMore && gameData) {
        res.casinoGames = {
          count: res?.casinoGames?.count,
          rows: [...gameData?.casinoGames?.rows, ...res?.casinoGames?.rows]
        }
      }
      res.popupGames = popupGames
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getUsersCasinoTransactions = createAsyncThunk(
  'fetch/users-casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getUsersCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)
export const getFavouriteGame = createAsyncThunk(
  'casino/favorite',
  async ({ data, hasMore = false }, thunkApi) => {
    try {
      const res = await getFavouriteGameService(data)

      const { favoriteGames } = thunkApi?.getState()?.games

      if (data.pageNo > 1) {
        res.favoriteGames = {
          count: res?.favoriteGames?.count,
          rows: [...favoriteGames?.rows, ...res?.favoriteGames?.rows]
        }
      }

      thunkApi.dispatch(getFavouriteGameSuccess(res?.favoriteGames))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const favoriteGameHandlerGenric = createAsyncThunk(
  'casino/favorite',
  async ({ gameData, isAllGame = false, onCardAddRemoveSuccess, onCardAddRemoveError }, thunkApi) => {
    const { id, isFavorite = false, gameSubCategoryId } = gameData
    const casinoGameId = id
    try {
      const res = (!isFavorite)
        ? await addFavouriteGameService({ casinoGameId })
        : await removeFavouriteGameService({ casinoGameId })
      const { filteredGames, casinoSubCategoriesGames } =
        thunkApi?.getState()?.games

      if (isAllGame) {
        const newAllfilteredGames = casinoSubCategoriesGames?.map((item) =>
          item.id === gameSubCategoryId
            ? {
              ...item,
              CasinoGames: item.CasinoGames.map((cData) =>
                cData.id === casinoGameId
                  ? { ...cData, isFavorite: !isFavorite }
                  : cData
              )
            }
            : item
        )

        thunkApi.dispatch(setCasinoSubCategories(newAllfilteredGames))
      } else {
        const rows = filteredGames?.casinoGames?.rows?.map((item) =>
          item.id === casinoGameId
            ? { ...item, isFavorite: !isFavorite }
            : item
        )
        const newfilteredGames = {
          casinoGames: {
            rows: rows,
            count: rows.length
          }
        }
        thunkApi?.dispatch(setFilteredGames(newfilteredGames))
      }

      onCardAddRemoveSuccess(res)
      return res
    } catch (error) {
      onCardAddRemoveError(error)
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const removeFavouriteGame = createAsyncThunk(
  'casino/favorite',
  async ({ gameId, onSuccess, onCardAddRemoveSuccess, onCardAddRemoveError }, thunkApi) => {
    try {
      const res = await removeFavouriteGameService({ casinoGameId: gameId })
      onCardAddRemoveSuccess(res)

      if (onSuccess) {
        onSuccess?.()
      }
      return res
    } catch (error) {
      onCardAddRemoveError(error)
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getCasinoGamesCategories = createAsyncThunk(
  'getGameStart',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoGamesCategoriesService(data)
      thunkApi.dispatch(setAllCasinoGameCategories(res?.response))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getCasinoCategoryGame = createAsyncThunk(
  '/getCasinoSubCategories',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoSubCategories(data)

      thunkApi.dispatch(setCasinoSubCategories(res?.casinoGames))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getGameData = createAsyncThunk(
  '/getGameData',
  async (data, thunkApi) => {
    try {
      const res = await getGameDataServices(data?.payload)
      thunkApi.dispatch(getGameDataSucces(res))
      return {}
    } catch (error) {
      data?.onGamePlayError(error)
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)
