import { getAuthToken } from 'helpers/cookie.helpers'
import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoute from './HOC/PublicRoutes/index'

const RouteValidator = ({ route }) => {
  const { component: Component, child: Child, showFooter = true } = route
  const isAuthenticated = !!getAuthToken()
  return (
    <>
      {
        route?.isPrivate
          ? (
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              Component={Component}
              child={Child}
              showFooter={showFooter}
            />
            )
          : (
            <PublicRoute
              isAuthenticated={isAuthenticated}
              Component={Component}
              child={Child}
              showFooter={showFooter}
            />
            )
        }
    </>
  )
}

export default RouteValidator
