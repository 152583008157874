import React from 'react'
import { MatchesBoxContainer } from './style'

const MatchesBox = () => {
	return (
		<MatchesBoxContainer>
			MatchesBoxContainer
		</MatchesBoxContainer>
	)
}

export default MatchesBox