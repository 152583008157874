import React from 'react'
import QRCode from 'react-qr-code'

const QRCodeGenerator = (props) => {
  const { value, size } = props
  return (
    <QRCode
      size={size}
      value={value}
    />
  )
}

export default QRCodeGenerator

QRCodeGenerator.defaultProps = {
  value: 'default',
  size: 140
}
