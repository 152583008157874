import {
  LiteCoinIcon,
  EthereumCoinIcon,
  TronCoinIcon,
  TetherCoinIcon,
  DogeCoinIcon,
  RupiahCoinIcon,
  BitcoinIcon,
  EURCoinIcon,
  JPYCoinIcon,
  INRCoinIcon,
  TRYCoinIcon,
  USDCoinIcon
} from 'assets/icons/index'

export const currencyIcon = {
  LTC: <LiteCoinIcon />,
  ETH: <EthereumCoinIcon />,
  BTC: <BitcoinIcon />,
  TRX: <TronCoinIcon />,
  IDR: <RupiahCoinIcon />,
  USDT: <TetherCoinIcon />,
  DOGE: <DogeCoinIcon />,
  EUR: <EURCoinIcon />,
  JPY: <JPYCoinIcon />,
  INR: <INRCoinIcon />,
  TRY: <TRYCoinIcon />,
  USD: <USDCoinIcon />

}
