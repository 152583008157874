import { createTheme } from '@mui/material'
import { customLightColors, customDarkColors } from './colors';


export const theme = createTheme({
  spacing: (factor) => `${1 / 2 * factor}rem`,
  palette: {
    mode: 'light',
    primary: {
      //custom
      gradientBlue: 'linear-gradient(90deg, #3F86FC 4%, #0D2F6E 100%)',
      //
      light: '#201C29',
      main: '#201C29',
      dark: '#1D1925',
      contrastText: '#FFFFFF',
      contrastTextLight: '#4D4954',
      contrastTextDark: '#667FA4',
      transparent: 'transparent',
      inputBg: '#110D1A',
      iconBg: '#2F2B38',
      overlayBg: 'rgb(35 157 67 / 43%)',
      bodyBg: '#17141D',
      borderColor: '#34303D',
      modalPopoverBg: '#2A2633',
      modalListBg: '#1D1926',
      checkboxSvg: '#433F4C',
      radioButton: '#645c64'
    },
    secondary: {
      light: '#2C2C2E',
      main: '#1C1C1E',
      dark: '#0C0C0D',
      contrastTextActive: '#2F4950',
      textGray: '#B1ADB8'
    },
    success: {
      main: '#04BD00'
    },
    danger: {
      main: '#BD0000',
      light: '#D74D47'
    },
    warning: {
      main: '#F8D648'
    },
    lightShade: {
      main: '#FFFFFF',
      light_09: 'rgba(255, 255, 255, 0.9)',
      light_08: 'rgba(255, 255, 255, 0.8)',
      light_07: 'rgba(255, 255, 255, 0.7)',
      light_06: 'rgba(255, 255, 255, 0.6)',
      light_05: 'rgba(255, 255, 255, 0.5)',
      light_04: 'rgba(255, 255, 255, 0.4)',
      light_03: 'rgba(255, 255, 255, 0.3)',
      light_02: 'rgba(255, 255, 255, 0.2)',
      light_01: 'rgba(255, 255, 255, 0.1)'
    },
    darkShade: {
      main: '#000000',
      dark_09: 'rgba(0, 0, 0, 0.9)',
      dark_08: 'rgba(0, 0, 0, 0.8)',
      dark_07: 'rgba(0, 0, 0, 0.7)',
      dark_06: 'rgba(0, 0, 0, 0.6)',
      dark_05: 'rgba(0, 0, 0, 0.5)',
      dark_04: 'rgba(0, 0, 0, 0.4)',
      dark_03: 'rgba(0, 0, 0, 0.3)',
      dark_02: 'rgba(0, 0, 0, 0.2)',
      dark_01: 'rgba(0, 0, 0, 0.1)'
    },
    border: {
      // light: '#48484A',
      // main: '#3A3A3C',
      // dark: '#2C2C2E',
      // active: '#743EE8',
      // transparent: 'transparent',
    },
    button: customLightColors,
    // button: {
    //   greenGradient: 'radial-gradient(124.42% 124.42% at 50% -30.23%, #0F6 0%, #258315 100%)',
    //   light: '#2C2C2E',
    //   main: 'radial-gradient(124.42% 124.42% at 50% -30.23%, #00A542 0%, #0B5100 100%)',
    //   activeBtn: '#239D43',
    //   hoverBtn: '#1c6830',
    //   sliderBtn: '#626F65'
    // },
    gradient: {
      // primaryActiveBtn: 'radial-gradient(124.42% 124.42% at 50% -30.23%, #00A542 0%, #0B5100 100%)',
      // sidebarBg: 'linear-gradient(129.39deg, rgba(255, 255, 255, 0.05) 27.45%, rgba(255, 255, 255, 0.03) 50.63%, rgba(255, 255, 255, 0.06) 72.54%)',
      // boxBg: 'linear-gradient(91.76deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.03) 53.88%, rgba(255, 255, 255, 0.04) 100%)',
      // headBg: 'linear-gradient(90deg, #743EE8 0%, #BF5AF2 100%)',
      // oddsBg: 'linear-gradient(92.8deg, rgba(255, 255, 255, 0.1) -34%, rgba(255, 255, 255, 0.02) 67.56%, rgba(255, 255, 255, 0.04) 99.87%)',
      // oddsBgActive: 'linear-gradient(90deg, #743EE8 0%, #743EE8 0.01%, #BF5AF2 100%)',
    },
    scrollbar: {
      track: '#181423',
      thumb: '#743EE8',
      transparent: 'transparent'
    }
  },
  typography: {
    fontFamily: 'Lexend',
    fontWeightThin: 100,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    fontWeightBoldBlack: 900
  },
  shadows: [
    'none',
    '0px 0px 10px rgba(116, 62, 232, 0.65)',
    '0px 0px 12px 0px rgba(132, 255, 75, 0.25)',
    '0px 0px 12px 0px rgba(133, 255, 232, 0.25)',
    '0px 7.112px 7.112px 0px rgba(0, 0, 0, 0.25)',
    'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      xm: 1070,
      lg: 1200,
      ml: 1396,
      xl: 1536,
      xxl: 1620,

      customModal_xs: 428,
      customModal_sm: 546,
      customModal_md: 780,
      customPoint_lg: 1024
    }
  },
  layout: {
    maxWidth: 1920
  },
  components: {
    // MENU CUSTOMIZE
    MuiPopover: {
      styleOverrides: {
        paper: {
          width: '100%',
          maxWidth: '16rem',
          backgroundColor: '#1D1925',
          backgroundImage: 'none',
          borderRadius: '4px',
          overflow: 'auto',
          padding: '0.5rem 0.5rem'

        }
      }
    },
    MuiList: {
      paddingTop: '0',
      styleOverrides: {
        root: {
          padding: '0',
          '&::-webkit-scrollbar': {
            width: '0',
            height: '0',
            background: 'transparent !important'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent !important'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          color: '#FFFFFF',
          fontSize: '0.875rem',
          lineHeight: '1.2rem',
          transition: '300ms all',
          '&:hover': {
            background: 'linear-gradient(90deg, rgba(0, 13, 130, 0) 0%, rgba(3, 0, 130, 0.6) 100%)'
          },
        }
      }
    }

    // AUTOCOMPLETE DROPDOWN CUSTOMIZE
    // MuiAutocomplete: {
    //   styleOverrides: {
    //     Popper: {
    //       width: '100%',
    //       maxWidth: '16rem',
    //       backgroundColor: '#070707',
    //       backgroundImage: 'none',
    //       border: '1px solid #2B2B2B',
    //       borderRadius: '4px',
    //       overflow: 'hidden',
    //       padding: '0.5rem 0.5rem',
    //       '& .MuiAutocomplete-label': {
    //         maxHeight: '9.563rem',
    //         overflowY: 'auto',
    //         padding: '0',
    //         '&::-webkit-scrollbar': {
    //           width: '0.25rem',
    //           height: '0.25rem',
    //           background: 'rgba(255, 255, 255, 0.5)'
    //         },
    //         '&::-webkit-scrollbar-thumb': {
    //           background: '#FF4CED'
    //         },
    //         '& .MuiAutocomplete-option': {
    //           borderRadius: '4px',
    //           fontFamily: 'Aeonik-Regular',
    //           color: '#FFFFFF',
    //           fontSize: '0.875rem',
    //           lineHeight: '1.2rem',
    //           transition: '300ms all',
    //           '&:hover': {
    //             backgroundColor: '#FF4CED'
    //           }
    //         }
    //       }
    //     }
    //   }
    // },

    // PAGINATION CUSTOMIZE
    // MuiPagination: {
    //   styleOverrides: {
    //     root: {
    //       marginTop: '1rem',
    //       marginBottom: '1rem'
    //     },
    //     ul: {
    //       justifyContent: 'center',
    //       flexWrap: 'nowrap',
    //       '& .MuiButtonBase-root': {
    //         minWidth: '2rem',
    //         height: '2rem',
    //         backgroundColor: '#2C2C2C',
    //         borderRadius: '2rem',
    //         color: '#FFFFFF',
    //         fontSize: '0.875rem',
    //         fontWeight: '400',
    //         lineHeight: 'normal',
    //         padding: '0.25rem',
    //         '& svg': {
    //           fill: '#FFFFFF'
    //         },
    //         '&:hover': {
    //           backgroundColor: '#FFFFFF',
    //           color: '#F474FF',
    //           '& svg': {
    //             fill: '#F474FF'
    //           }
    //         },
    //         '&.Mui-selected': {
    //           backgroundColor: '#D824E7',
    //           color: '#FFFFFF',
    //           '& svg': {
    //             fill: '#FFFFFF'
    //           }
    //         },
    //         '&.Mui-disabled': {
    //           opacity: '1',
    //           pointerEvents: 'auto',
    //           cursor: 'no-drop',
    //           '&:hover': {
    //             backgroundColor: '#2C2C2C',
    //             color: '#FFFFFF',
    //             '& svg': {
    //               fill: '#FFFFFF'
    //             }
    //           }
    //         }
    //       },
    //       '& li:first-child': {
    //         marginRight: 'auto'
    //       },
    //       '& li:last-child': {
    //         marginLeft: 'auto'
    //       }
    //     }
    //   }
    // }
  }

})
