import { Box, styled } from '@mui/material'

export const SearchGameWrapper = styled(Box)(({ theme }) => {
  return {
    '& .search-wrap': {
      width: theme.spacing(7),
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 0, 0),
      transition: 'width 0.3s ease',

      '&:hover': {
        width: theme.spacing(30),
      },

      [theme.breakpoints.down('customPoint_lg')]: {
        display: 'none'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      '& .input-holder': {
        width: '100%',
        marginLeft: 'auto',
        position: 'relative',
        color: '#fff',
        '& .MuiFormControl-root': {
          width: '100%',
          color: '#fff',
          '& .MuiInputBase-root': {
            color: '#fff',
            borderRadius: theme.spacing(1.25),
            '& .MuiInputBase-input': {
              color: '#fff',
              fontSize: theme.spacing(1.75),
              height: '1rem',
              fontWeight: theme.typography.fontWeightRegular,
              padding: theme.spacing(1.75, 1.75, 1.75, 6),
              [theme.breakpoints.down('xl')]: {
                fontSize: theme.spacing(1.2)
              },
              '& ::placeholder': {
                color: '#fff'
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important'
            }
          }
        },
        '& .icon-box': {
          width: theme.spacing(6),
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          '& svg': {
            filter: 'invert(100%) sepia(60%) saturate(0%) hue-rotate(164deg) brightness(106%) contrast(100%)'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .search-wrap': {
        width: '100%',
        padding: theme.spacing(0, 2, 0, 2),
        marginTop: theme.spacing(3),
        minWidth: 'auto',
        '& .MuiFormControl-root': {
          width: theme.spacing('100%')
        }
      }
    }
  }
})
