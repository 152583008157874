/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material/index";
import { LOADER_HANDLER_TYPES } from "constants/index";
import { openErrorToaster, openInfoToaster } from "helpers/toaster.helpers";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setShowWalletPopup } from "redux-thunk/redux/slices/wallet.slice";
import { getAllCurrencyStart } from "redux-thunk/thunk/gameSetting";
import { withdrawRequest } from "redux-thunk/thunk/user.thunk";
import { getLiminalApproxFee } from "redux-thunk/thunk/wallet.thunk";
import { currencyIcon } from "utils/currencyIcon";
import {
  findPlainNumber,
  formatNumberWithCommas,
  formatPrice,
} from "utils/numberFormatter";
import { v4 as uuidv4 } from "uuid";

import { CustomSelectBox } from "components/layout/Common/StyledForm/style";
import { getEmailCookie } from "../../../helpers/cookie.helpers";
import { encrypt } from "../../../helpers/obfus";
import CryptoComponent from "./CryptoComponent";

const AmountLabelComponent = memo((props) => {
  const { minMaxAmountLimit, depositCurrency } = props;
  const { t } = useTranslation();
  return typeof minMaxAmountLimit === "object" ? (
    <InputLabel className="input-label">
      {t(
        `Amount (Min ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit?.min)
        )} - Max ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit?.max)
        )} ) ${depositCurrency}`
      )}
      <Typography className="required-indicator">*</Typography>
    </InputLabel>
  ) : (
    <InputLabel className="input-label">
      {" "}
      {t(
        `Amount (Min ${formatNumberWithCommas(
          findPlainNumber(minMaxAmountLimit)
        )} ${depositCurrency})`
      )}{" "}
      <Typography className="required-indicator">*</Typography>
    </InputLabel>
  );
});

export const Withdraw = ({ currencyLimit, allCurrencies }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { depositData, withdrawalFee, walletSocket, withdrawalFeeLoader } =
    useSelector((state) => state.wallet);
  const [alertBoxopen, setAlertBoxOpen] = useState(false);
  const [currAmount, setCurrAmount] = useState(false);
  const [walletCurrency, setWalletCurrency] = useState("default");

  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  );

  const MIN_MAX_WITHDRAW_AMOUNT = currencyLimit?.value?.[walletCurrency];
  const userData = useSelector((state) => state?.user?.user?.getUser);

  const onWithDrawError = (error) => {
    setAlertBoxOpen(true);
    if (error?.[0]?.name === "InternalServerError") {
      openErrorToaster({ message: t("internalServerError") });
    } else {
      openErrorToaster({ message: t(error?.[0]?.description) });
    }
  };

  const onWithDrawSuccess = (response) => {
    if (response.message === "Insufficient balance") {
      setAlertBoxOpen(true);
      dispatch(setShowWalletPopup(true));
      openErrorToaster({ message: t("insufficientBalance") });
    } else {
      walletSocket.send(
        encrypt(
          JSON.stringify({
            type: "wallet",
            email: getEmailCookie(),
          })
        )
      );
      dispatch(setShowWalletPopup(false));
      openInfoToaster({ message: t("withdrawApprovalReqMsg") });
    }
  };

  const handleWithdrawRequest = async (values) => {
    setAlertBoxOpen(false);
    if (parseFloat(values.amount) >= MIN_MAX_WITHDRAW_AMOUNT) {
      try {
        let params = {
          currency: walletCurrency,
          amount: parseFloat(values.amount),
          address: values.address,
        };

        if (walletCurrency === "USDT") {
          params = {
            ...params,
            currency: "ETH",
            coin: walletCurrency,
          };
        }

        dispatch(
          withdrawRequest({
            params,
            onWithDrawError,
            onWithDrawSuccess,
          })
        );
      } catch (e) {
        openErrorToaster({ message: e.message });
      }
    } else {
      openErrorToaster({
        message: t("withdrawMinAmount", { min: MIN_MAX_WITHDRAW_AMOUNT }),
      });
    }
  };

  const handleAlertBoxClose = () => {
    setAlertBoxOpen(false);
  };

  useEffect(() => {
    if (!allCurrencies) {
      dispatch(getAllCurrencyStart());
    }
  }, [allCurrencies, dispatch]);

  const handleMaxValue = useMemo(() => {
    const filterZeroAmountCurrency =
      userData?.userWallets &&
      userData?.userWallets?.filter(
        (currencyCodeDetails, index) =>
          currencyCodeDetails?.currency.code === walletCurrency
      );
    return Number(formatPrice(filterZeroAmountCurrency[0]?.amount ?? 0));
  }, [userData?.userWallets, walletCurrency]);

  const checkEstimatedFee = useCallback(
    (amount) => {
      setCurrAmount(amount);
    },
    [dispatch, walletCurrency]
  );

  useEffect(() => {
    if (walletCurrency === "default" || !currAmount) return;

    dispatch(
      getLiminalApproxFee({
        currency: walletCurrency === "USDT" ? "ETH" : walletCurrency,
        coin: walletCurrency === "USDT" ? walletCurrency : "",
        amount: Number(currAmount),
        onWithDrawError,
      })
    );
  }, [currAmount, walletCurrency]);

  return (
    <Grid container spacing={2} className="wallet-form-group">
      <Grid item xs={12}>
        <Box>
          <InputLabel className="input-label" htmlFor="currency">
            {t("Currency")}
          </InputLabel>
          <CustomSelectBox
            className="select-box"
            value={walletCurrency}
            name="currency"
            onChange={(e) => {
              alertBoxopen && setAlertBoxOpen(false);
              setWalletCurrency(e.target?.value);
            }}
            sx={{
              width: "100%",
              color: "#FFF",
            }}
          >
            <MenuItem value="default" disabled>
              {t("Select Your Currency")}
            </MenuItem>
            {allCurrencies?.map((menuItem) => {
              return (
                <MenuItem
                  value={menuItem?.code}
                  style={{ display: "flex", alignItems: "center" }}
                  key={uuidv4()}
                  disabled={menuItem?.code === "IDR"}
                >
                  {currencyIcon[menuItem?.code]} &nbsp;{t(menuItem?.code)}{" "}
                </MenuItem>
              );
            })}
          </CustomSelectBox>
        </Box>

        {walletCurrency === "USDT" && (
          <Box>
            <InputLabel className="input-label" htmlFor="network">
              Network
            </InputLabel>
            <CustomSelectBox
              className="select-box"
              value={1}
              defaultValue={1}
              sx={{ width: "100%", color: "#FFF" }}
              readonly
            >
              <MenuItem value={1}>Ethereum (ERC-20)</MenuItem>
            </CustomSelectBox>
          </Box>
        )}
      </Grid>

      <CryptoComponent
        MIN_MAX_WITHDRAW_AMOUNT={MIN_MAX_WITHDRAW_AMOUNT}
        depositData={depositData}
        handleWithdrawRequest={handleWithdrawRequest}
        handleMaxValue={handleMaxValue}
        alertBoxopen={alertBoxopen}
        setAlertBoxOpen={setAlertBoxOpen}
        loading={loading}
        withdrawalFee={withdrawalFee}
        error={null}
        withdrawalFeeLoader={withdrawalFeeLoader}
        walletCurrency={walletCurrency}
        handleAlertBoxClose={handleAlertBoxClose}
        checkEstimatedFee={checkEstimatedFee}
      />
    </Grid>
  );
};
