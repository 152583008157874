
import { Button, styled, Box } from '@mui/material'
export const StyledContainer = styled(Box)({
  textAlign: 'center',
  background: '#1B1821',
  width: '100vw',
  top: '0',
  left: '0',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '&  .MuiTypography-h2': {
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '1rem'
  }
})
export const StyledImage = styled('img')({
  width: '100%',
  maxHeight: '300px',
  objectFit: 'cover'

})

export const StyledButton = styled(Button)({
  marginTop: '20px'
})
