import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'

export const getCasinoSubCategories = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/games', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCasinoProviderListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/game-provider', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getGameDataServices = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/game-launch-pp', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getGamesListService = (params, hasMore = false) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/games', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: hasMore ? LOADER_HANDLER_TYPES.submit : LOADER_HANDLER_TYPES.content
  })
}

export const addFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/casino/favorite', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getFavouriteGameService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/favorite', {}, {
    server: microServices.SERVICE_URL_1,
    params

  })
}
export const removeFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.delete, '/casino/favorite', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const getCasinoGamesCategoriesService = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/game-sub-category', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const getSearchedGamesListService = (params, hasMore = false) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/sub-category-games', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: hasMore ? LOADER_HANDLER_TYPES.submit : LOADER_HANDLER_TYPES.content
  })
}

export const getCasinoBannersListService = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'banners', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const getGeneralService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'casino/game-category', {}, {
    server: microServices.SERVICE_URL_1
  })
}
