/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material/index";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material/index";
import Link from "@mui/material/Link";
import { CustomTabButton } from "components/layout/Common/StyledButton/CustomButton";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepositCurrency,
  setDepositData,
  setPaymentCodeData,
  setShowWalletPopup,
  setWalletCurrency,
} from "redux-thunk/redux/slices/wallet.slice";
import { getAllCurrencyStart } from "redux-thunk/thunk/gameSetting";
import {
  getKycLevel,
  getUserWalletCurrencyLimit,
} from "redux-thunk/thunk/user.thunk";
import { currencyIcon } from "utils/currencyIcon";
import { currencySymbol } from "utils/currencySymbol";
import { formatPrice } from "utils/numberFormatter";
import { openErrorToaster } from "../../helpers/toaster.helpers";
import { BuyCrypto } from "./BuyCrypto/BuyCrypto";
import { Deposit } from "./Deposit/Deposit";
import { WalletModalStyle } from "./style";
import { Withdraw } from "./Withdraw/Withdraw";

const ViewType = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  BALANCE: "BALANCE",
  BUYCRYPTO: "BUYCRYPTO",
};

const calculateAmountsWithRates = (userWallets, convertedRate) => {
  const wallets = userWallets?.filter((wallet) => wallet.amount > 0) || [];
  const aggregatedWallets = wallets.reduce((acc, wallet) => {
    if (!acc[wallet.currency.code]) {
      acc[wallet.currency.code] = 0;
    }
    acc[wallet.currency.code] += wallet.amount;
    return acc;
  }, {});

  const conversionRates = convertedRate || {};
  const amountsWithRates = Object.entries(aggregatedWallets).reduce(
    (result, [currency, amount]) => {
      const rate = conversionRates[currency] || 1;
      result[currency] = {
        amount,
        priceInUSD: amount * rate,
        amountPerPrice: amount / rate,
      };
      return result;
    },
    {}
  );

  return amountsWithRates;
};

const calculateWalletBalances = (userWallets, convertedRate) => {
  const wallets = userWallets?.filter((wallet) => wallet.amount > 0) || [];
  const aggregatedWallets = wallets.reduce((acc, wallet) => {
    const currency = wallet.currency.code;
    if (!acc[currency]) {
      acc[currency] = 0;
    }
    acc[currency] += wallet.amount;
    return acc;
  }, {});

  const conversionRates = convertedRate || {};

  const totalBalancesInUSD = Object.entries(aggregatedWallets).reduce(
    (total, [currency, amount]) => {
      const rate = conversionRates[currency] || 1;
      return total + amount / rate;
    },
    0
  );

  return { aggregatedWallets, totalBalancesInUSD };
};

const Wallet = ({ daken }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [balanceValue, setBalanceValue] = useState(0);

  const {
    showWalletPopup,
    walletCurrencyLimit,
    walletData: dataSocket,
  } = useSelector((state) => state.wallet);

  const [viewType, setViewType] = useState(ViewType.BALANCE);

  const { totalBalancesInUSD } = calculateWalletBalances(
    dataSocket?.userWallets,
    dataSocket?.convertedRate
  );
  const amountsWithRates = calculateAmountsWithRates(
    dataSocket?.userWallets,
    dataSocket?.convertedRate
  );
  const { allCurrencies } = useSelector((state) => state.gameSetting);

  const walletHandleClose = () => {
    dispatch(setShowWalletPopup(false));
    dispatch(setDepositCurrency("default"));
    dispatch(setWalletCurrency("default"));
    dispatch(setDepositData(null));
    dispatch(setPaymentCodeData());
  };

  const { depositCurrencylimit, withdrawCurrencylimit } = useMemo(() => {
    const depositCurrencylimit = walletCurrencyLimit?.filter(
      (currencyLimit) => currencyLimit.key === "DEPOSIT_LIMIT"
    );

    const withdrawCurrencylimit = walletCurrencyLimit?.filter(
      (currencyLimit) => currencyLimit.key === "WITHDRAW_LIMIT"
    );

    return { depositCurrencylimit, withdrawCurrencylimit };
  }, [walletCurrencyLimit]);

  const [isVisibleBalance, setIsVisibleBalance] = useState(true);
  const toggleVisibility = () => {
    setIsVisibleBalance(!isVisibleBalance);
  };

  // useEffect(() => {
  //   if (!walletCurrencyLimit) {
  //     dispatch(getUserWalletCurrencyLimit())
  //     setValueBalance()
  //   }
  // }, [dispatch])

  useEffect(() => {
    if (!allCurrencies) {
      dispatch(getAllCurrencyStart());
    }
  }, []);

  useEffect(() => {
    if (!walletCurrencyLimit) {
      const fetchAndCalculateBalance = async () => {
        try {
          await dispatch(getUserWalletCurrencyLimit());
          if (Array.isArray(dataSocket?.userWallets)) {
            const totalAmount = dataSocket?.userWallets.reduce(
              (sum, item) => sum + item.amount,
              0
            );
            setBalanceValue(totalAmount);
          } else {
            // console.error('Data is not an array:', dataSocket?.userWallets);
            setBalanceValue(0);
          }
        } catch (error) {
          // console.error('Error in fetching and calculating balance:', error);
        }
      };

      // Call the async function inside useEffect
      fetchAndCalculateBalance();
    }
  }, [dispatch]); //

  const [isWithdrawChecking, setIsWithdrawChecking] = useState(false);

  const checkWithdraw = () => {
    setIsWithdrawChecking(true);
    dispatch(getKycLevel({}))
      .unwrap()
      .then((level) => {
        if (level < 2) {
          openErrorToaster({
            message: "You must reach min KYC level 2 to withdraw",
          });
          return;
        }

        setViewType(ViewType.WITHDRAW);
      })
      .finally(() => setIsWithdrawChecking(false));
  };

  return (
    <Modal
      open={showWalletPopup}
      onClose={walletHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <WalletModalStyle>
        {viewType === ViewType.BALANCE && (
          <Box>
            <Box
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                padding: "10px",
              }}
            >
              <IconButton onClick={walletHandleClose}>
                <CloseIcon sx={{ color: "#433F4C" }} />
              </IconButton>
            </Box>
            <Box className="login-modal">
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modal-heading"
                >
                  {t("Wallet")}
                </Typography>
                <Typography
                  variant="body1"
                  component="body1"
                  style={{
                    color: "#afb8d1",
                    fontSize: "13px",
                    marginBottom: "0 !important",
                  }}
                >
                  Estimated Balance
                </Typography>
                <IconButton
                  sx={{ color: "white" }}
                  color="primary"
                  onClick={toggleVisibility}
                >
                  {isVisibleBalance ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Box>
              <Box className="boxestimatedUSD" style={{ color: "white" }}>
                {isVisibleBalance ? (
                  <div className="estimatedUSD">
                    {
                      currencySymbol[
                      dataSocket?.other.faitCurrencyCode ?? "USD"
                      ]
                    }
                    &nbsp;{formatPrice(totalBalancesInUSD, true)}{" "}
                    {dataSocket?.other.faitCurrencyCode ?? "-"}
                  </div>
                ) : (
                  "*****"
                )}
              </Box>
              <Box>
                <div className="currency-user">
                  <div className="box-flex-currency">
                    <div className="heading-currency">
                      <p
                        style={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Your currencies
                      </p>
                    </div>
                    <div className="heading-value">
                      <p
                        style={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Value
                      </p>
                    </div>
                  </div>
                  {/* Render Amounts with Rates */}
                  <div className="box-currency-">
                    {Object.entries(amountsWithRates)
                      .sort(
                        ([, a], [, b]) => b.amountPerPrice - a.amountPerPrice
                      ) // Sorting by priceInUSD
                      .map(
                        ([
                          currency,
                          { amount, priceInUSD, amountPerPrice },
                        ]) => (
                          <div
                            className="box-height-currency"
                            key={currency}
                            style={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                            }}
                          >
                            <div className="currencyName">
                              {currencyIcon[currency]} {currency}
                            </div>
                            <div className="amountCurrency">
                              {isVisibleBalance ? (
                                <div className="marginamount">
                                  <div className="amountcrypto">
                                    {formatPrice(amount)}
                                  </div>
                                  <div className="amountperprice">
                                    {
                                      currencySymbol[
                                      dataSocket?.other.faitCurrencyCode ??
                                      "USD"
                                      ]
                                    }
                                    &nbsp;
                                    {formatPrice(amountPerPrice, true)}{" "}
                                    {dataSocket?.other.faitCurrencyCode ?? "-"}
                                  </div>
                                </div>
                              ) : (
                                "*****"
                              )}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTabButton
                      fullWidth
                      size="small"
                      onClick={() => setViewType(ViewType.DEPOSIT)}
                    >
                      Deposit
                    </CustomTabButton>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTabButton
                      fullWidth
                      onClick={checkWithdraw}
                      isLoading={isWithdrawChecking}
                      disabled={isWithdrawChecking}
                      size="small"
                    >
                      Withdraw
                    </CustomTabButton>
                  </Grid>
                  {/*
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTabButton
                      fullWidth
                      size="small"
                      onClick={() => setViewType(ViewType.BUYCRYPTO)}
                    >
                      Buy Crypto
                    </CustomTabButton>
                  </Grid>
                  */}
                </Grid>
              </Box>
            </Box>
          </Box>
        )}

        {viewType === ViewType.DEPOSIT && (
          <Box className="login-modal">
            <Breadcrumbs
              separator={
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#fff",
                    marginBottom: "0 !important",
                    fontSize: "1.2rem",
                  }}
                >
                  /
                </Typography>
              }
              aria-label="breadcrumb"
            >
              <Link
                href="#"
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#afb8b1",
                  marginBottom: "0 !important",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  verticalAlign: "top",
                }}
                underline="hover"
                onClick={() => setViewType(ViewType.BALANCE)}
              >
                &lsaquo;&nbsp;{t("Wallet")}
              </Link>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#fff",
                  marginBottom: "0 !important",
                  fontSize: "1.2rem",
                }}
              >
                Deposit
              </Typography>
            </Breadcrumbs>

            <Box className="wallet-tab" style={{ marginTop: "1rem" }}>
              <Deposit currencyLimit={depositCurrencylimit?.[0] || {}} />
            </Box>
          </Box>
        )}

        {viewType === ViewType.WITHDRAW && (
          <Box className="login-modal">
            <Breadcrumbs
              separator={
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#fff",
                    marginBottom: "0 !important",
                    fontSize: "1.2rem",
                  }}
                >
                  /
                </Typography>
              }
              aria-label="breadcrumb"
            >
              <Link
                href="#"
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#afb8b1",
                  marginBottom: "0 !important",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  verticalAlign: "top",
                }}
                underline="hover"
                onClick={() => setViewType(ViewType.BALANCE)}
              >
                &lsaquo;&nbsp;{t("Wallet")}
              </Link>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#fff",
                  marginBottom: "0 !important",
                  fontSize: "1.2rem",
                }}
              >
                Withdraw
              </Typography>
            </Breadcrumbs>

            <Box className="wallet-tab" style={{ marginTop: "1rem" }}>
              <Withdraw
                currencyLimit={withdrawCurrencylimit?.[0] || {}}
                allCurrencies={allCurrencies}
              />
            </Box>
          </Box>
        )}

        {viewType === ViewType.BUYCRYPTO && (
          <Box className="login-modal">
            <Breadcrumbs
              separator={
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    color: "#fff",
                    marginBottom: "0 !important",
                    fontSize: "1.2rem",
                  }}
                >
                  /
                </Typography>
              }
              aria-label="breadcrumb"
            >
              <Link
                href="#"
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#afb8b1",
                  marginBottom: "0 !important",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  verticalAlign: "top",
                }}
                underline="hover"
                onClick={() => setViewType(ViewType.BALANCE)}
              >
                &lsaquo;&nbsp;{t("Wallet")}
              </Link>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: "#fff",
                  marginBottom: "0 !important",
                  fontSize: "1.2rem",
                }}
              >
                Buy Crypto
              </Typography>
            </Breadcrumbs>

            <Box className="login-tab" style={{ marginTop: "1rem" }}>
              <BuyCrypto />
            </Box>
          </Box>
        )}
      </WalletModalStyle>
    </Modal>
  );
};

export default Wallet;
